import React, { useState } from "react";

const DeleteUser = () => {
  const [value, setValue] = useState("");

  const handleClick = () => {
    fetch(process.env.REACT_APP_DOMAIN + "/admin/deleteUser", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
      body: JSON.stringify({
        existing_user_id: value,
      }),
    })
      .then((res) => res.json())
      .then((d) => (d.status === 200 ? setValue("") : null));
  };
  return (
    <div className="admin-form-wrapper">
      <input
        type="text"
        value={value}
        placeholder="User Id..."
        onChange={(e) => setValue(e.target.value)}
      />
      <button onClick={handleClick}>Delete user</button>
    </div>
  );
};

export default DeleteUser;
