import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { TempAccountContext } from "../../../../context/temp-account-context.js";
import GoalsItem from "./GoalsItem/GoalsItem.js";

const GoalsItemWrapper = ({
  deletedItems,
  deleteItem,
  goalData,
  updateGoalData,
  setShowButton,
}) => {
  const [goalPanels, setGoalPanels] = useState([]);
  const { isTempAccount } = useContext(TempAccountContext);

  let maxAmountOfGoals = 4;
  let history = useHistory();

  useEffect(() => {
    setGoalPanels(
      goalData.map((g) => {
        if (deletedItems.indexOf(g.id) === -1) {
          return (
            <GoalsItem
              key={g.id}
              id={g.id}
              title={g.title}
              desc={g.desc}
              dateTime={g.dateTime}
              tags={g.tags}
              color={g.color}
              text={g.text}
              checked={g.checked}
              autoCategory={g.autoCategory}
              autoValue={g.autoValue}
              autoBaseValue={g.autoBaseValue}
              updateGoalData={updateGoalData}
              deleteItem={deleteItem}
            />
          );
        } else {
          return null;
        }
      })
    );

    if (
      isTempAccount &&
      goalData.length - deletedItems.length >= maxAmountOfGoals
    ) {
      setShowButton(false);
    } else {
      setShowButton(true);
    }

    //eslint-disable-next-line
  }, [goalData, deletedItems, maxAmountOfGoals, isTempAccount]);

  return (
    <>
      {goalData.length - deletedItems.length > 0 ? (
        isTempAccount &&
        goalData.length - deletedItems.length >= maxAmountOfGoals ? (
          <div className="goals-items-wrapper">
            {goalPanels}

            <div className="misc-max-amount-wrapper">
              <div className="misc-max-amount-txt">
                <p>Maximum amount of profiles reached.</p>
                <p>Please sign up for more.</p>
              </div>
              <button
                className="misc-max-amount-sign-up"
                onClick={() => history.push("/auth/signup")}
              >
                Sign Up
              </button>
            </div>
          </div>
        ) : (
          <div className="goals-items-wrapper">{goalPanels}</div>
        )
      ) : (
        <h3 className="goals-no-items-wrapper">No goals found</h3>
      )}
    </>
  );
};

export default GoalsItemWrapper;
