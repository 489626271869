import React from "react";
import { Link } from "react-router-dom";

export const FooterLink = ({ href, text }) => {
  return (
    <Link className="footer-link" to={href}>
      {text}
    </Link>
  );
};
