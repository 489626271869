import React, { useState } from "react";
import InfoBubbleSection from "./InfoBubbleSection.js";
import ExitIcon from "../../../images/cancel.svg";

const InfoBubble = ({ isLoggedIn, isTempAccount }) => {
  const [showBox, setShowBox] = useState(false);
  return (
    <div className="main-info-wrapper">
      <div
        className="main-info-box-wrapper"
        style={
          showBox
            ? { height: "400px", opacity: "1", bottom: "80px" }
            : { border: "none" }
        }
      >
        <div className="main-info-box-header">
          <img
            onClick={() => setShowBox((s) => !s)}
            src={ExitIcon}
            alt="Close"
          />
        </div>
        <InfoBubbleSection type="whySignUp" title="Why sign up?" />
      </div>
      <div className="main-info-bubble" onClick={() => setShowBox((s) => !s)}>
        {showBox ? <img src={ExitIcon} alt="Close" /> : "?"}
      </div>
    </div>
  );
};

// Add arrow later??

export default InfoBubble;
