export let borderColors = [
  "rgb(17, 138, 178)",
  "rgb(6, 214, 160)",
  "rgb(255, 209, 102)",
  "rgb(239, 71, 111)",
];
export let backgroundColors = [
  "rgba(17, 138, 178, 0.4)",
  "rgba(6, 214, 160, 0.4)",
  "rgba(255, 209, 102, 0.4)",
  "rgba(239, 71, 111, 0.4)",
];

export let options = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  tooltips: {
    mode: "index",
    intersect: false,
  },
  hover: {
    mode: "index",
    intersect: false,
  },
  scales: {
    yAxes: [
      {
        gridLines: {
          display: true,
          drawBorder: false,
          color: "#474747",
          zeroLineColor: "#474747",
        },
        type: "linear",
        ticks: {
          maxTicksLimit: 5,
          callback: function (tick, index, ticks) {
            return tick.toLocaleString();
          },
          fontColor: "#eee",
          z: 1,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          callback: function (tick, index, ticks) {
            if (index === 0 || index === ticks.length - 1) tick = "";
            return tick.substr(5);
          },
          maxTicksLimit: 8,
          autoSkip: true,
          maxRotation: 0,
          fontColor: "#bbb",
        },
      },
    ],
  },
};

export let polarOptions = {
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    animateRotate: false,
  },
  legend: {
    display: false,
  },
  scale: {
    display: true,
    gridLines: {
      color: "#474747",
    },
    ticks: {
      fontColor: "#fff",
      backdropColor: "rgba(0, 0 ,0 , 0)",
    },
  },
  tooltips: {
    mode: "nearest",
    intersect: true,
  },
  hover: {
    mode: "nearest",
    intersect: true,
  },
  scales: {
    display: false,
  },
};

export let radarOptions = {
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    animateRotate: false,
  },
  legend: {
    display: false,
  },
  scale: {
    display: true,
    gridLines: {
      color: "#474747",
    },
    ticks: {
      fontColor: "#fff",
      backdropColor: "rgba(0, 0 ,0 , 0)",
    },
  },
  tooltips: {
    mode: "nearest",
    intersect: false,
    displayColors: false,
    titleFontSize: 0,
  },
  hover: {
    mode: "nearest",
    intersect: false,
  },
  scales: {
    display: false,
  },
};

export let pieDoughnutOptions = {
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    animateRotate: false,
  },
  legend: {
    display: false,
  },
  tooltips: {
    mode: "nearest",
    intersect: true,
  },
  hover: {
    mode: "nearest",
    intersect: true,
  },
  scales: {
    display: false,
  },
};

export function getValues(value, data, maxLength = null) {
  let response = [];
  let maxLengthData = data;

  if (maxLength !== null && data.length > maxLength) {
    maxLengthData = data.slice(data.length - maxLength, data.length);
  }
  maxLengthData.forEach((d) => {
    for (let key in d) {
      if (key === value) {
        response.push(d[key]);
      }
    }
  });

  return response;
}
