import React from "react";
import Default from "./Default.js";
import ExitIcon from "../../../../../images/cancel.svg";

const GoalAdd = ({ openAddWindowCallback, addNewGoalCallback }) => {
  return (
    <div className="goals-add-wrapper">
      <div className="goals-add-exit-wrapper">
        <div className="goals-add-inner-wrapper">
          <Default
            openAddWindowCallback={openAddWindowCallback}
            addNewGoalCallback={addNewGoalCallback}
          />
        </div>
        <div
          className="goals-add-exit-icon"
          onClick={() => openAddWindowCallback(false)}
        >
          <img src={ExitIcon} alt="" />
        </div>
      </div>
    </div>
  );
};

export default GoalAdd;
