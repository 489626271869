import React, { useState, useEffect } from "react";
import GoalsListPanelItem from "./GoalsListPanelItem.js";
import GoalsListPanelSettings from "./GoalsListPanelSettings.js";
import SettingsIcon from "../../../../images/setting-icon.svg";
import Cancel from "../../../../images/cancel.svg";

const GoalsListPanel = ({
  id,
  handleDelete,
  handleModify,
  game,
  data,
  placement = NaN,
}) => {
  const [showSettings, setShowSettings] = useState(false);
  const [goalData, setGoalData] = useState(null);
  const [goalItems, setGoalItems] = useState([]);

  useEffect(() => {
    let profileId = localStorage.getItem("profileId");
    fetch(process.env.REACT_APP_DOMAIN + `/goal/getAll/${profileId}`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((d) => (d.status === 200 ? setGoalData(d.message) : null))
      .catch((e) => e);
  }, []);

  useEffect(() => {
    if (goalData !== null) {
      setGoalItems(
        goalData.map((g) => {
          return (
            <GoalsListPanelItem
              key={g.goal_id}
              title={g.goal_title}
              desc={g.goal_desc}
              color={g.goal_background}
            />
          );
        })
      );
    }
  }, [goalData]);

  return (
    <div className="stats-panel">
      <div
        className="stats-panel-settings-icon-wrapper"
        onClick={() => setShowSettings((s) => !s)}
      >
        <img
          className="stats-panel-settings-icon"
          src={showSettings ? Cancel : SettingsIcon}
          alt="Delete"
        />
      </div>

      <div className="panel-goals-wrapper">
        <div className="panel-goals-header">Goals</div>
        {goalItems.length > 0 ? (
          <div className="panel-goals-list-wrapper">{goalItems}</div>
        ) : (
          <div className="panel-goals-no-items-wrapper">
            <div className="panel-goals-no-items">No goals found</div>
          </div>
        )}
      </div>

      <div
        className="stats-panel-settings-wrapper"
        style={showSettings ? { display: "flex" } : { display: "none" }}
      >
        <GoalsListPanelSettings
          id={id}
          placement={placement}
          handleDelete={handleDelete}
          handleModify={handleModify}
        />
      </div>
    </div>
  );
};

export default GoalsListPanel;
