import React from "react";

import cancel from "../../../images/cancel.svg";

import MyUserField from "./MyUserField.js";

const StyledUserField = ({
  isLoading,
  value,
  type,
  name,
  placeholder,
  errors = null,
  exit = true,
  callback,
  resetForm,
}) => {
  // X button
  // Send callback function that sends name and a call with change of state. - Change of state also means to reset that initial value.
  // Y button
  // Other callback that sends a signal to accept the field

  return (
    <div className="settings-field-wrapper">
      <MyUserField
        type={type}
        name={name}
        placeholder={placeholder}
        style={isLoading ? { cursor: "progress" } : null}
        onChange={callback("confirm", name, value)}
      />
      <div
        className="settings-field-btn"
        style={{
          background: "#ef476f",
          cursor: "pointer",
        }}
        onClick={() => callback("exit", name, "", resetForm)}
      >
        {exit ? <img src={cancel} alt="cancel" /> : null}
      </div>
    </div>
  );
};

export default StyledUserField;
