import React, { useState } from "react";
import PanelCategoryItem from "../../Panels/PanelCategoryItem.js";
import PanelListItem from "../../Panels/PanelListItem.js";
import CancelIcon from "../../../../images/cancel.svg";
import GoBackIcon from "../../../../images/arrow.svg";

const ModifyPanelPopup = ({
  id,
  oldType,
  oldChartType = "oldChartType",
  placement,
  closePopupCallback,
  addNewPanelCallback,
}) => {
  const [selected, setSelected] = useState(-1);
  return (
    <div className="panel-popup">
      <div className="panel-popup-inner">
        <div className="panel-popup-header">
          <div
            className="panel-popup-go-back"
            style={selected === -1 ? { display: "none" } : null}
            onClick={() => setSelected(-1)}
          >
            <img src={GoBackIcon} alt="Choose another one" />
          </div>

          <div
            className="panel-popup-cancel"
            onClick={() => closePopupCallback(false)}
          >
            <img src={CancelIcon} alt="Cancel" />
          </div>
        </div>
        <div
          className={
            selected === -1
              ? "panel-popup-item-wrapper"
              : "panel-popup-overflow"
          }
        >
          <PanelCategoryItem
            id={0}
            selected={selected}
            setSelected={setSelected}
            title="None"
            desc="desc"
          >
            <PanelListItem
              panelId={id}
              placement={placement}
              title="None"
              desc="Reset to default"
              type="none"
              oldType={oldType}
              category="overview"
              closePopupCallback={closePopupCallback}
              addNewPanelCallback={addNewPanelCallback}
            />
          </PanelCategoryItem>

          <PanelCategoryItem
            id={1}
            selected={selected}
            setSelected={setSelected}
            title="Charts"
            desc="desc"
          >
            <PanelListItem
              panelId={id}
              placement={placement}
              title="Profile"
              desc="Profile with basic information of specified game"
              type="profile"
              oldType={oldType}
              category="overview"
              closePopupCallback={closePopupCallback}
              addNewPanelCallback={addNewPanelCallback}
            />
            <PanelListItem
              panelId={id}
              placement={placement}
              title="Line Chart"
              desc="Game statistics shown in chart format"
              type="chart"
              chartType="line"
              oldType={oldType}
              oldChartType={oldChartType}
              category="overview"
              closePopupCallback={closePopupCallback}
              addNewPanelCallback={addNewPanelCallback}
            />
            <PanelListItem
              panelId={id}
              placement={placement}
              title="Bar Chart"
              desc="Game statistics shown in chart format"
              type="chart"
              chartType="bar"
              oldType={oldType}
              oldChartType={oldChartType}
              category="overview"
              closePopupCallback={closePopupCallback}
              addNewPanelCallback={addNewPanelCallback}
            />
            <PanelListItem
              panelId={id}
              placement={placement}
              title="Radar Chart"
              desc="Game statistics shown in chart format"
              type="chart"
              chartType="radar"
              oldType={oldType}
              oldChartType={oldChartType}
              category="overview"
              closePopupCallback={closePopupCallback}
              addNewPanelCallback={addNewPanelCallback}
            />
            <PanelListItem
              panelId={id}
              placement={placement}
              title="Polar Chart"
              desc="Game statistics shown in chart format"
              type="chart"
              chartType="polar"
              oldType={oldType}
              oldChartType={oldChartType}
              category="overview"
              closePopupCallback={closePopupCallback}
              addNewPanelCallback={addNewPanelCallback}
            />
            <PanelListItem
              panelId={id}
              placement={placement}
              title="Pie Chart"
              desc="Game statistics shown in chart format"
              type="chart"
              chartType="pie"
              oldType={oldType}
              oldChartType={oldChartType}
              category="overview"
              closePopupCallback={closePopupCallback}
              addNewPanelCallback={addNewPanelCallback}
            />
            <PanelListItem
              panelId={id}
              placement={placement}
              title="Doughnut Chart"
              desc="Game statistics shown in chart format"
              type="chart"
              chartType="doughnut"
              oldType={oldType}
              oldChartType={oldChartType}
              category="overview"
              closePopupCallback={closePopupCallback}
              addNewPanelCallback={addNewPanelCallback}
            />
          </PanelCategoryItem>

          <PanelCategoryItem
            id={2}
            selected={selected}
            setSelected={setSelected}
            title="Lists"
            desc="desc"
          >
            <PanelListItem
              panelId={id}
              placement={placement}
              title="Goals List"
              desc="List of all goals you currently have"
              type="goalsList"
              oldType={oldType}
              category="overview"
              closePopupCallback={closePopupCallback}
              addNewPanelCallback={addNewPanelCallback}
            />
            <PanelListItem
              panelId={id}
              placement={placement}
              title="Notes List"
              desc="List of all notes you currently have"
              type="notesList"
              oldType={oldType}
              category="overview"
              closePopupCallback={closePopupCallback}
              addNewPanelCallback={addNewPanelCallback}
            />
          </PanelCategoryItem>
        </div>
      </div>
    </div>
  );
};

export default ModifyPanelPopup;
