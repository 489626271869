import React, { useState, useEffect } from "react";
import NotesListPanelItem from "./NotesListPanelItem.js";
import NotesListPanelSettings from "./NotesListPanelSettings.js";
import SettingsIcon from "../../../../images/setting-icon.svg";
import Cancel from "../../../../images/cancel.svg";

const NotesListPanel = ({
  id,
  handleDelete,
  handleModify,
  game,
  data,
  placement = NaN,
}) => {
  const [showSettings, setShowSettings] = useState(false);
  const [noteData, setNoteData] = useState(null);
  const [noteItems, setNoteItems] = useState([]);

  useEffect(() => {
    let profileId = localStorage.getItem("profileId");
    fetch(process.env.REACT_APP_DOMAIN + `/note/getAll/${profileId}`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((d) => (d.status === 200 ? setNoteData(d.message) : null))
      .catch((e) => e);
  }, []);

  useEffect(() => {
    if (noteData !== null) {
      setNoteItems(
        noteData.map((n) => {
          let date = new Date(parseInt(n.last_updated));
          return (
            <NotesListPanelItem
              key={n.note_id}
              id={n.note_id}
              title={n.note_name}
              lastChange={date.toLocaleTimeString()}
            />
          );
        })
      );
    }
  }, [noteData]);

  return (
    <div className="stats-panel">
      <div
        className="stats-panel-settings-icon-wrapper"
        onClick={() => setShowSettings((s) => !s)}
      >
        <img
          className="stats-panel-settings-icon"
          src={showSettings ? Cancel : SettingsIcon}
          alt="Delete"
        />
      </div>

      <div className="panel-notes-wrapper">
        <div className="panel-notes-header">Notes</div>
        {noteItems.length > 0 ? (
          <div className="panel-notes-list-wrapper">{noteItems}</div>
        ) : (
          <div className="panel-notes-no-items-wrapper">
            <div className="panel-notes-no-items">No notes found</div>
          </div>
        )}
      </div>

      <div
        className="stats-panel-settings-wrapper"
        style={showSettings ? { display: "flex" } : { display: "none" }}
      >
        <NotesListPanelSettings
          id={id}
          placement={placement}
          handleDelete={handleDelete}
          handleModify={handleModify}
        />
      </div>
    </div>
  );
};

export default NotesListPanel;
