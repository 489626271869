import React from "react";
import edit from "../../../images/edit.svg";

const EditButton = ({ click, name }) => {
  return (
    <div onClick={() => click(name)} className="settings-field-btn">
      <img style={{ width: "20px", height: "20px" }} src={edit} alt="edit" />
    </div>
  );
};

export default EditButton;
