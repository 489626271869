import React, { useEffect, useState, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import Quill from "quill";
import Tag from "./Tag.js";
import AddIcon from "../../../../../../images/add.svg";
import YesIcon from "../../../../../../images/checkmark.svg";

const Content = ({
  id,
  isShowing,
  callback,
  tags,
  color,
  text,
  updateGoalData,
}) => {
  const [quill, setQuill] = useState(null);

  const [tagDatas, setTagDatas] = useState(tags);
  const [tagPanels, setTagPanels] = useState([]);
  const [showEditTag, setShowEditTag] = useState(false);
  const [newTagValue, setNewTagValue] = useState("");
  const [updatingColor, setUpdatingColor] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [colorValue, setColorValue] = useState(
    color.length > 0 ? color : "#118ab2"
  );

  const handleChange = (e, type) => {
    if (type === "color") {
      setColorValue(e.target.value);
      callback(e.target.value);
      setUpdatingColor(true);
    }
    if (type === "tag" && e.target.value.length <= 25) {
      setNewTagValue(e.target.value);
    }
  };

  const handleClick = (type) => {
    if (type === "edit") {
      setShowEditTag((t) => !t);
    }
  };

  const handleDelete = useCallback(
    (id) => {
      setTagDatas(tagDatas.filter((t) => id !== t.id));
    },
    [setTagDatas, tagDatas]
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newTagValue.length > 0 && newTagValue.length <= 25) {
      setTagDatas((t) => [...t, { id: uuidv4(), title: newTagValue }]);
      setShowEditTag(false);
      setNewTagValue("");
    } else if (newTagValue.length === 0) {
      setShowEditTag(false);
    }
  };

  if (quill !== null) {
    quill.on("text-change", function (badDelta, oldDelta, source) {
      if (source === "user") setUploading(true);
    });
  }

  useEffect(() => {
    setQuill(
      new Quill(`#todo-editor-${id}`, {
        theme: "bubble",
        bounds: `#todo-editor-${id}`,
        placeholder: "If the description wasn't enough...",
      })
    );
  }, [id]);

  useEffect(() => {
    updateGoalData(id, "tags", tagDatas);
  }, [id, updateGoalData, tagDatas]);

  useEffect(() => {
    if (quill !== null) {
      quill.setContents(text);
    }
  }, [quill, text]);

  useEffect(() => {
    if (uploading) {
      setTimeout(() => {
        let delta = quill.getContents();
        updateGoalData(id, "text", delta);
        setUploading(false);
      }, 500);
    }

    //eslint-disable-next-line
  }, [uploading]);

  useEffect(() => {
    if (updatingColor) {
      setTimeout(() => {
        updateGoalData(id, "color", colorValue);
        setUpdatingColor(false);
      }, 500);
    }
  }, [updatingColor, colorValue, id, updateGoalData]);

  useEffect(() => {
    setTagPanels(
      tagDatas.map((t) => {
        return (
          <Tag
            key={t.id}
            id={t.id}
            title={t.title}
            handleDelete={handleDelete}
          />
        );
      })
    );
  }, [handleDelete, tagDatas]);

  return (
    <div className="goals-item-content-wrapper">
      <div className="goals-item-content-tags-wrapper">
        <div>Tags:</div>
        {tagPanels}

        {showEditTag ? (
          <form
            onSubmit={handleSubmit}
            className="goals-item-content-add-new-tag"
          >
            <input
              onChange={(e) => handleChange(e, "tag")}
              type="text"
              value={newTagValue}
              autoFocus
            />
            <button type="submit">
              <img src={YesIcon} alt="Yes" />
            </button>
          </form>
        ) : (
          <img onClick={() => handleClick("edit")} src={AddIcon} alt="Add" />
        )}
      </div>
      <div className="goals-item-content-color-wrapper">
        <div>Color:</div>
        <div className="goals-item-content-color-selector">
          <div>{colorValue}</div>
          <input
            onChange={(e) => handleChange(e, "color")}
            value={colorValue}
            type="color"
            alt="Color"
          />
        </div>
      </div>
      <div
        style={isShowing ? { height: "150px" } : null}
        className="goals-item-content-extension"
      >
        <div id={`todo-editor-${id}`}></div>
      </div>
    </div>
  );
};

export default Content;
