import React, { useState, useEffect } from "react";
import ChartPanelLine from "./ChartTypes/ChartPanelLine.js";
import ChartPanelBar from "./ChartTypes/ChartPanelBar.js";
import ChartPanelPolar from "./ChartTypes/ChartPanelPolar.js";
import ChartPanelRadar from "./ChartTypes/ChartPanelRadar.js";
import ChartPanelPie from "./ChartTypes/ChartPanelPie.js";
import ChartPanelDoughnut from "./ChartTypes/ChartPanelDoughnut.js";
import ChartPanelSettings from "./ChartPanelSettings.js";
import SettingsIcon from "../../../../images/setting-icon.svg";
import Cancel from "../../../../images/cancel.svg";

const ChartPanel = ({
  id,
  handleDelete,
  handleModify,
  game,
  data,
  placement = NaN,
}) => {
  const [showSettings, setShowSettings] = useState(false);
  const [values, setValues] = useState(["none", "none", "none", "none"]);
  const [type, setType] = useState("line");
  const [chart, setChart] = useState(null);
  const [title, setTitle] = useState("Line Chart");

  function setValuesCallback(value) {
    setValues(value);
  }

  function setTypeCallback(type) {
    setType(type);
  }

  useEffect(() => {
    switch (type.toLowerCase()) {
      case "line":
        setTitle("Line Chart");
        setChart(
          <ChartPanelLine selected={values} data={data} placement={placement} />
        );
        break;
      case "bar":
        setTitle("Bar Chart");
        setChart(
          <ChartPanelBar selected={values} data={data} placement={placement} />
        );
        break;
      case "polar":
        setTitle("Polar Chart");
        setChart(
          <ChartPanelPolar
            selected={values}
            data={data}
            placement={placement}
          />
        );
        break;
      case "pie":
        setTitle("Pie Chart");
        setChart(
          <ChartPanelPie selected={values} data={data} placement={placement} />
        );
        break;
      case "doughnut":
        setTitle("Doughnut Chart");
        setChart(
          <ChartPanelDoughnut
            selected={values}
            data={data}
            placement={placement}
          />
        );
        break;
      case "radar":
        setTitle("Radar Chart");
        setChart(
          <ChartPanelRadar
            selected={values}
            data={data}
            placement={placement}
          />
        );
        break;
      default:
        setTitle("Line Chart");
        setChart(
          <ChartPanelLine selected={values} data={data} placement={placement} />
        );
        break;
    }
  }, [placement, type, values, data]);

  return (
    <div className="stats-panel">
      <div
        className="stats-panel-settings-icon-wrapper"
        onClick={() => setShowSettings((s) => !s)}
      >
        <img
          className="stats-panel-settings-icon"
          src={showSettings ? Cancel : SettingsIcon}
          alt="Delete"
        />
      </div>

      <div className="stats-panel-chart-wrapper">
        <div className="stats-panel-chart-header">
          <p>{title}</p>
        </div>
        <div className="stats-panel-chart-line-outer">{chart}</div>
      </div>

      <div
        className="stats-panel-settings-wrapper"
        style={showSettings ? { display: "flex" } : { display: "none" }}
      >
        <ChartPanelSettings
          id={id}
          placement={placement}
          handleDelete={handleDelete}
          handleModify={handleModify}
          data={data}
          setValuesCallback={setValuesCallback}
          setTypeCallback={setTypeCallback}
        />
      </div>
    </div>
  );
};

export default ChartPanel;
