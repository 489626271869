import React, { useState, useEffect } from "react";
import ProfileSelect from "./ProfileSelect";

// Get all profile data
// Loop through the profiles
// Add text, id and game (empty string if it doesn't exist) to ProfileSelect

const ProfileSettings = () => {
  const [response, setResponse] = useState(null);
  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    let controller = new AbortController();
    let signal = controller.signal;
    fetch(process.env.REACT_APP_DOMAIN + `/profile/getAll`, {
      signal,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((d) => setResponse(d))
      .catch((err) => err);

    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (response !== null) {
      if (response.status === 200) {
        setProfiles(
          response.message.map((p) => {
            return (
              <ProfileSelect
                key={p.profile_id}
                text={p.profile_title}
                id={p.profile_id}
                game={p.profile_game === null ? "" : p.profile_game}
              />
            );
          })
        );
      }
    }
  }, [response]);

  return <div className="profile-settings-wrapper">{profiles}</div>;
};

export default ProfileSettings;
