import React from "react";
import DeleteUser from "./DeleteUser.js";
import SearchUser from "./SearchUser.js";
import CreateAccount from "./CreateAccount.js";

const AdminContent = () => {
  return (
    <div className="admin-content-wrapper">
      <div className="admin-content-left">
        <DeleteUser />
        <SearchUser />
      </div>
      <div className="admin-content-right">
        <CreateAccount />
      </div>
    </div>
  );
};

export default AdminContent;
