import React, { useState } from "react";

const CreateAccount = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [returnedUsername, setReturnedUsername] = useState("");
  const [returnedUserId, setReturnedUserId] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (username.length > 3 && email.length > 1 && password.length > 5) {
      fetch(process.env.REACT_APP_DOMAIN + "/auth/signup", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
        body: JSON.stringify({
          user_name: username.trim(),
          user_email: email.trim(),
          user_password: password.trim(),
          user_confirmed_password: password.trim(),
          user_agreed_legal: true,
          send_cookies: false,
        }),
      })
        .then((res) => res.json())
        .then((d) => {
          if (d.status === 200) {
            setReturnedUserId(d.message.user_id);
            setReturnedUsername(username);
            setUsername("");
            setPassword("");
            setEmail("");
          }
        });
    }
  };

  return (
    <div className="admin-form-wrapper">
      <form onSubmit={handleSubmit} className="admin-create-account-wrapper">
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button type="submit">Create user</button>
      </form>
      <div>
        <p>{returnedUserId}</p>
        <p>{returnedUsername}</p>
      </div>
    </div>
  );
};

export default CreateAccount;
