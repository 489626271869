import React, { useState, useEffect } from "react";
import Quill from "quill";
import { useLocation } from "react-router-dom";
import { queryParser } from "../../customFunctions/url.js";
import { toDateTimeString } from "../../customFunctions/handleData.js";
import Loader from "../reusable/Loader.js";
import DiscordIcon from "../../images/discord.png";

const SharedNote = () => {
  const [quill, setQuill] = useState(null);
  const [error, setError] = useState(false);
  const [noteData, setNoteData] = useState(null);
  const location = useLocation();
  let query = queryParser(location.search);
  if (query.id === undefined) query = { id: "none" };

  useEffect(() => {
    setQuill(
      new Quill("#editor", {
        theme: "bubble",
      })
    );
  }, []);

  useEffect(() => {
    if (quill !== null) {
      quill.enable(false);
      fetch(process.env.REACT_APP_DOMAIN + `/note/getSharedNote/${query.id}`, {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
      })
        .then((res) => res.json())
        .then((d) => {
          if (d.status === 200) {
            setNoteData(d.message);
            quill.setContents(d.message.data);
          } else {
            setError(true);
          }
        });
    }
  }, [quill, query.id]);

  if (query.id) {
    if (!error) {
      return (
        <div className="snotes-wrapper">
          <div className="snotes-editor">
            <div
              style={
                noteData !== null ? { display: "flex" } : { display: "none" }
              }
              id="editor"
            ></div>
            <div
              style={
                noteData !== null ? { display: "none" } : { display: "flex" }
              }
              className="snotes-loader"
            >
              <Loader />
            </div>
          </div>
          <div className="snotes-bottom-wrapper">
            {noteData !== null ? (
              <>
                <div className="snotes-share-discord-wrapper">
                  <a
                    href="https://discord.gg/UrTBPvu"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={DiscordIcon} alt="Discord's Logo" />
                    <h3>Share on discord!</h3>
                  </a>
                </div>
                <div>Title: {noteData.note_name}</div>
                <div>Writer: {noteData.user_name}</div>
                <div>
                  Last edit:{" "}
                  {toDateTimeString(new Date(parseInt(noteData.last_updated)))}
                </div>
              </>
            ) : (
              <div className="snotes-loader">
                <Loader />
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return <div>Text could not be found</div>;
    }
  } else {
    return <div>No note found</div>;
  }
};

export default SharedNote;
