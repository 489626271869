import React from "react";
import DiscordLogo from "../images/discord.png";

const FAQ = () => {
  return (
    <div className="faq-wrapper">
      <h3>
        There doesn't seem to have been any questions asked. However, you're
        welcome to ask your question at our discord server:
      </h3>
      <a href="https://discord.gg/UrTBPvu">
        <img src={DiscordLogo} alt="Discord" />
        <p>Discord</p>
      </a>
    </div>
  );
};

export default FAQ;
