import React from "react";

const NewPanel = ({ closePopupCallback }) => {
  return (
    <div
      onClick={() => closePopupCallback(true)}
      className="stats-new-panel stats-panel"
    >
      <p className="stats-new-panel-text">Add Panel</p>
    </div>
  );
};

export default NewPanel;
