export async function getGameNames() {
  let response = "error";
  await fetch(process.env.REACT_APP_DOMAIN + `/gameNames/get`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((d) => (response = d.message))
    .catch((err) => console.log(err));

  return response;
}

export async function postGameName(name, type) {
  let response = {};

  await fetch(process.env.REACT_APP_DOMAIN + "/gameNames/post", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
    },
    credentials: "include",
    body: JSON.stringify({
      apex_name: type === "apexLegends" ? name : "",
      league_name: type === "leagueOfLegends" ? name : "",
    }),
  })
    .then((res) => res.json())
    .then((data) => (response = data));

  return response;
}

export async function updateGameName(name, type) {
  let response = {};

  await fetch(process.env.REACT_APP_DOMAIN + "/gameNames/update", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
    },
    credentials: "include",
    body: JSON.stringify({
      apex_name: type === "apexLegends" ? name : "",
      league_name: type === "leagueOfLegends" ? name : "",
    }),
  })
    .then((res) => res.json())
    .then((data) => (response = data));

  return response;
}

export async function getFromApexName(apexName) {
  try {
    let response = {};

    await fetch(process.env.REACT_APP_DOMAIN + `/apex/get/all/${apexName}`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => (response = data));

    return response;
  } catch (err) {
    console.log(err);
  }
}

export async function postApexName(apexName, apexPlatform) {
  try {
    let response = {};

    await fetch(process.env.REACT_APP_DOMAIN + "/apex/post", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
      body: JSON.stringify({
        apex_name: apexName,
        apex_platform: apexPlatform,
      }),
    })
      .then((res) => res.json())
      .then((data) => (response = data));

    return response;
  } catch (err) {
    console.log(err);
  }
}

export async function getFromLeagueName(leagueName, leagueRegion) {
  try {
    let response = {};

    await fetch(
      process.env.REACT_APP_DOMAIN + `/league/get/all/${leagueName}`,
      {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((data) => (response = data));

    if (response.status === 200) {
      if (!response.message.league_regions.includes(leagueRegion)) {
        return { status: 404, message: "Region not found" };
      }
    }

    return response;
  } catch (err) {
    console.log(err);
  }
}

export async function postLeagueName(leagueName, leagueRegion) {
  try {
    let response = {};

    await fetch(process.env.REACT_APP_DOMAIN + "/league/post", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
      body: JSON.stringify({
        league_name: leagueName,
        league_region: leagueRegion,
      }),
    })
      .then((res) => res.json())
      .then((data) => (response = data));

    return response;
  } catch (err) {
    console.log(err);
  }
}
