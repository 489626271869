import React from "react";
import PrimarySectionItems from "./PrimarySectionItems";
import OverviewIcon from "../../../images/Tasks/overview-icon.svg";
import StatisticsIcon from "../../../images/Tasks/statistics-icon.svg";
import GoalsIcon from "../../../images/Tasks/tasks-icon.svg";
import NotesIcon from "../../../images/Tasks/notes-icon.svg";

const Navbar = () => {
  let primaryTitles = ["OVERVIEW", "STATISTICS", "GOALS", "NOTES"];
  let primaryIcons = [OverviewIcon, StatisticsIcon, GoalsIcon, NotesIcon];

  return (
    <div className="navbar">
      <PrimarySectionItems rawIcons={primaryIcons} rawTitles={primaryTitles} />
    </div>
  );
};

export default Navbar;
