import React from "react";
import { v4 as uuidv4 } from "uuid";

const PanelListItem = ({
  panelId = "",
  chartType = "chartType",
  placement = NaN,
  oldChartType = "oldChartType",
  oldType = "none",
  image = null,
  title,
  desc,
  type,
  category,
  closePopupCallback,
  addNewPanelCallback,
}) => {
  const handleClick = (panelId, placement, type, category) => {
    let uuid = uuidv4();

    if (category === "overview") {
      addNewPanelCallback(panelId, placement, type);

      if (type === "chart" && oldChartType !== chartType) {
        fetch(process.env.REACT_APP_DOMAIN + `/panel/deleteChart/${panelId}`, {
          method: "DELETE",
          headers: {
            "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
          },
          credentials: "include",
        })
          .then(() => {
            fetch(process.env.REACT_APP_DOMAIN + "/panel/postChart", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
              },
              credentials: "include",
              body: JSON.stringify({
                stat_id: "",
                chart_id: panelId,
                chart_type: chartType,
              }),
            }).catch((e) => null);
          })
          .catch((e) => null);
      }
    }

    if (category === "stats") {
      fetch(process.env.REACT_APP_DOMAIN + "/stat/post", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
        body: JSON.stringify({
          profile_id: localStorage.getItem("profileId"),
          stat_id: uuid,
          stat_type: type,
          chart_type: chartType,
        }),
      })
        .then((res) => res.json())
        .then((d) =>
          d.status === 200 ? addNewPanelCallback(uuid, type) : null
        )
        .catch((e) => null);
    }
    closePopupCallback(false);
  };
  return (
    <>
      <div
        className="panel-item"
        onClick={() => handleClick(panelId, placement, type, category)}
      >
        <div>
          {image !== null ? (
            <img className="panel-item-image" src={image} alt={title} />
          ) : null}
          <div className="panel-item-title">{title}</div>
        </div>

        <div className="panel-item-hover">
          <div className="panel-item-desc">{desc}</div>
        </div>
      </div>
    </>
  );
};

export default PanelListItem;
