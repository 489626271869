import React, { useState, useEffect } from "react";

const Tabs = ({
  ids = [],
  names = [],
  handleClick,
  activeTab,
  setActiveTab,
}) => {
  const [tabs, setTabs] = useState([]);
  useEffect(() => {
    setTabs(
      names.map((e, i) => {
        return (
          <div className="notes-tab" key={e + i}>
            <div
              className="notes-tab"
              onClick={() => {
                handleClick(ids[i]);
                setActiveTab(i);
              }}
              style={
                activeTab === i
                  ? { background: "var(--contrast-lighter-background-color)" }
                  : null
              }
            >
              <p className="notes-tab-text">{e}</p>
            </div>
            {i === names.length - 1 ? null : (
              <div className="notes-divider"></div>
            )}
          </div>
        );
      })
    );
  }, [setActiveTab, ids, names, handleClick, activeTab]);
  return <div className="notes-tabs-wrapper">{tabs}</div>;
};

export default Tabs;
