import React, { useState } from "react";
import ErrorPanelSettings from "./ErrorPanelSettings.js";
import SettingsIcon from "../../../../images/setting-icon.svg";
import Cancel from "../../../../images/cancel.svg";

const ErrorPanel = ({
  id,
  type,
  handleDelete,
  handleModify,
  game,
  data,
  placement = NaN,
}) => {
  const [showSettings, setShowSettings] = useState(false);

  return (
    <div className="stats-panel">
      <div
        className="stats-panel-settings-icon-wrapper"
        onClick={() => setShowSettings((s) => !s)}
      >
        <img
          className="stats-panel-settings-icon"
          src={showSettings ? Cancel : SettingsIcon}
          alt="Delete"
        />
      </div>

      <div className="stats-panel-error-wrapper">
        <h1>Error</h1>
        {game === "CSGO" ? (
          <h3>Are your steam profile set to public?</h3>
        ) : (
          <h3>Try reload the page.</h3>
        )}

        <p>If this error continues to consist, please contact us at:</p>
        <p>contact@bepity.com</p>
      </div>

      <div
        className="stats-panel-settings-wrapper"
        style={showSettings ? { display: "flex" } : { display: "none" }}
      >
        <ErrorPanelSettings
          id={id}
          type={type}
          placement={placement}
          handleDelete={handleDelete}
          handleModify={handleModify}
        />
      </div>
    </div>
  );
};

export default ErrorPanel;
