import React, { useEffect, useState, useContext } from "react";
import { UpdateProfileContext } from "../../../../context/update-profile-context.js";
import GoalsItemWrapper from "./GoalsItemWrapper.js";
import GoalsAdd from "./GoalsAdd/GoalsAdd.js";
import GoalsSearch from "./GoalsSearch.js";
import AddIcon from "../../../../images/add.svg";

import "../../../../css/desktop/Goals.css";
import "../../../../css/mobile/MobileGoals.css";
import "../../../../css/global/flatpickr-dark-mode.css";

const Goals = () => {
  const { updateProfile } = useContext(UpdateProfileContext);
  const [showAddWindow, setShowAddWindow] = useState(false);
  const [goalData, setGoalData] = useState([]);
  const [filteredData, setFilteredData] = useState(goalData);
  const [deletedItems, setDeletedItems] = useState([]);
  const [updateSearch, setUpdateSearch] = useState(false);
  const [showButton, setShowButton] = useState(true);

  let profileId = localStorage.getItem("profileId");

  function deleteItem(id) {
    fetch(process.env.REACT_APP_DOMAIN + `/goal/delete/${id}`, {
      method: "DELETE",
      headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((d) =>
        d.status === 200 ? setDeletedItems((d) => [...d, id]) : null
      );
  }

  function openAddWindow(state) {
    setShowAddWindow(state);
  }

  async function addNewGoal(id, values) {
    await setGoalData((d) => [
      ...d,
      { ...values, id: id, checked: false, autoCategory: "none", autoValue: 0 },
    ]);
    setUpdateSearch((s) => !s);
  }

  function handleDatabaseGoalData(data) {
    setGoalData(
      data.map((e) => {
        return {
          id: e.goal_id,
          title: e.goal_title,
          desc: e.goal_desc,
          dateTime: e.goal_deadline,
          tags: e.goal_tags,
          color: e.goal_background,
          text: e.goal_text,
          checked: e.goal_checked,
          autoCategory: e.goal_auto_category,
          autoValue: e.goal_auto_value,
          autoBaseValue: e.goal_auto_base_value,
        };
      })
    );
  }

  function filterDataBySearch(data) {
    setFilteredData(data);
  }

  function getIndexFromObject(array, id) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) return i;
    }
    return -1;
  }

  async function updateGoalData(id, item, data) {
    let arrIndex = getIndexFromObject(goalData, id);
    let updatedGoalData = goalData;

    updatedGoalData[arrIndex] = {
      ...updatedGoalData[arrIndex],
      [item]: data,
    };

    await fetch(process.env.REACT_APP_DOMAIN + "/goal/put", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
      body: JSON.stringify({
        profile_id: profileId,
        goal_id: updatedGoalData[arrIndex].id,
        goal_title: updatedGoalData[arrIndex].title,
        goal_desc: updatedGoalData[arrIndex].desc,
        goal_tags: updatedGoalData[arrIndex].tags,
        goal_deadline: updatedGoalData[arrIndex].dateTime,
        goal_text: updatedGoalData[arrIndex].text,
        goal_checked: updatedGoalData[arrIndex].checked,
        goal_background: updatedGoalData[arrIndex].color,
        goal_auto_category: updatedGoalData[arrIndex].autoCategory,
        goal_auto_value: updatedGoalData[arrIndex].autoValue,
        goal_auto_base_value: updatedGoalData[arrIndex].autoBaseValue,
      }),
    })
      .then((res) => res.json())
      .then((d) => (d.status === 200 ? setGoalData(updatedGoalData) : null))
      .then(() => setUpdateSearch((s) => !s))
      .catch((e) => null);
  }

  useEffect(() => {
    fetch(process.env.REACT_APP_DOMAIN + `/goal/getAll/${profileId}`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((d) =>
        d.status === 200 ? handleDatabaseGoalData(d.message) : null
      );
  }, [profileId, updateProfile]);

  useEffect(() => {
    setUpdateSearch((s) => !s);
  }, [goalData]);

  return (
    <div className="goals-wrapper">
      <GoalsSearch
        update={updateSearch}
        data={goalData}
        updateDataCallback={filterDataBySearch}
      />
      <GoalsItemWrapper
        goalData={filteredData}
        updateGoalData={updateGoalData}
        deletedItems={deletedItems}
        deleteItem={deleteItem}
        setShowButton={setShowButton}
      />
      {showAddWindow ? (
        <GoalsAdd
          addNewGoalCallback={addNewGoal}
          openAddWindowCallback={openAddWindow}
        />
      ) : null}

      {showButton ? (
        <div className="goals-add-new-icon" onClick={() => openAddWindow(true)}>
          <img src={AddIcon} alt="Add new goal" />
        </div>
      ) : null}
    </div>
  );
};

export default Goals;
