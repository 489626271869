import React, { useState, useEffect, useContext } from "react";
import { UpdateProfileContext } from "../../../context/update-profile-context.js";
import UpdateIcon from "../../../images/update.svg";

const GameDataUpdate = () => {
  const [updating, setUpdating] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(0);
  const [timer, setTimer] = useState(null);
  const [finishedPercent, setFinishedPercent] = useState("0");
  const { setUpdateProfile } = useContext(UpdateProfileContext);
  const timeToNextUpdate = 2 * 60000;
  let profileId = localStorage.getItem("profileId");

  useEffect(() => {
    fetch(process.env.REACT_APP_DOMAIN + `/games/getLastUpdate/${profileId}`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((d) => (d.status === 200 ? setLastUpdate(d.message) : null))
      .catch((e) => e);
  }, [profileId, updated]);

  useEffect(() => {
    let now = Date.now();
    let timeGoal =
      timeToNextUpdate - (now + (now - lastUpdate) - Date.now()) + Date.now();
    let intervalID = window.setInterval(() => {
      let timeSinceLastUpdate = timeGoal - Date.now();

      if (timeSinceLastUpdate > 0) {
        let minutes = Math.floor((timeSinceLastUpdate / 1000 / 60) % 60);
        let seconds = Math.floor((timeSinceLastUpdate / 1000) % 60);

        setFinishedPercent(
          Math.floor(
            (timeToNextUpdate - timeSinceLastUpdate) / (timeToNextUpdate / 100)
          )
        );
        if (seconds >= 0 && seconds <= 9) setTimer(`${minutes}:0${seconds}`);
        else setTimer(`${minutes}:${seconds}`);
      } else {
        setTimer(null);
        clearInterval(intervalID);
      }
    }, 1000);

    return () => {
      clearInterval(intervalID);
    };
  }, [timeToNextUpdate, lastUpdate]);

  const handleClick = () => {
    let randomInt = Math.floor(Math.random() * 100) + 1000;
    setUpdating(true);
    setTimeout(() => {
      fetch(process.env.REACT_APP_DOMAIN + "/games/update", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
        body: JSON.stringify({
          profile_id: profileId,
        }),
      })
        .then(() =>
          setTimeout(() => {
            setUpdateProfile((p) => !p);
          }, 500)
        )
        .then(() => setUpdated((u) => !u))
        .catch((e) => e);

      setTimeout(() => {
        setUpdating(false);
      }, 1800);
    }, randomInt);
  };

  return (
    <div className="main-header-update-wrapper">
      {timer === null ? null : (
        <div className="main-header-timer-wrapper">
          <div className="main-header-timer-bar">
            <div style={{ width: finishedPercent + "%" }}></div>
          </div>
          <p>{timer}</p>
        </div>
      )}

      {timer === null ? (
        <div
          id={updating ? "update-btn-spin" : null}
          className="main-header-update-spinner"
          onClick={handleClick}
        >
          <img src={UpdateIcon} alt="Update" />
        </div>
      ) : null}
    </div>
  );
};

export default GameDataUpdate;
