import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

const PrimarySectionItems = ({ rawIcons, rawTitles, setPrimaryCallback }) => {
  const [items, setItems] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const location = useLocation();
  const history = useHistory();

  const handleClick = (title, item) => {
    setActiveItem(title);
    history.replace(title.toLowerCase());
  };

  useEffect(() => {
    let pathname = location.pathname.split("/");
    let pathnameSection =
      pathname.length >= 3 ? pathname[2].toUpperCase().trim() : "";

    if (pathnameSection === "NO_PROFILE") {
      setActiveItem("OVERVIEW");
    } else if (rawTitles.indexOf(pathnameSection) === -1) {
      history.replace("/app/overview");
      setActiveItem("OVERVIEW");
    } else {
      setActiveItem(pathnameSection);
    }
  }, [history, rawTitles, location]);

  useEffect(() => {
    if (activeItem !== null) {
      localStorage.setItem(
        "primary",
        JSON.stringify({
          index: rawTitles.indexOf(activeItem.toUpperCase()),
          title: activeItem.toUpperCase(),
        })
      );

      setItems(
        rawTitles.map((title, i) => {
          return (
            <div
              className={
                activeItem.toUpperCase() === title.toUpperCase()
                  ? "sidebar-active"
                  : ""
              }
              onClick={() => handleClick(title, i)}
              key={Math.random() * 1000}
            >
              <img
                key={Math.random() * 1000}
                className="sidebar-section-icon"
                src={rawIcons[i]}
                alt="Representative icon of the text beside it"
              />
              <li key={Math.random() * 1000} className="sidebar-section-text">
                {title}
              </li>
            </div>
          );
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItem, rawIcons, rawTitles]);

  return <div className="sidebar-primary-items-wrapper">{items}</div>;
};

export default PrimarySectionItems;
