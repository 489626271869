import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import { BrowserRouter as Router } from "react-router-dom";
import TempAccountContextProvider from "./context/temp-account-context.js";
import * as serviceWorker from "./serviceWorker";

const AppWithRouter = () => {
  return (
    <TempAccountContextProvider>
      <Router>
        <App />
      </Router>
    </TempAccountContextProvider>
  );
};

ReactDOM.render(<AppWithRouter />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
