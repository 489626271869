import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import SmallLoader from "../../reusable/SmallLoader.js";

const ConfirmationPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  let location = useLocation();
  let queryString = location.search;

  const queryParser = (queryString) => {
    let object = {};
    let queryArray = queryString.replace("?", "").split("&");
    queryArray = queryArray.map((query) => query.split("="));
    for (let i = 0; i < queryArray.length; i++) {
      object[queryArray[i][0]] = queryArray[i][1];
    }
    return object;
  };

  let email = queryParser(queryString).email;

  const handleClick = () => {
    setIsLoading(true);

    fetch(process.env.REACT_APP_DOMAIN + "/resetPassword/sendEmailToken", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
      body: JSON.stringify({
        email: queryParser(queryString).email,
      }),
    }).then(() => setIsLoading(false));
  };

  return (
    <div className="auth-background">
      <div className="auth-wrapper">
        <div className="flex-row">
          <p>Email sent to: </p>
          <p className="bold space"> {email}</p>
        </div>
        <p>It may take a few minutes.</p>
        <p>Haven't got anything? Check your spam or resend.</p>
        <button className="auth-submit-btn" onClick={handleClick}>
          {isLoading ? <SmallLoader reloadMessage={false} /> : "Resend"}
        </button>
      </div>
    </div>
  );
};

export default ConfirmationPassword;
