import React, { useState, useEffect, useContext, useCallback } from "react";
import { UpdateProfileContext } from "../../../../context/update-profile-context.js";
import {
  getData,
  getUsernamesAndGame,
} from "../../../../customFunctions/getData.js";
import PanelHub from "../../Panels/PanelHub.js";
import NoGamePanel from "../../Panels/NoGamePanel.js";
import ModifyPanelPopup from "./ModifyPanelPopup.js";
import { v4 as uuidv4 } from "uuid";

import "../../../../css/desktop/Overview.css";

function Overview() {
  const { updateProfile } = useContext(UpdateProfileContext);
  const [customModifyPanelPopup, setCustomModifyPanelPopup] = useState(null);
  const [overflowValue, setOverflowValue] = useState("visible");
  const [panels, setPanels] = useState([]);
  const [panelsData, setPanelsData] = useState(null);

  let profileId = localStorage.getItem("profileId");

  const closePopupCallback = useCallback(
    (state) => {
      if (!state) {
        setCustomModifyPanelPopup(null);
      }
    },
    [setCustomModifyPanelPopup]
  );

  const modifyPanelCallback = useCallback(
    (id, placement, type) => {
      if (type === "chart")
        setPanels((panel) => panel.map((p, i) => (i === placement ? null : p)));
      let newData = panelsData.map((e, i) => {
        return i === placement ? { type: type, id: id } : e;
      });

      fetch(process.env.REACT_APP_DOMAIN + "/overview/post", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
        body: JSON.stringify({
          profile_id: profileId,
          overview_id: uuidv4(),
          overview_data: newData,
        }),
      });

      setPanelsData(
        panelsData.map((e, i) => {
          return i === placement ? { type: type, id: id } : e;
        })
      );
    },
    [setPanelsData, panelsData, profileId]
  );

  const handleModify = useCallback(
    (id, placement, oldType, chartType) => {
      setCustomModifyPanelPopup(
        <ModifyPanelPopup
          id={id}
          oldType={oldType}
          oldChartType={chartType}
          placement={placement}
          closePopupCallback={closePopupCallback}
          addNewPanelCallback={modifyPanelCallback}
        />
      );
    },
    [setCustomModifyPanelPopup, closePopupCallback, modifyPanelCallback]
  );

  useEffect(() => {
    fetch(process.env.REACT_APP_DOMAIN + `/overview/get/${profileId}`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((d) =>
        d.status === 200
          ? setPanelsData(d.message.overview_data)
          : setPanelsData([
              { type: "none", id: "" },
              { type: "none", id: "" },
              { type: "none", id: "" },
              { type: "none", id: "" },
            ])
      )
      .catch((e) => e);
  }, [updateProfile, profileId]);

  useEffect(() => {
    if (panelsData !== null) {
      getUsernamesAndGame(profileId).then((res) =>
        getData(res[0] !== null ? res[0][res[1]] : null, "all", res[1]).then(
          (d) =>
            setPanels(
              panelsData.map((e, i) => {
                let id = uuidv4();
                let existingGameName =
                  res[0] === null
                    ? null
                    : Object.values(res[0]).filter(
                        (e) => e === null || e === "none" || e === ""
                      ).length !== Object.values(res[0]).length
                    ? true
                    : false;

                if (
                  (res[1] === "none" || res[1] === null) &&
                  (e.type === "chart" || e.type === "profile")
                ) {
                  return (
                    <NoGamePanel
                      key={e.id}
                      id={e.id}
                      type={e.type}
                      placement={i}
                      existingGameName={existingGameName}
                      handleDelete={null}
                      handleModify={handleModify}
                    />
                  );
                } else {
                  if (e.type === "none") {
                    return (
                      <div className="overview-none-button-wrapper">
                        <button onClick={() => handleModify(id, i)}>
                          Add panel
                        </button>
                      </div>
                    );
                  } else {
                    return (
                      <PanelHub
                        key={e.id}
                        id={e.id}
                        placement={i}
                        type={e.type}
                        handleDelete={null}
                        handleModify={handleModify}
                        game={d[1]}
                        data={d[0]}
                      />
                    );
                  }
                }
              })
            )
        )
      );
    }
  }, [handleModify, panelsData, profileId, updateProfile]);

  useEffect(() => {
    if (customModifyPanelPopup === null) {
      setOverflowValue("auto");
    } else {
      setOverflowValue("visible");
      window.scrollTo(0, 0);
    }
  }, [customModifyPanelPopup]);

  return (
    <div
      className="overview-wrapper"
      style={customModifyPanelPopup ? { overflow: overflowValue } : null}
    >
      {customModifyPanelPopup}
      <div className="overview-inner-wrapper">
        <div style={{ gridArea: "tl" }}>{panels[0]}</div>
        <div style={{ gridArea: "tr" }}>{panels[1]}</div>
        <div style={{ gridArea: "bl" }}>{panels[2]}</div>
        <div style={{ gridArea: "br" }}>{panels[3]}</div>
      </div>
    </div>
  );
}

export default Overview;
