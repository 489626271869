import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Loader from "../../reusable/Loader.js";

const HomeContentAction = ({ isLoggedIn, setIsLoggedIn }) => {
  const [showContent, setShowContent] = useState(false);
  const [loading, setIsLoading] = useState(false);
  let history = useHistory();

  const handleClick = (isLoggedIn) => {
    setIsLoading(true);
    if (isLoggedIn) {
      setTimeout(() => {
        history.push("/app");
      }, 1000);
    } else {
      fetch(process.env.REACT_APP_DOMAIN + "/auth/createNoAccount", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
      })
        .then((res) => res.json())
        .then((d) => {
          setIsLoggedIn(true);
          setTimeout(() => {
            history.push("/app");
          }, 1000);
        })
        .catch((e) => e);
    }
  };
  window.addEventListener("scroll", () => {
    if (window.scrollY >= 2160 && !showContent) setShowContent(true);
  });
  return (
    <div className="home-action-wrapper">
      <button
        className="home-action-button"
        onClick={() => handleClick(isLoggedIn)}
        style={showContent ? { opacity: 1, right: 0 } : null}
      >
        {loading ? (
          <Loader reloadMessage={false} color="#fff" />
        ) : isLoggedIn ? (
          <div>Launch App</div>
        ) : (
          <div>Try it now!</div>
        )}
      </button>
      <div className="home-action-divider"></div>
      <div
        className="home-action-text"
        style={showContent ? { opacity: 1, left: 0 } : null}
      >
        {isLoggedIn ? "Welcome back fellow user!" : "No account required"}
      </div>
    </div>
  );
};

export default HomeContentAction;
