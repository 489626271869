import React, { useState } from "react";
import DoneIcon from "../../../../../images/checkmark.svg";
import RemoveIcon from "../../../../../images/cancel.svg";

const Basic = ({ id, title, desc, updateGoalData, isCompleted }) => {
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingDesc, setIsEditingDesc] = useState(false);

  const [titleValue, setTitleValue] = useState(title);
  const [descValue, setDescValue] = useState(
    desc.length > 0 ? desc : "NoDescription"
  );

  const handleClick = (type) => {
    if (type === "title") {
      if (isEditingTitle && titleValue.length > 0) {
        setIsEditingTitle(false);
        updateGoalData(id, "title", titleValue);
      } else {
        setIsEditingTitle(true);
      }
    }
    if (type === "desc") {
      if (isEditingDesc) {
        if (descValue.length > 0) updateGoalData(id, "desc", descValue);
        else setDescValue("NoDescription");
        setIsEditingDesc((d) => false);
      } else {
        if (descValue === "NoDescription") setDescValue("");
        setIsEditingDesc((d) => true);
      }
    }
  };

  const handleChange = (e, type) => {
    if (type === "title") setTitleValue(e.target.value);
    if (type === "desc") setDescValue(e.target.value);
  };

  return (
    <div className="goals-item-basic-wrapper">
      {isEditingTitle ? (
        <div className="goals-item-basic-is-editing">
          <input
            onChange={(e) => handleChange(e, "title")}
            value={titleValue}
            type="text"
            placeholder="Can't be empty"
          />
          <div
            className="goals-item-basic-is-editing-img"
            onClick={() => handleClick("title")}
          >
            <img src={DoneIcon} alt="Done" />
          </div>
        </div>
      ) : (
        <p
          className="goals-item-basic-title"
          onClick={() => handleClick("title")}
          style={
            descValue === "NoDescription"
              ? { position: "relative", top: "9px" }
              : null
          }
        >
          {titleValue}
        </p>
      )}
      {isEditingDesc ? (
        <div className="goals-item-basic-is-editing">
          <input
            onChange={(e) => handleChange(e, "desc")}
            value={descValue}
            type="text"
            placeholder="Description"
          />
          <div
            className="goals-item-basic-is-editing-img"
            onClick={() => handleClick("desc")}
          >
            <img
              src={descValue.length === 0 ? RemoveIcon : DoneIcon}
              alt={descValue.length === 0 ? "Undo" : "Done"}
            />
          </div>
        </div>
      ) : (
        <div
          className="goals-item-basic-desc"
          onClick={() => handleClick("desc")}
          style={
            descValue === "NoDescription"
              ? { position: "relative", top: "9px", opacity: "0" }
              : { opacity: "1" }
          }
        >
          {descValue}
        </div>
      )}
    </div>
  );
};

export default Basic;
