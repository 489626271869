import React, { useState, useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Overview from "./Overview/Overview.js";
import Goals from "./Goals/Goals.js";
import Notes from "./Notes/Notes.js";
import Statistics from "./Statistics/Statistics.js";

const NoProfile = () => {
  const [showFields, setShowFields] = useState(false);
  const [value, setValue] = useState("");
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (value.trim().length >= 2) {
      fetch(process.env.REACT_APP_DOMAIN + "/profile/post", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
        body: JSON.stringify({
          profile_id: uuidv4(),
          profile_title: value.trim(),
        }),
      }).then(() => history.push({ search: "?addNewProfile=true" }));
    }
  };

  return (
    <div className="main-np-wrapper">
      <div className="main-np-inner-wrapper">
        {showFields ? (
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Profile..."
              onChange={(e) => setValue(e.target.value)}
              value={value}
              minLength="2"
              autoFocus
            />
            <button>Add profile</button>
          </form>
        ) : (
          <>
            <h3>Seems that you don't have any profiles!</h3>
            <p>Press the button to add a new profile.</p>
            <button onClick={() => setShowFields((s) => !s)}>
              Create profile
            </button>
          </>
        )}
      </div>
    </div>
  );
};

const Main = () => {
  let history = useHistory();

  const profilesExist = async () => {
    let data;
    let returnVal;

    await fetch(process.env.REACT_APP_DOMAIN + `/profile/getAll`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((d) => (data = d.message));

    returnVal = data.length === 0 ? true : false;
    return returnVal;
  };

  useEffect(() => {
    setTimeout(() => {
      profilesExist().then((res) =>
        res ? history.push("/app/no_profile") : null
      );
    }, 100);
  }, [history]);

  return (
    <div className="main-wrapper">
      <Route strict path="/app/no_profile">
        <NoProfile />
      </Route>
      <Route strict path="/app/overview">
        <Overview />
      </Route>
      <Route strict path="/app/goals">
        <Goals />
      </Route>
      <Route strict path="/app/notes">
        <Notes />
      </Route>
      <Route strict path="/app/statistics">
        <Statistics />
      </Route>
    </div>
  );
};

export default Main;
