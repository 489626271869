import React from "react";
import HomeContentOverview from "./HomeContentOverview.js";
import HomeContentAction from "./HomeContentAction.js";

const HomeContent = ({ isLoggedIn, setIsLoggedIn }) => {
  return (
    <div className="home-content">
      <HomeContentOverview />
      <HomeContentAction
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
      />
    </div>
  );
};

export default HomeContent;
