import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import checkmark from "../../../images/checkmark.svg";
import submit from "./gameFieldModules.js";
import Loader from "../../reusable/Loader";

const GameField = ({
  userId,
  label,
  initUser,
  options,
  game,
  handleNameChange,
  placeholder = "",
  initValue = "",
}) => {
  let history = useHistory();
  let location = useLocation();
  const [user, setUser] = useState(initUser);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(initValue);
  const [platformValue, setPlatformValue] = useState(
    options.length > 0 ? options[0] : ""
  );
  const [error, setError] = useState("");

  function queryParser(queryString) {
    let object = {};
    let queryArray = queryString.replace("?", "").split("&");
    queryArray = queryArray.map((query) => query.split("="));
    for (let i = 0; i < queryArray.length; i++) {
      object[queryArray[i][0]] = queryArray[i][1];
    }
    return object;
  }

  let platforms = [];

  if (options.length > 0) {
    platforms = options.map((p) => {
      return (
        <option key={p} value={p}>
          {p}
        </option>
      );
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading && value.length > 0) {
      setError("");
      setIsLoading(true);
      await submit(
        value,
        initValue,
        platformValue,
        game,
        setError,
        setUser,
        setValue,
        history
      );
      handleNameChange();
      setIsLoading(false);
    } else if (value.length === 0) {
      setError("");
    }
  };

  const handleChange = (e, type) => {
    if (!isLoading && game !== "leagueOfLegends") {
      if (type === "input") setValue(e.target.value);
      if (type === "select") setPlatformValue(e.target.value);
    }
  };

  useEffect(() => {
    if (game === "steam") {
      if (queryParser(location.search).steamLoggedOut) {
        setValue("");
      }
    }
  }, [game, location]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div
          style={game === "steam" ? { marginBlockEnd: 0 } : null}
          className="settings-game-wrapper"
        >
          <label className="settings-game-label">{label}</label>
          <div className="settings-field-wrapper">
            <input
              className="settings-game-field"
              type="text"
              placeholder={placeholder}
              onChange={(e) => handleChange(e, "input")}
              value={value}
              style={isLoading ? { cursor: "progress" } : null}
            />

            {options.length === 0 ? null : (
              <select
                className="settings-game-select"
                onChange={(e) => handleChange(e, "select")}
              >
                {platforms}
              </select>
            )}
            <div
              className="settings-field-btn"
              onClick={handleSubmit}
              style={
                isLoading
                  ? { cursor: "progress" }
                  : value !== "" && initValue !== value
                  ? { background: "var(--green-color)", cursor: "pointer" }
                  : { cursor: "not-allowed" }
              }
            >
              <img src={checkmark} alt="checkmark" />
            </div>
          </div>
          {game === "steam" ? null : (
            <div className="settings-current-user-wrapper">
              <p>Current user: </p>
              <p style={{ paddingInlineStart: "5px" }} className="bold">
                {user === null ? "None" : user}
              </p>
            </div>
          )}

          {game === "leagueOfLegends" ? (
            <p className="settings-game-disclosure">
              Bepity isn't endorsed by Riot Games and doesn't reflect the views
              or opinions of Riot Games or anyone officially involved in
              producing or managing Riot Games properties. Riot Games, and all
              associated properties are trademarks or registered trademarks of
              Riot Games, Inc.
            </p>
          ) : null}
          <div>{error}</div>
          <Loader reloadMessage={false} isLoading={isLoading} />
        </div>
      </form>
    </>
  );
};

export default GameField;
