import React, { useState, useEffect } from "react";

// On form submit
//
// Update the profile game using the id

const ProfileSelect = ({ text, id, game }) => {
  const [gameNames, setGameNames] = useState([]);
  const [gameIds, setGameIds] = useState([]);
  const [rawGameNames, setRawGameNames] = useState(null);
  const sudonyms = {
    apex: "Apex Legends",
    league: "League of Legends",
    steam: "Steam",
  };

  const [options, setOptions] = useState([
    <option key="none" value="none">
      None
    </option>,
  ]);
  const [selectValue, setSelectValue] = useState(game === "" ? "none" : game);

  const handleChange = (e) => {
    setSelectValue(e.target.value);

    let controller = new AbortController();
    let signal = controller.signal;
    fetch(process.env.REACT_APP_DOMAIN + "/profile/updateGame", {
      signal,
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
      body: JSON.stringify({
        profile_id: id,
        profile_game: e.target.value,
      }),
    }).catch((err) => err);

    return () => controller.abort();
  };

  useEffect(() => {
    let controller = new AbortController();
    let signal = controller.signal;
    fetch(process.env.REACT_APP_DOMAIN + `/gameNames/get`, {
      signal,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((d) => setRawGameNames(d))
      .catch((err) => err);

    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (rawGameNames !== null) {
      if (rawGameNames.status === 200) {
        for (let key in sudonyms) {
          if (rawGameNames.message[key] !== null) {
            if (rawGameNames.message[key].length > 0) {
              if (key === "steam") {
                setGameNames((g) => [...g, "CSGO", "TF2"]);
                setGameIds((g) => [...g, "CSGO", "TF2"]);
              } else {
                setGameNames((g) => [...g, sudonyms[key]]);
                setGameIds((g) => [...g, key]);
              }
            }
          }
        }
      }
    }
    //eslint-disable-next-line
  }, [rawGameNames]);

  useEffect(() => {
    setOptions(
      gameNames.map((name, i) => {
        return (
          <option key={gameIds[i]} value={gameIds[i]}>
            {name}
          </option>
        );
      })
    );
    //eslint-disable-next-line
  }, [gameNames]);

  return (
    <div className="profile-settings-item">
      <div className="profile-settings-item-header">
        <h3>{text}</h3>
      </div>
      <select
        className="profile-settings-item-select"
        onChange={handleChange}
        value={selectValue}
      >
        <option value="none">None</option>
        {options}
      </select>
    </div>
  );
};

export default ProfileSelect;
