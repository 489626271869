import React, { useState, useEffect } from "react";
import GameDataUpdate from "./GameDataUpdate.js";
import { useLocation, useHistory } from "react-router-dom";
import settingsIcon from "../../../images/setting-icon.svg";
import menuIcon from "../../../images/menu.svg";
import { queryParser } from "./headerModules.js";

// Should work

const Header = ({ showSidebarCallback, setHeaderMargin }) => {
  let headerTitle = "";
  let history = useHistory();
  let location = useLocation();

  const [data, setData] = useState(null);
  const [gameData, setGameData] = useState(null);
  const [gameName, setGameName] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [showSelect, setShowSelect] = useState(false);
  const [desktopWidth, setDesktopWidth] = useState(true);
  const [showGameUpdate, setShowGameUpdate] = useState(true);

  const [regions, setRegions] = useState([]);
  const [queueTypes, setQueueTypes] = useState([]);
  const [activeRegion, setActiveRegion] = useState("");
  const [activeQueueType, setActiveQueueType] = useState("");
  const [regionOptions, setRegionOptions] = useState([]);
  const [queueTypeOptions, setQueueTypeOptions] = useState([]);

  if (location.pathname !== "/main/no_profile") {
    headerTitle = location.pathname.split("/")[2];
  } else {
    headerTitle = "No profile found";
  }

  const handleChange = (e, type) => {
    if (type === "region") setActiveRegion(e.target.value);
    if (type === "queue") setActiveQueueType(e.target.value);
  };

  function handleResize() {
    if (window.innerWidth > 970) {
      setDesktopWidth(true);
    } else {
      setDesktopWidth(false);
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize, true);
    return () => window.removeEventListener("resize", handleResize, true);
  }, [desktopWidth]);

  useEffect(() => {
    let controller = new AbortController();
    let signal = controller.signal;
    fetch(process.env.REACT_APP_DOMAIN + `/gameNames/get`, {
      signal,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((d) => (d.status === 200 ? setGameName(d.message.league) : null))
      .catch((err) => err);

    if (window.innerWidth > 970) {
      setDesktopWidth(true);
    } else {
      setDesktopWidth(false);
    }

    return () => controller.abort;
  }, []);

  useEffect(() => {
    let controller = new AbortController();
    let signal = controller.signal;
    if (queryParser(location.search).updateProfile || firstLoad) {
      if (firstLoad) setFirstLoad(false);
      let profileId = localStorage.getItem("profileId");
      fetch(process.env.REACT_APP_DOMAIN + `/profile/get/${profileId}`, {
        signal,
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
      })
        .then((res) => res.json())
        .then((d) => (d.status === 200 ? setData(d.message[0]) : null))
        .catch((err) => err);
    }

    return () => controller.abort;
  }, [location, firstLoad]);

  useEffect(() => {
    try {
      if (data !== null) {
        if (data.profile_game === "none") setShowGameUpdate(false);
        else setShowGameUpdate(true);

        if (data.profile_game === "league") {
          setShowSelect(true);
          if (!desktopWidth) setHeaderMargin(124);
        } else {
          setShowSelect(false);
          if (!desktopWidth) setHeaderMargin(67);
        }
      }
    } catch (err) {}
  }, [data, setHeaderMargin, desktopWidth]);

  useEffect(() => {
    if (desktopWidth) {
      setHeaderMargin(100);
    } else {
      if (data !== null) {
        if (data.profile_game === "league") {
          setHeaderMargin(124);
        } else {
          setHeaderMargin(67);
        }
      } else {
        setHeaderMargin(67);
      }
    }
  }, [desktopWidth, setHeaderMargin, data]);

  useEffect(() => {
    let controller = new AbortController();
    let signal = controller.signal;
    if (setShowSelect && gameName !== null) {
      fetch(process.env.REACT_APP_DOMAIN + `/league/get/last/${gameName}`, {
        signal,
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
      })
        .then((res) => res.json())
        .then((d) => (d.status === 200 ? setGameData(d.message) : null))
        .catch((err) => err);
    }
    // Show select tags with the correct information by sending url

    return () => controller.abort;
  }, [showSelect, gameName]);

  useEffect(() => {
    if (gameData !== null) {
      if (regions.length === 0) {
        var tempRegions = Object.keys(gameData.league_data[0]);
        if (tempRegions.length > 0) {
          setRegions(tempRegions);
          setActiveRegion(tempRegions[0]);
        }
      } else {
        setActiveRegion(regions[0]);
      }
    }
  }, [gameData, regions]);

  useEffect(() => {
    let gData = gameData;
    if (activeRegion.length > 0) {
      if (queueTypes.length === 0) {
        let tempQueueTypes = [];
        for (let key in gData.league_data[0][activeRegion]) {
          if (
            key !== "summoner_id" &&
            key !== "level" &&
            key !== "champion_mastery" &&
            key !== "icon"
          ) {
            tempQueueTypes.push(key);
          }
        }

        if (tempQueueTypes.length > 0) {
          setQueueTypes(tempQueueTypes);
          setActiveQueueType(tempQueueTypes[0]);
        }
      } else {
        setActiveQueueType(queueTypes[0]);
      }
    }
  }, [activeRegion, gameData, queueTypes]);

  useEffect(() => {
    localStorage.setItem(
      "leagueIds",
      JSON.stringify({
        activeQueue: activeQueueType,
        activeRegion: activeRegion,
      })
    );
    // eslint-disable-next-line
  }, [activeRegion, activeQueueType]);

  useEffect(() => {
    if (regions.length > 0) {
      setRegionOptions(
        regions.map((r) => {
          return (
            <option key={r} value={r}>
              {r}
            </option>
          );
        })
      );
    }
  }, [regions]);

  useEffect(() => {
    if (queueTypes.length > 0) {
      setQueueTypeOptions(
        queueTypes.map((q) => {
          return (
            <option key={q} value={q}>
              {q}
            </option>
          );
        })
      );
    }
  }, [queueTypes]);

  return (
    <div className="main-header-wrapper">
      <div className="main-header-mobile-upper">
        <div className="main-header-left">
          <h1 className="main-header-title">{headerTitle}</h1>
        </div>
        <div className="main-header-mobile-right">
          <div className="main-header-update-mobile">
            {showGameUpdate ? <GameDataUpdate /> : null}
          </div>
          <div
            className="main-header-show-sidebar"
            onClick={() => showSidebarCallback(true)}
          >
            <img className="main-menu-icon" src={menuIcon} alt="Menu" />
          </div>
        </div>
      </div>

      <div className="main-header-right">
        <div className="main-header-update-desktop">
          {showGameUpdate ? <GameDataUpdate /> : null}
        </div>
        <div
          style={
            showSelect &&
            regionOptions.length > 0 &&
            queueTypeOptions.length > 0
              ? { display: "flex" }
              : { display: "none" }
          }
          className="main-header-select-wrapper"
        >
          {showSelect && regionOptions.length > 0 ? (
            <select
              className="main-header-select"
              onChange={(e) => handleChange(e, "region")}
            >
              {regionOptions}
            </select>
          ) : null}

          {showSelect && queueTypeOptions.length > 0 ? (
            <select
              className="main-header-select"
              onChange={(e) => handleChange(e, "queue")}
            >
              {queueTypeOptions}
            </select>
          ) : null}
        </div>

        <div
          className="main-header-settings"
          onClick={() => history.push("/settings")}
        >
          <div className="main-settings-icon-wrapper">
            <img className="main-settings-icon" src={settingsIcon} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
