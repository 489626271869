import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import SmallPanelBox from "./SmallPanelBox.js";

const SmallPanel = ({ delta }) => {
  const [links, setLinks] = useState(null);

  const linkify = (text) => {
    let response = null;
    let urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
    text.replace(urlRegex, function (url) {
      if (url) response = url;
    });
    return response;
  };

  useEffect(() => {
    let imageLinks = [];
    let videoLinks = [];
    let generalLinks = [];

    if (delta !== null) {
      delta.ops.forEach((e) => {
        if (e.insert !== undefined) {
          if (e.insert.image !== undefined) {
            imageLinks.push(e.insert.image);
          }
          if (typeof e.insert === "string") {
            let link = linkify(e.insert);
            if (link !== null && link !== undefined) {
              if (
                /(youtube.com|twitch.tv|soundcloud.com|vimeo.com|wistia.com|dailymotion.com|facebook.com|vidyard.com)/.test(
                  link
                )
              ) {
                if (ReactPlayer.canPlay(link)) videoLinks.push(link);
                else generalLinks.push(link);
              } else {
                generalLinks.push(link);
              }
            }
          }
          if (e.insert.video !== undefined) {
            if (videoLinks.indexOf(e.insert.video) === -1) {
              videoLinks.push(e.insert.video);
            }
          }
        }

        if (e.attributes !== undefined) {
          if (
            e.attributes.link !== undefined &&
            /(youtube.com|twitch.tv|soundcloud.com|vimeo.com|wistia.com|dailymotion.com|facebook.com|vidyard.com)/.test(
              e.attributes.link.trim()
            )
          ) {
            if (ReactPlayer.canPlay(e.attributes.link.trim())) {
              videoLinks.push(e.attributes.link.trim());
            } else {
              generalLinks.push(e.attributes.link.trim());
            }
          } else if (
            /.(ytimg|jpg|gif|png|jpeg|jxr|webp|apng|tif|tiff|xbm|ico)/.test(
              e.attributes.link
            )
          ) {
            if (imageLinks.indexOf(e.attributes.link) === -1) {
              imageLinks.push(e.attributes.link);
            }
          } else if (
            /.(ytimg|jpg|gif|png|jpeg|jxr|webp|apng|tif|tiff|xbm|ico)/.test(
              e.attributes.alt
            )
          ) {
            if (imageLinks.indexOf(e.attributesealt) === -1) {
              imageLinks.push(e.attributes.alt);
            }
          } else {
            if (
              generalLinks.indexOf(e.attributes.link) === -1 &&
              e.attributes.link !== undefined
            ) {
              generalLinks.push(e.attributes.link.trim());
            }
          }
        }
      });
      //png, jpg, jpeg, jxr, webp, gif, apng, tif, tiff, xbm, ico
    }

    setLinks({
      image: imageLinks,
      video: videoLinks,
      general: [...new Set(generalLinks)],
    });
  }, [delta]);

  return (
    <div className="notes-small-panel-wrapper">
      <SmallPanelBox
        type="image"
        links={links}
        noContentMsg="No Images Found"
      />
      <SmallPanelBox
        type="video"
        links={links}
        noContentMsg="No Videos Found"
      />
      <SmallPanelBox
        type="general"
        links={links}
        noContentMsg="No Links Found"
      />
    </div>
  );
};

export default SmallPanel;
