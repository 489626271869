import { v4 as uuidv4 } from "uuid";

const apexLegends = (data) => {
  let handledData = [];
  let statisticsData = [];

  for (let key in data.apex_data) {
    for (let opener in data.apex_data[key].total) {
      if (data.apex_data[key].total[opener].value !== -1) {
        let val = data.apex_data[key].total[opener].value;
        if (data.apex_data[key].total[opener].name !== null) {
          if (data.apex_data[key].total[opener].name.toLowerCase() === "kd") {
            val = val.toFixed(3);
          }
        }

        statisticsData.push({
          name: data.apex_data[key].total[opener].name,
          value: val,
        });
      }
    }

    handledData.push({
      id: uuidv4(),
      date: key,
      rank: {
        rankDiv: data.apex_data[key].rank.rankDiv,
        rankIcon: data.apex_data[key].rank.rankImg,
        rankName: data.apex_data[key].rank.rankName,
        rankScore: data.apex_data[key].rank.rankScore,
      },
      statistics: [
        {
          name: "Level",
          value: data.apex_data[key].level.levelValue,
          percentToNextLevel: data.apex_data[key].level.toNextLevelPercent,
        },
        ...statisticsData,
      ],
    });
  }

  return handledData;
};

const leagueOfLegends = (data, region, queue) => {
  let handledData = [];
  let statisticsData = [];
  let rRank = {};
  try {
    for (let key in data.league_data) {
      if (data.league_data[key][region].hasOwnProperty(queue)) {
        rRank = {
          rank: {
            rankName: `${data.league_data[key][region][queue].rank.rank_tier} ${data.league_data[key][region][queue].rank.rank_value}`,
          },
        };
        for (let dat in data.league_data[key][region][queue]) {
          if (dat !== "rank") {
            let string = dat
              .toLowerCase()
              .split("_")
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" ");
            let val = data.league_data[key][region][queue][dat];

            if (dat.toLowerCase() === "win_loss_ratio") {
              val = Math.trunc(val * 100);
              string = `${string} (%)`;
            }

            statisticsData.push({
              name: string,
              value: val,
            });
          }
        }
      }

      handledData.push({
        id: uuidv4(),
        date: key,
        ...rRank,
        statistics: [
          {
            name: "Level",
            value: data.league_data[key][region].level,
          },
          ...statisticsData,
        ],
      });
    }
  } catch (err) {
    console.log(err);
  }

  return handledData;
};

const steam = (data, game) => {
  let handledData = [];

  try {
    for (let date in data) {
      let statisticsData = [];
      for (let key in data[date]) {
        if (game.toLowerCase() === "csgo") {
          let nameArray = key.toLowerCase().split("_");
          if (nameArray[0] === "total") {
            nameArray.shift();
            let name = nameArray
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" ");
            statisticsData.push({
              name: name,
              value: data[date][key],
            });
          }
        }

        if (game.toLowerCase() === "tf2") {
          let nameArray = key.toLowerCase().split(/[_.]/);
          let name = nameArray
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(" ");

          statisticsData.push({
            name: name,
            value: data[date][key],
          });
        }
      }
      handledData.push({
        id: uuidv4(),
        date: date,
        statistics: [...statisticsData],
      });
    }
  } catch (err) {
    console.log(err);
  }

  return handledData;
};

//New league of legends data handling

const charts = (data) => {
  let result = [];
  let response = [];
  data.forEach(async (d) => {
    let values = [];
    d.statistics.forEach((s) => {
      if (s.value === -1) {
        return;
      } else {
        values.push({
          [s.name]: s.value,
        });
      }
    });
    result.push({
      date: d.date,
      ...values,
    });
  });

  result.forEach((r) => {
    let destructedData = {};
    for (let key in r) {
      if (key !== "date") {
        destructedData = {
          ...destructedData,
          ...r[key],
        };
      } else {
        destructedData = {
          ...destructedData,
          [key]: r[key],
        };
      }
    }
    response.push(destructedData);
  });
  if (response.length === 1) {
    response = [...response, ...response];
  }

  return response;
};
const reactCharts = async (data) => {
  let result = [];
  let response = [];
  await data.forEach(async (d) => {
    let values = [];
    await d.statistics.forEach((s) => {
      if (s.value === -1) {
        return;
      } else {
        values.push({
          [s.name]: s.value,
        });
      }
    });
    await result.push({
      date: d.date,
      ...values,
    });
  });

  result.forEach((r) => {
    let destructedData = {};
    for (let key in r) {
      if (key !== "date") {
        destructedData = {
          ...destructedData,
          ...r[key],
        };
      } else {
        destructedData = {
          ...destructedData,
          [key]: r[key],
        };
      }
    }
    response.push(destructedData);
  });
  if (response.length === 1) {
    response = [...response, ...response];
  }

  return response;
};

function handleGameData(data, game) {
  if (data !== null && data[0] !== null) {
    switch (game) {
      case "apex":
        return apexLegends(data);
      case "league":
        let leagueIds = JSON.parse(localStorage.getItem("leagueIds"));
        return leagueOfLegends(
          data,
          leagueIds.activeRegion,
          leagueIds.activeQueue
        );
      case "CSGO":
        return steam(data[0].csgo_data, "csgo");
      case "TF2":
        return steam(data[0].tf2_data, "tf2");
      default:
        return [];
    }
  } else {
    return [];
  }
}

export function handleDataFunc(res, profileGame) {
  if (profileGame === "apex") {
    return apexLegends(res);
  }
  if (profileGame === "league") {
    const leagueIds = localStorage.getItem("leagueIds");
    let parsedLeagueIds = JSON.parse(leagueIds);

    if (leagueIds !== null) {
      return leagueOfLegends(
        res,
        parsedLeagueIds.activeRegion,
        parsedLeagueIds.activeQueue
      );
    }
  }
  // Fix get data function

  if (profileGame === "CSGO") {
    return steam(res.csgo_data, profileGame);
  }

  if (profileGame === "TF2") {
    return steam(res.tf2_data, profileGame);
  }
}

export function toDateTimeString(date, type = "both") {
  const d = new Date(date);

  const dateString = d.toDateString().slice(3);
  const timeString = d.toLocaleTimeString("en-US").slice(0, 12);

  if (type === "time") {
    return timeString;
  } else if (type === "date") {
    return dateString;
  } else {
    return `${dateString} ${timeString}`;
  }
}

//eslint-disable-next-line
export default {
  apexLegends: apexLegends,
  leagueOfLegends: leagueOfLegends,
  steam: steam,
  reactCharts: reactCharts,
  charts: charts,
  handleDataFunc: handleDataFunc,
  handleGameData: handleGameData,
  toDateTimeString: toDateTimeString,
};
