import React from "react";
import { useHistory } from "react-router-dom";

const GoalsListPanelItem = ({ title, desc, color }) => {
  let history = useHistory();
  return (
    <div
      className="panel-goals-item-wrapper"
      onClick={() => history.push("/app/goals")}
    >
      <div
        className="panel-goals-item-color"
        style={{ background: color }}
      ></div>
      <div className="panel-goals-item-main">
        <h3>{title}</h3>
        <p>{desc}</p>
      </div>
    </div>
  );
};

export default GoalsListPanelItem;
