import React from "react";
import { Link } from "react-router-dom";
import LogInForm from "./LogInForm";

const LogIn = ({ setIsLoggedIn }) => {
  return (
    <div className="auth-background">
      <div className="auth-wrapper">
        <h3 className="auth-title">Log In</h3>
        <LogInForm setIsLoggedIn={setIsLoggedIn} />
        <div className="auth-account-link-wrapper">
          <p>Don't have an account yet?</p>
          <Link className="auth-account-link" to="/auth/signup">
            Click here
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
