import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="legal-wrapper">
      <div className="legal-inner-wrapper">
        <h1>Privacy Policy</h1>
        <p>Effective date: October 31, 2020</p>
        <br />
        <p>
          Bepity, ("us", "we", or "our") operates the https://www.bepity.com
          website (the "Service").
        </p>
        <br />
        <p>
          This page informs you of our policies regarding the collection, use,
          and disclosure of personal data when you use our Service and the
          choices you have associated with that data.
        </p>
        <br />
        <p>
          We use your data to provide and improve the Service. By using the
          Service, you agree to the collection and use of information in
          accordance with this policy. Unless otherwise defined in this Privacy
          Policy, terms used in this Privacy Policy have the same meanings as in
          our Terms and Conditions, accessible from https://www.bepity.com
        </p>
        <br />
        <h2>Information Collection And Use</h2>
        <br />
        <p>
          We collect several different types of information for various purposes
          to provide and improve our Service to you.
        </p>
        <br />
        <h2>Types of Data Collected</h2>
        <br />
        <h3>Personal Data</h3>
        <br />
        <div className="legal-p">
          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you ("Personal Data"). Personally identifiable information
          may include, but is not limited to:
          <ul>
            <li>Email address</li>
            <li>Cookies and Usage Data</li>
          </ul>
        </div>
        <br />
        <h3>Usage Data</h3>
        <br />
        <p>
          We may also collect information how the Service is accessed and used
          ("Usage Data"). This Usage Data may include information such as your
          computer's Internet Protocol address (e.g. IP address), browser type,
          browser version, the pages of our Service that you visit, the time and
          date of your visit, the time spent on those pages, unique device
          identifiers and other diagnostic data.
        </p>
        <br />
        <h2>Use of Data</h2>
        <br />
        <p>Bepity uses the collected data for various purposes:</p>
        <br />
        <ul>
          <li>To provide and maintain the Service</li>
          <li>To notify you about changes to our Service</li>
          <li>
            To allow you to participate in interactive features of our Service
            when you choose to do so
          </li>
          <li>To provide customer care and support</li>
          <li>
            To provide analysis or valuable information so that we can improve
            the Service
          </li>
          <li>To monitor the usage of the Service</li>
          <li>To detect, prevent and address technical issues</li>
        </ul>
        <br />
        <h2>Transfer Of Data</h2>
        <br />
        <p>
          Your information, including Personal Data, may be transferred to — and
          maintained on — computers located outside of your state, province,
          country or other governmental jurisdiction where the data protection
          laws may differ than those from your jurisdiction.
        </p>
        <br />
        <p>
          Your consent to this Privacy Policy followed by your submission of
          such information represents your agreement to that transfer.
        </p>
        <br />
        <p>
          Bepity will take all steps reasonably necessary to ensure that your
          data is treated securely and in accordance with this Privacy Policy
          and no transfer of your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of your data and other personal information.
        </p>
        <br />
        <h2>Disclosure Of Data</h2>
        <br />
        <h3>Legal Requirements</h3>
        <br />
        <p>
          Bepity may disclose your Personal Data in the good faith belief that
          such action is necessary to:
        </p>
        <br />
        <ul>
          <li>To comply with a legal obligation</li>
          <li>To protect and defend the rights or property of Bepity</li>
          <li>
            To prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            To protect the personal safety of users of the Service or the public
          </li>
          <li>To protect against legal liability</li>
        </ul>
        <br />
        <h2>Security Of Data</h2>
        <br />
        <p>
          The security of your data is important to us, but remember that no
          method of transmission over the Internet, or method of electronic
          storage is 100% secure. While we strive to use commercially acceptable
          means to protect your Personal Data, we cannot guarantee its absolute
          security.
        </p>
        <br />
        <h2>Service Providers</h2>
        <br />
        <p>
          We may employ third party companies and individuals to facilitate our
          Service ("Service Providers"), to provide the Service on our behalf,
          to perform Service-related services or to assist us in analyzing how
          our Service is used.
        </p>
        <br />
        <p>
          These third parties have access to your Personal Data only to perform
          these tasks on our behalf and are obligated not to disclose or use it
          for any other purpose.
          <br />
        </p>
        <br />
        <h2>Analytics</h2>
        <br />
        <p>
          We may use third-party Service Providers to monitor and analyze the
          use of our Service.
        </p>
        <br />
        <h3>Google Analytics</h3>
        <br />
        <p>
          Google Analytics is a web analytics service offered by Google that
          tracks and reports website traffic. Google uses the data collected to
          track and monitor the use of our Service. This data is shared with
          other Google services. Google may use the collected data to
          contextualize and personalize the ads of its own advertising network.
        </p>
        <br />
        <p>
          You can opt-out of having made your activity on the Service available
          to Google Analytics by installing the Google Analytics opt-out browser
          add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
          analytics.js, and dc.js) from sharing information with Google
          Analytics about visits activity.
        </p>
        <br />
        <p>
          For more information on the privacy practices of Google, please visit
          the Google Privacy & Terms web page:
          https://policies.google.com/privacy?hl=en
        </p>
        <br />
        <h2>Links To Other Sites</h2>
        <br />
        <p>
          Our Service may contain links to other sites that are not operated by
          us. If you click on a third party link, you will be directed to that
          third party's site. We strongly advise you to review the Privacy
          Policy of every site you visit.
        </p>
        <br />

        <p>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </p>
        <br />
        <h2>Children's Privacy</h2>

        <p>
          Our Service does not address anyone under the age of 18 ("Children").
        </p>
        <br />
        <p>
          We do not knowingly collect personally identifiable information from
          anyone under the age of 18. If you are a parent or guardian and you
          are aware that your Children has provided us with Personal Data,
          please contact us. If we become aware that we have collected Personal
          Data from children without verification of parental consent, we take
          steps to remove that information from our servers.
        </p>
        <br />
        <h2>Changes To This Privacy Policy</h2>
        <br />
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <br />
        <p>
          We will let you know via email and/or a prominent notice on our
          Service, prior to the change becoming effective and update the
          "effective date" at the top of this Privacy Policy.
        </p>
        <br />
        <p>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
        <br />
        <h2>Contact Us</h2>
        <br />
        <p>
          If you have any questions about this Privacy Policy, please contact
          us:
        </p>
        <p>By email: contact@bepity.com</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
