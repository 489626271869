import React, { useState, useEffect } from "react";
import AdminContent from "./AdminContent/AdminContent.js";
import NoMatch from "../NoMatch.js";
import "../../css/desktop/Admin.css";

const Admin = () => {
  const [firstValue, setFirstValue] = useState("");
  const [secondValue, setSecondValue] = useState("");
  const [thirdValue, setThirdValue] = useState("");
  const [fourthValue, setFourthValue] = useState("");

  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [verifiedSecondLogin, setVerifiedSecondLogin] = useState(null);

  const [isCorrectCookiePass, setIsCorrectCookiePass] = useState(false);

  const handleSubmit = (e, type) => {
    e.preventDefault();

    if (type === "first") {
      fetch(process.env.REACT_APP_DOMAIN + "/admin/getAccess", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
        body: JSON.stringify({
          firstField: firstValue,
          secondField: secondValue,
        }),
      })
        .then((res) => res.json())
        .then((d) => (d.status === 200 ? setIsLoggedIn(true) : null))
        .catch((err) => err);
    }

    if (type === "second") {
      fetch(process.env.REACT_APP_DOMAIN + "/admin/verifySecondLogin", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
        body: JSON.stringify({
          thirdField: thirdValue,
          fourthField: fourthValue,
        }),
      })
        .then((res) => res.json())
        .then((d) => (d.status === 200 ? setVerifiedSecondLogin(true) : null))
        .catch((err) => err);
    }
  };

  useEffect(() => {
    fetch(process.env.REACT_APP_DOMAIN + "/admin/verifyPassword", {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((d) =>
        d.status === 200
          ? setIsCorrectCookiePass(true)
          : setIsCorrectCookiePass(false)
      )
      .catch((err) => console.log(err));
  }, []);

  if (isCorrectCookiePass) {
    if (isLoggedIn) {
      if (verifiedSecondLogin) {
        return <AdminContent />;
      } else {
        return (
          <div className="admin-wrapper">
            <form
              className="admin-login-form-wrapper"
              onSubmit={(e) => handleSubmit(e, "second")}
            >
              <input
                type="text"
                value={thirdValue}
                onChange={(e) => setThirdValue(e.target.value)}
              />
              <input
                type="text"
                value={fourthValue}
                onChange={(e) => setFourthValue(e.target.value)}
              />
              <button>Submit</button>
            </form>
          </div>
        );
      }
    } else {
      return (
        <div className="admin-wrapper">
          <form
            className="admin-login-form-wrapper"
            onSubmit={(e) => handleSubmit(e, "first")}
          >
            <input
              type="text"
              value={firstValue}
              onChange={(e) => setFirstValue(e.target.value)}
            />
            <input
              type="password"
              value={secondValue}
              onChange={(e) => setSecondValue(e.target.value)}
            />
            <button>Submit</button>
          </form>
        </div>
      );
    }
  } else {
    return <NoMatch />;
  }
};

export default Admin;
