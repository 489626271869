import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TempAccountContext } from "../../../context/temp-account-context.js";
import PrimarySectionItems from "./PrimarySectionItems";
import SecondarySectionItems from "./SecondarySectionItems";
import OverviewIcon from "../../../images/Tasks/overview-icon.svg";
import StatisticsIcon from "../../../images/Tasks/statistics-icon.svg";
import TasksIcon from "../../../images/Tasks/tasks-icon.svg";
import NotesIcon from "../../../images/Tasks/notes-icon.svg";
import ExitIcon from "../../../images/cancel.svg";
import LogoWhite from "../../../images/logo-white.svg";

import "../../../css/desktop/Sidebar.css";
import "../../../css/mobile/MobileSidebar.css";

const Sidebar = ({ mobile, showSidebarCallback }) => {
  const { isTempAccount } = useContext(TempAccountContext);
  let history = useHistory();
  let location = useLocation();

  let primaryTitles = ["OVERVIEW", "STATISTICS", "GOALS", "NOTES"];
  let primaryIcons = [OverviewIcon, StatisticsIcon, TasksIcon, NotesIcon];

  const handleClick = (type) => {
    if (type === "signup") {
      if (isTempAccount) {
        history.replace("/auth/signup");
      }
    }
    if (type === "settings") {
      history.push("/settings");
    }
  };

  return (
    <div className="sidebar-wrapper">
      <div className="sidebar-exit" onClick={() => showSidebarCallback(false)}>
        <img src={ExitIcon} alt="exit" />
      </div>
      <div className="sidebar-top" onClick={() => history.push("/home")}>
        <img src={LogoWhite} alt="Logo" />
        <p>Bepity</p>
      </div>

      <div className="sidebar-content-wrapper">
        {mobile ? null : (
          <div
            style={
              location.pathname === "/app/no_profile"
                ? { display: "none" }
                : { display: "block" }
            }
            className="sidebar-section sidebar-primary-section-wrapper"
          >
            <h3 className="sidebar-section-header">sections</h3>
            <PrimarySectionItems
              rawIcons={primaryIcons}
              rawTitles={primaryTitles}
            />
          </div>
        )}

        <div className="sidebar-section sidebar-secondary-section-wrapper">
          <h3 className="sidebar-section-header">profiles</h3>
          <SecondarySectionItems />
        </div>
      </div>
      <div className="sidebar-logout-wrapper">
        {mobile && !isTempAccount ? null : (
          <div onClick={() => handleClick("signup")}>
            {isTempAccount ? "Sign Up" : ""}
          </div>
        )}

        {mobile ? (
          <div
            onClick={() => handleClick("settings")}
            className="sidebar-settings"
          >
            Settings
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Sidebar;
