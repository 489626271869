import React, { createContext, useState } from "react";

export const UpdateProfileContext = createContext();

const UpdateProfileContextProvider = (props) => {
  let [updateProfile, setUpdateProfile] = useState(false);
  return (
    <UpdateProfileContext.Provider
      value={{
        updateProfile,
        setUpdateProfile,
      }}
    >
      {props.children}
    </UpdateProfileContext.Provider>
  );
};

export default UpdateProfileContextProvider;
