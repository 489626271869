import React from "react";
import { Link } from "react-router-dom";

const infoBubbleTexts = {
  whySignUp: (
    <>
      <p>
        Using Bepity without an account may seem intuitive at first, but it
        means that you'll not be able to use Bepity on multiple devices.
      </p>
      <br />
      <p>
        If you're for some reason not satisfied with our product we'll happily
        delete it for you!
      </p>
      <br />
      <p>So what are you waiting for?</p>
      <p>
        <Link to="/auth/signup">Sign Up</Link> today and we can promise you it's
        going to be worth it!
      </p>
    </>
  ),
};

export default infoBubbleTexts;
