const validate = (values) => {
  const errors = {};

  if (values.username.length > 0) {
    if (values.username.length >= 15) {
      errors.username = "Must be 15 characters or less";
    } else if (values.username.length <= 3) {
      errors.username = "Must be 4 characters or more";
    }
  }

  if (values.email.length > 0) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = "Invalid email adress";
    }
  }

  if (values.password.length > 0 && values.confirmedPassword.length > 0) {
    if (values.password.length <= 5) {
      errors.password = "Must be 6 characters or more";
    } else if (values.password.length >= 500) {
      errors.password = "Must be 500 characters or less";
    }

    if (values.confirmedPassword !== values.password) {
      errors.confirmedPassword = "Passwords must match";
    }
  }

  return errors;
};

export default validate;
