import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";

import validate from "./validate.js";
import StyledUserField from "./StyledUserField.js";
import EditButton from "./EditButton.js";
import Loader from "../../reusable/Loader.js";

const AccountSettings = ({ setIsLoggedIn }) => {
  let history = useHistory();

  const externalController = new AbortController();
  const externalSignal = externalController.externalSignal;
  const [data, setData] = useState(null);
  const [submittedData, setSubmittedData] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [editable, setEditable] = useState({
    username: true,
    email: true,
    password: true,
  });
  const [errorMsg, setErrorMsg] = useState("");

  const [initialValues] = useState({
    username: "",
    email: "",
    password: "",
    confirmedPassword: "",
  });

  function fieldCallback(action, name, value = "", resetForm = null) {
    initialValues[name] = value;
    if (name === "password") {
      initialValues.confirmedPassword = value;
    }

    if (action === "exit") {
      setEditable((e) => ({ ...e, [name]: !e[name] }));
      setErrorMsg("");
      resetForm();
    }
  }

  function click(name) {
    setEditable((e) => ({ ...e, [name]: !e[name] }));
  }

  function emptyStrings(strs) {
    for (let key in strs) {
      if (strs[key].length > 0) {
        return true;
      }
    }
    return false;
  }

  const handleLogOut = (data) => {
    if (data.status === 200) {
      setIsLoggedIn(false);
      history.push("/home");
    }
  };

  const handleClick = (type) => {
    if (type === "logoutSingle") {
      fetch(process.env.REACT_APP_DOMAIN + "/auth/logout", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
      })
        .then((res) => res.json())
        .then((data) => handleLogOut(data))
        .catch((e) => e);
    }

    if (type === "logoutAll") {
      fetch(process.env.REACT_APP_DOMAIN + "/auth/logoutAll", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
      })
        .then((res) => res.json())
        .then((data) => handleLogOut(data))
        .catch((e) => e);
    }
  };

  const handleSubmit = (resetForm, signal) => {
    fetch(process.env.REACT_APP_DOMAIN + "/user/updateUserData", {
      signal,
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
      body: JSON.stringify({
        user_name: initialValues.username,
        user_email: initialValues.email,
        user_password: initialValues.password,
      }),
    })
      .then((res) => res.json())
      .then((d) =>
        d.status === 200
          ? setSubmittedData(d.message)
          : setErrorMsg("Error! Try reload the webpage.")
      )
      .then(resetForm())
      .catch((err) => err);
  };

  useEffect(() => {
    let controller = new AbortController();
    let signal = controller.signal;
    fetch(process.env.REACT_APP_DOMAIN + `/user/get`, {
      method: "GET",
      signal,
      headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((d) => setData(d))
      .catch((err) => err);

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (data !== null) {
      setUserName(data.message.user_name);
      setUserEmail(data.message.user_email);
      setIsLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (submittedData !== null) {
      if (submittedData.username.status === 400) {
        setErrorMsg("Username already taken.");
      } else if (submittedData.email.status === 400) {
        setErrorMsg("Email already taken.");
      } else if (submittedData.password.status === 400) {
        setErrorMsg("Error! Try reload the webpage.");
      } else {
        setErrorMsg("");
        for (let key in editable) {
          setEditable((e) => ({ ...e, [key]: true }));
        }
      }

      if (
        submittedData.username.status === 200 &&
        submittedData.username.message.length > 0
      ) {
        setUserName(submittedData.username.message);
        initialValues.username = "";
      }

      if (
        submittedData.email.status === 200 &&
        submittedData.email.message.length > 0
      ) {
        setUserEmail(submittedData.email.message);
        initialValues.email = "";
      }
    }
    // eslint-disable-next-line
  }, [submittedData]);

  return (
    <div className="settings-account-wrapper">
      <Formik
        initialValues={initialValues}
        validate={validate}
        enableReinitialize
        onReset={(values) => {
          values.username = initialValues.username;
          values.email = initialValues.email;
          values.password = initialValues.password;
          values.confirmedPassword = initialValues.confirmedPassword;
        }}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          setTimeout(() => {
            if (emptyStrings(values)) {
              setSubmitting(false);
              handleSubmit(resetForm, externalSignal);
            }
          }, 400);
        }}
      >
        {({ values, errors, resetForm }) =>
          !isLoading ? (
            <Form>
              <div className="settings-main-field-wrapper">
                <h3 className="settings-field-key">Username</h3>
                {editable.username ? (
                  <div className="settings-field-value-wrapper">
                    <p className="settings-field-value">{userName}</p>
                    <EditButton click={click} name="username" />
                  </div>
                ) : (
                  <StyledUserField
                    type="text"
                    name="username"
                    placeholder="Username"
                    value={values.username}
                    errors={errors.username}
                    callback={fieldCallback}
                    resetForm={resetForm}
                  />
                )}
              </div>

              <div className="settings-main-field-wrapper">
                <h3 className="settings-field-key">Email</h3>
                {editable.email ? (
                  <div className="settings-field-value-wrapper">
                    <p className="settings-field-value">{userEmail}</p>
                    <EditButton click={click} name="email" />
                  </div>
                ) : (
                  <StyledUserField
                    type="text"
                    name="email"
                    placeholder="Email"
                    value={values.email}
                    errors={errors.email}
                    callback={fieldCallback}
                    resetForm={resetForm}
                  />
                )}
              </div>

              <div className="settings-main-field-wrapper">
                <h3 className="settings-field-key">Password</h3>
                {editable.password ? (
                  <div className="settings-field-value-wrapper">
                    <p className="settings-field-value">*********</p>
                    <EditButton click={click} name="password" />
                  </div>
                ) : (
                  <StyledUserField
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={values.password}
                    errors={errors.password}
                    callback={fieldCallback}
                    resetForm={resetForm}
                  />
                )}
              </div>

              {!editable.password ? (
                <StyledUserField
                  type="password"
                  name="confirmedPassword"
                  placeholder="Confirm Password"
                  value={values.confirmedPassword}
                  errors={errors.confirmedPassword}
                  callback={fieldCallback}
                  resetForm={resetForm}
                  exit={false}
                />
              ) : null}
              <div className="error">{errorMsg}</div>
              {Object.values(editable).indexOf(false) > -1 ? (
                <button className="settings-save-button" type="submit">
                  Save
                </button>
              ) : null}
            </Form>
          ) : (
            <Loader />
          )
        }
      </Formik>

      <div className="settings-logout-button">
        <button onClick={() => handleClick("logoutSingle")}>Log out</button>
      </div>

      <div className="settings-logout-button">
        <button onClick={() => handleClick("logoutAll")}>
          Log out on all devices
        </button>
        <p style={{ fontSize: "0.6rem" }}>
          It may take up to 5 minutes for all devices to log out.
        </p>
      </div>
    </div>
  );
};

export default AccountSettings;
