import React from "react";
import { useHistory } from "react-router-dom";
import DeleteOrModify from "./DeleteOrModify.js";

const NoGamePanel = ({
  id,
  type,
  placement,
  existingGameName,
  handleDelete,
  handleModify,
}) => {
  const history = useHistory();
  return (
    <div className="panel-no-game-wrapper stats-panel">
      {existingGameName ? (
        <button onClick={() => history.push("/settings?section=profile")}>
          Add a game to profile
        </button>
      ) : (
        <button onClick={() => history.push("/settings?section=game")}>
          Add your first game
        </button>
      )}

      <DeleteOrModify
        id={id}
        type={type}
        placement={placement}
        handleDelete={handleDelete}
        handleModify={handleModify}
      />
    </div>
  );
};

export default NoGamePanel;
