import React, { useState, useEffect } from "react";
import handleData from "../../../customFunctions/handleData.js";

import ProfilePanel from "./ProfilePanel/ProfilePanel.js";
import ChartPanel from "./ChartPanel/ChartPanel.js";
import GoalsListPanel from "./GoalsListPanel/GoalsListPanel.js";
import NotesListPanel from "./NotesListPanel/NotesListPanel.js";
import ErrorPanel from "./ErrorPanel/ErrorPanel.js";

const PanelHub = ({
  id,
  type,
  handleModify,
  handleDelete,
  game,
  data,
  placement = NaN,
}) => {
  const [handledData, setHandledData] = useState(null);
  const [panel, setPanel] = useState(null);

  useEffect(() => {
    let handledData =
      data === null ? null : handleData.handleGameData(data, game);
    if (game !== null && handledData !== null) {
      if (game === "apex") {
        let date = Object.keys(data.apex_data)[
          Object.keys(data.apex_data).length - 1
        ];

        const { banner, icon } = data.apex_data[date].profile;
        const iconImg =
          "https://media.contentapi.ea.com/content/dam/apex-legends/images/2019/03/apex-featured-tile-march-patch-notes.jpg.adapt.crop16x9.431p.jpg";
        const bannerImg =
          "https://media.contentapi.ea.com/content/dam/apex-legends/images/2020/10/apex-featured-image-clubs-discovery-menu.jpg.adapt.crop16x9.431p.jpg";

        setHandledData({
          username: data.apex_data[date].profile.inGameName,
          level: data.apex_data[date].level.levelValue,
          levelProgression: data.apex_data[date].level.toNextLevelPercent,
          banner: banner === "Not available" ? bannerImg : banner,
          icon: icon === "Not available" ? iconImg : icon,
          statistics: handledData,
        });
      }

      if (game === "league") {
        let date = Object.keys(data.league_data)[
          Object.keys(data.league_data).length - 1
        ];
        let leagueSelection = JSON.parse(localStorage.getItem("leagueIds"));
        let iconId = data.league_data[date][leagueSelection.activeRegion].icon;
        setHandledData({
          username: data.league_name,
          level: data.league_data[date][leagueSelection.activeRegion].level,
          levelProgression: null,
          banner:
            "https://ddragon.leagueoflegends.com/cdn/img/champion/splash/Aatrox_3.jpg",
          icon: `https://ddragon.leagueoflegends.com/cdn/10.13.1/img/profileicon/${iconId}.png`,
          statistics: handledData,
        });
      }

      if (game.toLowerCase() === "tf2" || game.toLowerCase() === "csgo") {
        setHandledData({
          username: data[1].steam_display_name,
          level: null,
          levelProgression: null,
          banner:
            game.toLowerCase() === "tf2"
              ? "https://steamcdn-a.akamaihd.net/apps/tf2/artwork/expiration_date_01.jpg"
              : "https://skins.cash/blog/wp-content/uploads/2018/07/CS-GO-wallpaper-HD-art-2.jpg",
          icon: data[1].steam_icon,
          statistics: handledData,
        });
      }
    }
  }, [game, data]);

  useEffect(() => {
    if (type !== null) {
      switch (type) {
        case "profile":
          if (handledData !== null) {
            setPanel(
              <ProfilePanel
                id={id}
                placement={placement}
                handleModify={handleModify}
                handleDelete={handleDelete}
                game={game}
                data={handledData}
              />
            );
          } else {
            setPanel(
              <ErrorPanel
                id={id}
                type="profile"
                placement={placement}
                handleModify={handleModify}
                handleDelete={handleDelete}
                game={game}
                data={handledData}
              />
            );
          }
          break;
        case "chart":
          if (handledData !== null && handledData.statistics.length > 0) {
            setPanel(
              <ChartPanel
                id={id}
                placement={placement}
                handleModify={handleModify}
                handleDelete={handleDelete}
                game={game}
                data={handledData}
              />
            );
          } else {
            setPanel(
              <ErrorPanel
                id={id}
                type="chart"
                placement={placement}
                handleModify={handleModify}
                handleDelete={handleDelete}
                game={game}
                data={handledData}
              />
            );
          }
          break;
        case "goalsList":
          setPanel(
            <GoalsListPanel
              id={id}
              placement={placement}
              handleModify={handleModify}
              handleDelete={handleDelete}
            />
          );
          break;
        case "notesList":
          setPanel(
            <NotesListPanel
              id={id}
              placement={placement}
              handleModify={handleModify}
              handleDelete={handleDelete}
            />
          );
          break;
        default:
          if (handledData !== null) {
            setPanel(
              <ProfilePanel
                id={id}
                placement={placement}
                handleModify={handleModify}
                handleDelete={handleDelete}
                game={game}
                data={handledData}
              />
            );
          }
      }
    }
  }, [handleDelete, game, handleModify, id, placement, handledData, type]);

  return <>{panel}</>;
};

export default PanelHub;
