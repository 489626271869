import {
  postApexName,
  getFromApexName,
  updateGameName,
} from "../settingsModules.js";

const compactUpdateGameName = (value, type, setUser, setValue) => {
  updateGameName(value, type)
    .then((res) => {
      setUser(value);
      setValue("");
    })
    .catch((err) => null);
};

export default async function submit(
  value,
  initValue = "",
  platformValue = "",
  type,
  setError,
  setUser,
  setValue,
  history = ""
) {
  if (type === "apexLegends") {
    await getFromApexName(value).then((res) =>
      res.status === 200
        ? compactUpdateGameName(value, type, setUser, setValue)
        : postApexName(value, platformValue).then((res) =>
            res.status === 200
              ? compactUpdateGameName(res.message, type, setUser, setValue)
              : setError(res.message)
          )
    );
  }
  if (type === "leagueOfLegends") {
    return;
  }
  if (type === "steam" && value !== initValue) {
    fetch(process.env.REACT_APP_DOMAIN + "/steam/addId", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
      body: JSON.stringify({
        steam_id: value,
      }),
    })
      .then((res) => res.json())
      .then((res) =>
        res.status === 200
          ? history.push("?steamLoggedIn=true")
          : setError(res.message)
      );
  }
}
