import React from "react";
import DiscordIcon from "../../../images/discord.png";
import { Link } from "react-router-dom";

const SignUp = () => {
  return (
    <div className="auth-background">
      <div className="auth-wrapper">
        <h3 className="auth-title">Bepity is currently invite only</h3>
        <div className="auth-invite-only-wrapper">
          <p>
            However, there is no need for panic as you only need to join our
            discord server. You will then be able to receive an account. If you
            already have an account:
            <Link className="auth-account-link" to="/auth/login">
              Click here
            </Link>
            .
          </p>
          <a
            className="auth-discord-wrapper"
            href="https://discord.gg/UrTBPvu"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={DiscordIcon} alt="Discord" />
            <p>Join to receive an account!</p>
          </a>
        </div>
        <div className="auth-account-link-wrapper auth-invite-only-link-wrapper"></div>
      </div>
    </div>
  );
};

export default SignUp;
