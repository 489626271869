import React from "react";
import DeleteOrModify from "../DeleteOrModify.js";

const ProfilePanelSettings = ({
  id,
  placement,
  handleDelete,
  handleModify,
}) => {
  return (
    <div className="stats-panel-settings">
      <DeleteOrModify
        id={id}
        type="profile"
        placement={placement}
        handleDelete={handleDelete}
        handleModify={handleModify}
      />
    </div>
  );
};

export default ProfilePanelSettings;
