import React, { useState } from "react";
import UserInformation from "./UserInformation.js";

const SearchUser = () => {
  const [value, setValue] = useState("");
  const [userInformation, setUserInformation] = useState(null);

  const handleClick = () => {
    fetch(process.env.REACT_APP_DOMAIN + `/admin/searchUser/${value}`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((d) => {
        if (d.status === 200) {
          setUserInformation(<UserInformation data={d.message} />);
          setValue("");
        } else setUserInformation("Error");
      })
      .catch((e) => e);
  };
  return (
    <div className="admin-form-wrapper">
      <input
        type="text"
        value={value}
        placeholder="User Id, Name or Email..."
        onChange={(e) => setValue(e.target.value)}
      />
      <button onClick={handleClick}>Search user user</button>
      {userInformation}
    </div>
  );
};

export default SearchUser;
