import React, { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router";
import arrow from "../../images/dropdown_arrow.svg";
import { queryParser } from "../../customFunctions/url.js";
import AccountSettings from "./AccountSettings/AccountSettings.js";
import GameSettings from "./GameSettings.js";
import ProfileSettings from "./ProfileSettings/ProfileSettings.js";
import SettingsSidebar from "./SettingsSidebar.js";
import "../../css/desktop/Settings.css";
import "../../css/mobile/MobileSettings.css";

import { TempAccountContext } from "../../context/temp-account-context.js";

const Settings = ({ setIsLoggedIn }) => {
  let history = useHistory();
  let location = useLocation();
  const { isTempAccount } = useContext(TempAccountContext);

  const [category, setCategory] = useState(isTempAccount ? "game" : "account");

  const getAccessToken = (message) => {
    if (message.status === 200) {
    }
  };

  useEffect(() => {
    fetch(process.env.REACT_APP_DOMAIN + "/auth/getNewToken", {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((token) => getAccessToken(token));

    let interval = setInterval(() => {
      fetch(process.env.REACT_APP_DOMAIN + "/auth/getNewToken", {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
      })
        .then((res) => res.json())
        .then((token) => getAccessToken(token));
    }, 260000);

    if (localStorage.getItem("steamLogIn")) {
      localStorage.removeItem("steamLogIn");
      setCategory("game");
    }

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (queryParser(location.search).section) {
      setCategory(queryParser(location.search).section);
      history.replace("/settings");
    }
  }, [history, location]);

  function setCategoryCallback(value) {
    setCategory(value);
  }

  return (
    <div className="settings-wrapper">
      <div className="settings-prop-header">
        <div></div>
      </div>
      <div className="settings-header">
        <img
          src={arrow}
          className="settings-header-go-back"
          onClick={() => history.push("/app")}
          alt="Go back"
        />
        <h3>settings</h3>
        <div className="settings-header-empty"></div>
      </div>
      <div className="settings-inner-wrapper">
        <SettingsSidebar
          setCategoryCallback={setCategoryCallback}
          isTempAccount={isTempAccount}
        />

        <div className="settings-inner-right">
          {category === "account" && !isTempAccount ? (
            <>
              <h2 className="settings-text-header">Account Details</h2>
              <AccountSettings setIsLoggedIn={setIsLoggedIn} />
            </>
          ) : category === "game" ? (
            <div className="settings-all-games-wrapper">
              <h2 className="settings-text-header">Linked Game Accounts</h2>
              <GameSettings />
            </div>
          ) : category === "profile" ? (
            <>
              <h2 className="settings-text-header">Profiles</h2>
              <ProfileSettings />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Settings;
