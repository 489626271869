import React from "react";
import { useHistory } from "react-router-dom";

const NotesListPanelItem = ({ id, title, lastChange }) => {
  let history = useHistory();
  return (
    <div
      className="panel-notes-item-wrapper"
      onClick={() =>
        history.push({ pathname: "/app/notes", search: `openInEditor=${id}` })
      }
    >
      <h3>{title}</h3>
      <p>Last Change: {lastChange}</p>
    </div>
  );
};

export default NotesListPanelItem;
