import React, { useState } from "react";
import LargePanel from "./LargePanel/LargePanel.js";
import SmallPanel from "./SmallPanel/SmallPanel.js";
import "../../../../css/desktop/Notes.css";
import "../../../../css/mobile/MobileNotes.css";

const Notes = () => {
  const [delta, setDelta] = useState(null);
  const [key, setKey] = useState(null);

  const findInfoFromDeltas = (delta) => {
    setDelta(delta);
    setKey(Date.now());
  };

  return (
    <div className="notes-wrapper">
      <LargePanel
        currentDelta={delta}
        findInfoFromDeltas={findInfoFromDeltas}
      />
      <div className="notes-desktop-small-panel">
        <SmallPanel key={key} delta={delta} />
      </div>
    </div>
  );
};

export default Notes;
