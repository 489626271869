import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  useHistory,
  useLocation,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import UpdateProfileContextProvider from "../context/update-profile-context.js";
import { TempAccountContext } from "../context/temp-account-context.js";
import Home from "./home/Home.js";
import Main from "./main/Main.js";
import Auth from "./auth/Auth";
import Admin from "./admin/Admin.js";
import Settings from "./Settings/Settings.js";
import NoMatch from "./NoMatch";
import SharedNote from "./shared/SharedNote.js";
import Cookie from "./reusable/Cookie.js";
import PrivacyPolicy from "./legal/PrivacyPolicy.js";
import CookiePolicy from "./legal/CookiePolicy.js";
import TOS from "./legal/TOS.js";
import Loader from "./reusable/Loader.js";
import FAQ from "./FAQ.js";
import "../css/global/cookie.css";
import "../css/global/legal.css";
import "../css/global/misc.css";
import "../css/global/variables.css";
import "../css/global/noPage.css";

function App() {
  let history = useHistory();
  let location = useLocation();

  const [intervalStarted, setIntervalStarted] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const { isTempAccount, setIsTempAccount } = useContext(TempAccountContext);
  const [isLoading, setIsLoading] = useState(true);
  const [viewHeight, setViewHeight] = useState(8);
  const [viewWidth, setViewWidth] = useState(8);

  function handleResize() {
    if (window.innerWidth < 970) {
      setTimeout(() => {
        setViewHeight(window.innerHeight * 0.01);
      }, 300);

      setTimeout(() => {
        setViewWidth(window.innerWidth * 0.01);
      }, 300);
    }
  }

  useEffect(() => {
    document.documentElement.style.setProperty("--vh", `${viewHeight}px`);
    document.documentElement.style.setProperty("--vw", `${viewWidth}px`);
    window.addEventListener("resize", handleResize, true);
    return () => window.removeEventListener("resize", handleResize, true);
  }, [viewHeight, viewWidth]);

  useEffect(() => {
    setViewHeight(window.innerHeight * 0.01);
    setViewWidth(window.innerWidth * 0.01);
  }, []);

  const checkAuth = async () => {
    let returnVal;

    await fetch(process.env.REACT_APP_DOMAIN + "/auth/status", {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((d) => (returnVal = d.status === 401 ? false : true))
      .catch((returnVal = false));

    return returnVal;
  };

  const getAccessToken = useCallback(
    (message) => {
      checkAuth().then((res) => setIsLoggedIn(res));
    },
    [setIsLoggedIn]
  );

  useEffect(() => {
    fetch(process.env.REACT_APP_DOMAIN + "/auth/getNewToken", {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((token) => getAccessToken(token))
      .catch((err) => err);
  }, [getAccessToken]);

  useEffect(() => {
    if (isLoggedIn !== null) {
      if (isLoggedIn) {
        setIsLoading(false);

        if (!intervalStarted) {
          setInterval(() => {
            fetch(process.env.REACT_APP_DOMAIN + "/auth/getNewToken", {
              method: "GET",
              headers: {
                "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
                "Access-Control-Allow-Credentials": true,
              },
              credentials: "include",
            }).catch((err) => console.log(err));
          }, 270000);
          setIntervalStarted(true);
        }
      }

      fetch(process.env.REACT_APP_DOMAIN + `/auth/isTempAccount`, {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
          "Access-Control-Allow-Credentials": true,
        },
        credentials: "include",
      })
        .then((res) => res.json())
        .then(async (d) => {
          if (d.status === 200) {
            await setIsTempAccount(d.message);
          } else {
            await setIsTempAccount(false);
          }
        })
        .then(() => {
          let pathname = location.pathname.split("/");

          if (isLoggedIn && !isTempAccount) {
            if (pathname[1] === "app" || pathname[1] === "") {
              history.replace("/app");
            }
          } else if (!isLoggedIn) {
            if (
              (!isTempAccount && pathname[1] === "app") ||
              pathname[1] === "settings" ||
              pathname[1] === ""
            ) {
              history.replace("/home");
            } else if (
              (isTempAccount && pathname[1] === "app") ||
              pathname[1] === ""
            ) {
              history.replace("/home");
            }
          }
          setIsLoading(false);
        })
        .catch((e) => e);
    }

    //eslint-disable-next-line
  }, [isLoggedIn]);

  return (
    <UpdateProfileContextProvider>
      {isLoading ? (
        <div className="main-loader">
          <Loader />
          <h3>Loading...</h3>
        </div>
      ) : (
        <>
          <Cookie />
          <div className="App">
            <Switch>
              <Route path="/" exact>
                <Redirect to="/home" />
              </Route>
              <Route path="/home">
                <Home isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
              </Route>
              <Route path="/app">
                <Main isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
              </Route>
              <Route path="/auth">
                <Auth
                  setIsTempAccount={setIsTempAccount}
                  setIsLoggedIn={setIsLoggedIn}
                />
              </Route>
              <Route path="/settings">
                <Settings setIsLoggedIn={setIsLoggedIn} />
              </Route>
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/cookie-policy" component={CookiePolicy} />
              <Route path="/tos" component={TOS} />
              <Route path="/admin" component={Admin} />
              <Route path="/shared/note" component={SharedNote} />
              <Route path="/FAQ" component={FAQ} />
              <Route component={NoMatch} />
            </Switch>
          </div>
        </>
      )}
    </UpdateProfileContextProvider>
  );
}

export default App;
