// Post profile
// Delete profile

export function queryParser(queryString) {
  let object = {};
  let queryArray = queryString.replace("?", "").split("&");
  queryArray = queryArray.map((query) => query.split("="));
  for (let i = 0; i < queryArray.length; i++) {
    object[queryArray[i][0]] = queryArray[i][1];
  }
  return object;
}

export async function postProfile(profileId, title) {
  fetch(process.env.REACT_APP_DOMAIN + "/profile/post", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
    },
    credentials: "include",
    body: JSON.stringify({
      profile_id: profileId,
      profile_title: title,
    }),
  });
}

export function deleteProfile(profileId) {
  let response = fetch(
    process.env.REACT_APP_DOMAIN + `/profile/delete/${profileId}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
    }
  );

  return response;
}
