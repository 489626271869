import React from "react";
import { Route, Switch } from "react-router-dom";
import LogIn from "./LogIn/LogIn.js";
import SignUp from "./SignUp/SignUp.js";
import ForgotPassword from "./ForgotPassword/ForgotPassword.js";
import NoMatch from "../NoMatch";
import "../../css/desktop/Auth.css";
import "../../css/mobile/MobileAuth.css";

const Auth = ({ setIsLoggedIn }) => {
  return (
    <>
      <Switch>
        <Route path="/auth/login">
          <LogIn setIsLoggedIn={setIsLoggedIn} />
        </Route>
        <Route path="/auth/signup">
          <SignUp setIsLoggedIn={setIsLoggedIn} />
        </Route>
        <Route path="/auth/password">
          <ForgotPassword />
        </Route>
        <Route component={NoMatch} />
      </Switch>
    </>
  );
};

export default Auth;
