import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { queryParser } from "../../customFunctions/url.js";
import SteamButton from "../../images/steamButton.png";

const Steam = ({ handleNameChange }) => {
  let location = useLocation();
  let history = useHistory();

  const [reloadPage, setReloadPage] = useState(true);
  const [authStatus, setAuthStatus] = useState("loggedOut");

  useEffect(() => {
    if (queryParser(location.search).steamLoggedOut) {
      setAuthStatus("loggedOut");
      setTimeout(() => {
        history.push("/settings");
      }, 100);
    } else if (queryParser(location.search).steamLoggedIn) {
      setAuthStatus("loggedIn");
      setTimeout(() => {
        history.push("/settings");
      }, 100);
    }
  }, [history, location]);

  useEffect(() => {
    fetch(process.env.REACT_APP_DOMAIN + `/steam/status`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((d) => setAuthStatus(d.message.auth))
      .catch((e) => null);

    let query = queryParser(location.search);

    if (query["openid.sig"] && query["openid.signed"]) {
      handleNameChange();
      fetch(process.env.REACT_APP_DOMAIN + "/steam/handleLink", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
        body: JSON.stringify({
          parsedQuery: query,
        }),
      }).then(() => {
        history.push("/settings");
        window.location.reload();
        setReloadPage(false);
      });

      setTimeout(() => {
        if (reloadPage) {
          handleNameChange();
          history.push("/settings");
          window.location.reload();
        }
        setReloadPage(false);
      }, 800);
    }

    //eslint-disable-next-line
  }, []);

  const handleClick = (type) => {
    if (type === "logIn") {
      localStorage.setItem("steamLogIn", true);
      fetch(process.env.REACT_APP_DOMAIN + `/steam/getLink`, {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
      })
        .then((res) => res.json())
        .then(
          (d) =>
            d.status === 200
              ? window.location.assign(d.message)
              : null /* Display user already logged in */
        )
        .catch((e) => console.log(e));
    }
    if (type === "logOut") {
      fetch(process.env.REACT_APP_DOMAIN + "/steam/logout", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
      })
        .then((res) => res.json())
        .then((d) => {
          fetch(process.env.REACT_APP_DOMAIN + "/profile/updateGame", {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
            },
            credentials: "include",
            body: JSON.stringify({
              profile_id: localStorage.getItem("profileId"),
              profile_game: "none",
            }),
          })
            .then(() => {
              if (d.status === 200) history.push("?steamLoggedOut=true");
            })
            .catch((err) => err);
        });
    }
  };

  return (
    <div className="settings-game-wrapper">
      <label className="settings-game-label">or</label>
      {authStatus === "loggedIn" ? (
        <div
          className="settings-steam-logout"
          onClick={() => handleClick("logOut")}
        >
          <div className="settings-steam-logout-upper">Sign Out</div>
          <div className="settings-steam-logout-small-txt">
            From both CS:GO and Team Fortress 2.
          </div>
        </div>
      ) : (
        <div className="settings-field-wrapper">
          <button
            className="settings-steam-log-in"
            onClick={() => handleClick("logIn")}
          >
            <img src={SteamButton} alt="Steam LogIn" />
          </button>
        </div>
      )}
    </div>
  );
};

export default Steam;
