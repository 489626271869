import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Logo from "../../images/logo.svg";
import SmallLoader from "../reusable/SmallLoader.js";

const HomeHeader = ({ isLoggedIn, isTempAccount }) => {
  let history = useHistory();
  const [isBackground, setIsBackground] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    setIsLoading(true);
    setTimeout(() => {
      history.push("/app");
      setIsLoading(false);
    }, 900);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 50) setIsBackground(true);
      else setIsBackground(false);
    });
  }, [isBackground]);

  return (
    <div
      className="home-header-wrapper"
      style={
        isBackground
          ? {
              height: "70px",
              paddingInline: "20px",
            }
          : {
              background: "rgba(0, 0, 0, 0)",
            }
      }
    >
      <div className="home-header-left">
        <img src={Logo} alt="Logo" />
        <p>Bepity</p>
      </div>
      <div className="home-header-right">
        {isLoggedIn && !isTempAccount ? (
          <button className="home-header-filled-button" onClick={handleClick}>
            {isLoading ? (
              <SmallLoader reloadMessage={false} color="#fff" />
            ) : (
              "Launch App"
            )}
          </button>
        ) : (
          <>
            <button
              className="home-header-text-button"
              onClick={() => history.push("/auth/login")}
            >
              Log In
            </button>
            <button
              className="home-header-filled-button"
              onClick={() => history.push("/auth/signup")}
            >
              Sign Up
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default HomeHeader;
