import React, { useState, useEffect, useRef } from "react";
import handleData from "../../../../../customFunctions/handleData.js";
import {
  getUsernamesAndGame,
  getData,
} from "../../../../../customFunctions/getData.js";

import Flatpickr from "react-flatpickr";

const Completion = ({
  id,
  dateTime,
  autoCategory,
  autoValue,
  autoBaseValue,
  updateGoalData,
  updateDateTime,
}) => {
  const [date] = useState(dateTime);
  const [showWindow, setShowWindow] = useState(false);

  const [data, setData] = useState(null);
  const [statistics, setStatistics] = useState(null);
  const [options, setOptions] = useState([]);

  const [inputValue, setInputValue] = useState(autoValue);
  const [selectValue, setSelectValue] = useState("none");
  const [progressValue, setProgressValue] = useState(0);

  let profileId = localStorage.getItem("profileId");

  const node1 = useRef();
  const node2 = useRef();

  const handleChange = (e) => {
    let val = e.target.value;
    if (val <= 0 && val !== "") val = 0;
    if (val >= 2147483647 && val !== "") val = 2147483647;
    setInputValue(val);
    updateGoalData(id, "autoValue", val);
  };

  const handleClickOutside = (e) => {
    if (node1.current.contains(e.target) || node2.current.contains(e.target)) {
      return;
    } else {
      setShowWindow(false);
    }
  };

  useEffect(() => {
    let controller = new AbortController();
    let signal = controller.signal;
    getUsernamesAndGame(profileId, signal).then((res) =>
      getData(res[0] !== null ? res[0][res[1]] : null, "last", res[1]).then(
        (d) => {
          setData(handleData.handleGameData(d[0], res[1]));
        }
      )
    );
    return () => controller.abort();
  }, [profileId]);

  useEffect(() => {
    if (data !== null && data[0] !== undefined) {
      data[0].statistics.forEach((d) => {
        if (d.name === autoCategory) setSelectValue(d.name);
        setStatistics((s) => {
          return { ...s, [d.name]: d.value };
        });
      });
      setOptions(
        data[0].statistics.map((d) => {
          return (
            <option key={d.name} value={d.name}>
              {d.name}
            </option>
          );
        })
      );
    }

    //eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (
      autoBaseValue !== null &&
      autoCategory !== "none" &&
      inputValue !== 0 &&
      statistics !== null
    ) {
      setProgressValue(statistics[autoCategory] - autoBaseValue);
    }
  }, [autoBaseValue, statistics, autoCategory, inputValue]);

  useEffect(() => {
    if (showWindow) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showWindow]);

  return (
    <div className="goals-item-completion-wrapper">
      <div className="goals-item-completion-auto">
        <div className="goals-item-completion-title">Autocomplete: </div>
        <div className="goals-item-completion-auto-wrapper">
          <div
            ref={node2}
            className="goals-item-completion-change-date"
            onMouseDown={() => {
              if (!showWindow) setShowWindow(true);
            }}
          >
            Change
          </div>
          <div className="goals-item-completion-display-auto-completion">
            {selectValue === "none" || inputValue === 0 ? (
              <p>Set autocomplete...</p>
            ) : (
              <>
                <div>{selectValue}</div>
                <div>
                  {progressValue} / {inputValue}
                </div>
              </>
            )}
          </div>
        </div>
        <div
          ref={node1}
          className="goals-item-completion-display-auto-window"
          style={
            showWindow
              ? { opacity: "1", height: "300px", width: "300px", top: "48px" }
              : null
          }
        >
          <div>
            <div>Type</div>
            <select
              onChange={(e) => {
                setSelectValue(e.target.value);
                updateGoalData(id, "autoCategory", e.target.value);
                updateGoalData(id, "autoBaseValue", statistics[e.target.value]);
              }}
              value={selectValue}
            >
              <option value="none">None</option>
              {options}
            </select>
          </div>
          <div>
            <div>Amount</div>
            <input
              onChange={handleChange}
              min="0"
              max="2147483647"
              value={inputValue}
              type="number"
            />
          </div>
        </div>
      </div>
      <div className="goals-item-completion-deadline">
        <div className="goals-item-completion-title">Deadline: </div>
        <div className="goals-item-completion-flatpickr-wrapper">
          <div className="goals-item-completion-change-date">Change</div>
          <Flatpickr
            data-enable-time
            placeholder="Select date..."
            value={date}
            onChange={(date) => {
              updateGoalData(id, "dateTime", date);
              updateDateTime(date);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Completion;
