import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, useField } from "formik";
import SmallLoader from "../../reusable/SmallLoader.js";
import { TempAccountContext } from "../../../context/temp-account-context.js";

const MyCheckbox = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: "checkbox" });
  return (
    <div className="auth-input-checkbox-wrapper">
      <label className="auth-input-checkbox">
        <input type="checkbox" {...field} {...props} />
        <p>{children}</p>
      </label>
      {meta.touched && meta.error ? (
        <div className="auth-error">{meta.error}</div>
      ) : null}
    </div>
  );
};

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = "Required";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email.trim())
  ) {
    errors.email = "Invalid email adress";
  }

  if (!values.password) {
    errors.password = "Required";
  }

  return errors;
};

const LogInForm = ({ setIsLoggedIn }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { setIsTempAccount } = useContext(TempAccountContext);
  const history = useHistory();

  const handleSubmit = async (email, password, rememberMe) => {
    setIsLoading(true);
    fetch(process.env.REACT_APP_DOMAIN + "/auth/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
      body: JSON.stringify({
        user_email: email.trim(),
        user_password: password.trim(),
        remember_me: rememberMe,
      }),
    })
      .then((res) => res.json())
      .then((d) => {
        setData(d);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (data !== null) {
      if (data.status === 200) {
        setError("");
        setIsLoggedIn(true);
        setIsTempAccount(false);
        setTimeout(() => {
          history.replace("/app");
        }, 800);
      } else {
        setIsLoading(false);
        setError(data.message);
      }
    }
  }, [setIsLoggedIn, setIsTempAccount, data, history]);

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
          rememberMe: false,
        }}
        validate={validate}
        onSubmit={(values, { setSubmitting }) => {
          setIsLoading(true);
          setTimeout(() => {
            handleSubmit(values.email, values.password, values.rememberMe);
            setSubmitting(false);
          }, 1000);
        }}
      >
        {({ errors, touched, onSubmit }) => (
          <Form className="auth-form-wrapper">
            <div className="auth-custom-error">{error}</div>

            <div className="auth-input-text-wrapper">
              <Field
                className="auth-input-text"
                type="text"
                name="email"
                placeholder="Email"
              />
              <div className="auth-error">
                <ErrorMessage name="email" />
              </div>
            </div>

            <div className="auth-input-text-wrapper">
              <Field
                className="auth-input-text"
                type="password"
                name="password"
                placeholder="Password"
              />
              <div className="auth-error">
                <ErrorMessage name="password" />
              </div>
            </div>

            <div className="auth-below-login-wrapper">
              <MyCheckbox name="rememberMe">Remember Me</MyCheckbox>
              <div className="auth-forgot-password">
                <Link to="/auth/password/forgot">Forgot your password?</Link>
              </div>
            </div>
            <button className="auth-submit-btn" type="submit">
              {isLoading ? <SmallLoader reloadMessage={false} /> : "Log In"}
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default LogInForm;
