import React, { useLayoutEffect } from "react";
import Footer from "./reusable/Footer/Footer.js";
import { useHistory } from "react-router-dom";
import DiscordIcon from "../images/discord.png";

export default function NoMatch() {
  const history = useHistory();

  const getAccessToken = (message) => {
    if (message.status === 200) {
    }
  };

  useLayoutEffect(() => {
    fetch(process.env.REACT_APP_DOMAIN + "/auth/getNewToken", {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((token) => getAccessToken(token))
      .catch((err) => console.log(err));

    let interval = setInterval(() => {
      fetch(process.env.REACT_APP_DOMAIN + "/auth/getNewToken", {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
      })
        .then((res) => res.json())
        .then((token) => getAccessToken(token))
        .catch((err) => console.log(err));
    }, 260000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleClick = () => {
    history.push("/home");
  };
  return (
    <div className="no-page-wrapper">
      <div className="no-page-inner-wrapper">
        <div className="no-page-message-wrapper">
          <div className="no-page-not-found">404</div>
          <div className="no-page-divider"></div>
          <button className="no-page-go-home" onClick={handleClick}>
            Home
          </button>
        </div>
        <div className="no-page-contact-wrapper">
          <h3 className="no-page-need-help">Need help?</h3>
          <div className="no-page-contact-link-wrapper">
            <a
              href="https://www.discord.gg/UrTBPvu"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={DiscordIcon} alt="Discord" />
              <p>Discord</p>
            </a>
            <div className="no-page-divider"> </div>
            <p>contact@bepity.com</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
