import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Loader from "../reusable/Loader.js";
import backgroundVideo from "../../images/video/website_background.mov";

const HomeStart = ({ isTempAccount, isLoggedIn, setIsLoggedIn }) => {
  let history = useHistory();
  const [loading, setIsLoading] = useState(false);

  const handleClick = (isLoggedIn) => {
    setIsLoading(true);
    if (isLoggedIn) {
      setTimeout(() => {
        history.push("/app");
      }, 900);
    } else {
      fetch(process.env.REACT_APP_DOMAIN + "/auth/createNoAccount", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
      })
        .then((res) => res.json())
        .then((d) => {
          setIsLoggedIn(true);
          setTimeout(() => {
            history.push("/app");
          }, 900);
        })
        .catch((e) => e);
    }
  };
  return (
    <div className="home-start-wrapper">
      <div className="home-start-inner-wrapper">
        <div className="home-start-left">
          <div className="home-start-left-big">Bepity</div>
          <div className="home-start-left-small  anim-typewriter">
            Helping gamers rise to the top.
          </div>
        </div>
        <div className="home-start-right">
          <button
            className="home-start-button"
            onClick={() => handleClick(isLoggedIn)}
          >
            {loading ? (
              <Loader reloadMessage={false} color="#fff" />
            ) : isLoggedIn ? (
              <div>Launch App</div>
            ) : (
              <div>Try it now!</div>
            )}
          </button>
          {isLoggedIn ? null : <div>No account required!</div>}
        </div>
        <div
          className="home-start-go-down"
          onClick={() => (window.location.hash = "#overview")}
        ></div>
      </div>
      <video src={backgroundVideo} playsInline muted autoPlay loop />
    </div>
  );
};

// Poster in backgroundVideo

export default HomeStart;
