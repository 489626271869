import React, { useState, useEffect } from "react";

const UserInformation = ({ data }) => {
  const [changelog, setChangelog] = useState([]);

  useEffect(() => {
    setChangelog(
      data.user_changelog.reverse().map((e, i) => {
        return (
          <div key={e + i}>
            <div>{e.message}</div>
            <div className="admin-changelog-date">
              {new Date(e.date).toLocaleString("se-SV")}
            </div>
            {e.value ? (
              <div className="admin-changelog-value">{e.value}</div>
            ) : null}
            {e.values
              ? e.values.map((r, i) => (
                  <div className="admin-changelog-value" key={r + i}>
                    {r}
                  </div>
                ))
              : null}
          </div>
        );
      })
    );
  }, [data]);

  return (
    <div className="admin-user-info-wrapper">
      <div className="admin-basic-user-info-wrapper">
        <div>User id: {data.user_id}</div>
        <div>Name: {data.user_name}</div>
        <div>Email: {data.user_email}</div>
      </div>
      <div className="admin-changelog-wrapper">
        <div className="admin-changelog-header">Changelog</div>
        <div className="admin-changelog-inner-wrapper">{changelog}</div>
      </div>
    </div>
  );
};

export default UserInformation;
