import React, { useState, useEffect, useCallback } from "react";
import ReactPlayer from "react-player";
import arrow from "../../../../../images/arrow.svg";

const SmallPanelBox = ({ type, links, noContentMsg }) => {
  const [items, setItems] = useState([]);
  const [breakpoint, setBreakpoint] = useState(false);
  const [reloadVideo, setReloadVideo] = useState(false);
  const [activeItem, setActiveItem] = useState(0);

  const handleClick = (type, direction = null) => {
    if (type === "arrow") {
      if (direction === "right") {
        setActiveItem((i) => (i < items.length - 1 ? i + 1 : 0));
      }
      if (direction === "left") {
        setActiveItem((i) => (i > 0 ? i - 1 : items.length - 1));
      }
    }

    if (type === "redirect" && direction !== null) {
      let win = window.open(direction, "_blank");
      win.focus();
    }
  };

  const handleEvent = useCallback(() => {
    if (type === "video") {
      if (window.innerWidth <= 1500 && !breakpoint) {
        setBreakpoint(true);
      }

      if (window.innerWidth > 1500 && breakpoint) {
        setBreakpoint(false);
      }
    }
  }, [setBreakpoint, breakpoint, type]);

  useEffect(() => {
    if (links !== null) {
      if (type === "image") {
        setItems(
          links.image.map((e) => {
            return (
              <img className="notes-small-content-image" src={e} alt={e} />
            );
          })
        );
      }

      if (type === "video") {
        setItems(
          links.video.map((e, i) => {
            if (ReactPlayer.canPlay(e)) {
              return (
                <ReactPlayer
                  style={{ position: "absolute" }}
                  url={e}
                  width="100%"
                  height="100%"
                  controls={true}
                />
              );
            } else {
              return null;
            }
          })
        );
      }

      if (type === "general") {
        setItems([]);
        links.general.forEach(async (e) => {
          if (e !== null && e !== undefined) {
            fetch(process.env.REACT_APP_DOMAIN + `/note/get/preview/`, {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
              },
              credentials: "include",
              body: JSON.stringify({ url: e }),
            })
              .then((res) => res.json())
              .then((preview) => {
                if (preview.status === 200) {
                  setItems((i) => [
                    ...i,
                    <div className="notes-small-link-preview-wrapper">
                      <img
                        className="notes-small-content-image"
                        src={preview.message.image}
                        alt={preview.message.title}
                      />
                      <div
                        className="notes-small-link-preview-info"
                        onClick={() =>
                          handleClick("redirect", preview.message.url)
                        }
                      >
                        {preview.message.title}
                      </div>
                    </div>,
                  ]);
                }
              })
              .catch((e) => null);
          }
        });
      }
    }
    //eslint-disable-next-line
  }, [links, reloadVideo, type]);

  useEffect(() => {
    window.addEventListener("resize", handleEvent);

    return () => {
      window.removeEventListener("resize", handleEvent);
    };
  }, [handleEvent, type, breakpoint]);

  useEffect(() => {
    setItems([]);
    let timeout = setTimeout(() => {
      setReloadVideo((r) => !r);
    }, 200);

    return () => clearTimeout(timeout);
  }, [breakpoint]);

  return (
    <>
      <div className="notes-small-box">
        <div
          className="notes-arrow-wrapper notes-arrow-wrapper-left"
          style={items.length <= 1 ? { display: "none" } : { display: "flex" }}
          onClick={() => handleClick("arrow", "left")}
        >
          <img
            className="notes-arrow notes-arrow-left"
            src={arrow}
            alt="left"
          />
        </div>

        <div
          className="notes-arrow-wrapper notes-arrow-wrapper-right"
          style={items.length <= 1 ? { display: "none" } : { display: "flex" }}
          onClick={() => handleClick("arrow", "right")}
        >
          <img
            className="notes-arrow notes-arrow-right"
            src={arrow}
            alt="right"
          />
        </div>

        <div className="notes-box-content">
          <div className="notes-content">
            {items.length > 0 ? (
              items[activeItem]
            ) : (
              <p className="notes-no-content-msg">{noContentMsg}</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SmallPanelBox;
