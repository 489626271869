import React from "react";
import Goals from "../../../images/goals-render.png";
import Notes from "../../../images/notes-render.png";
import Statistics from "../../../images/statistics-render.png";

const HomeContentOverview = () => {
  return (
    <div className="home-overview-wrapper" id="overview">
      <img
        className="home-overview-image"
        style={{ gridArea: "i1", background: "var(--main-color)" }}
        src={Goals}
        alt="React1"
      />
      <div className="home-overview-text" style={{ gridArea: "t1" }}>
        <div className="home-overview-large-text">Forge ambitious goals</div>
        <div className="home-overview-small-text">
          Something all successful gamers have in common are that they all have
          ambitious goals. That's why you can create your own custom goals at
          Bepity. They will even automatically complete!
        </div>
      </div>
      <img
        className="home-overview-image"
        style={{ gridArea: "i2", background: "var(--green-color)" }}
        src={Notes}
        alt="React1"
      />
      <div className="home-overview-text" style={{ gridArea: "t2" }}>
        <div className="home-overview-large-text">Write and share guides</div>
        <div className="home-overview-small-text">
          To reach the top you need to learn from the best. So why not head the
          notes and get the latest and greatest guides from users all around the
          globe. Or why not write your own guides to help those with less
          experience.
        </div>
      </div>

      <img
        className="home-overview-image"
        style={{ gridArea: "i3", background: "var(--red-color)" }}
        src={Statistics}
        alt="React1"
      />
      <div className="home-overview-text" style={{ gridArea: "t3" }}>
        <div className="home-overview-large-text">Track your stats</div>
        <div className="home-overview-small-text">
          You no longer need to open a new tab to track your favorite games. You
          can do it all natively on Bepity. Just add a few of your favorite
          panels and you're all set.
        </div>
      </div>
    </div>
  );
};

export default HomeContentOverview;
