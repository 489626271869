import React, { useState, useEffect } from "react";
import Quill from "quill";
import ImageResize from "quill-image-resize";
import { v4 as uuidv4 } from "uuid";
import save from "../../../../../images/save_button.svg";
import cancel from "../../../../../images/cancel.svg";
import saved from "../../../../../images/checkmark.svg";

Quill.register("modules/imageResize", ImageResize);

const LargePanelEditor = ({
  openInEditor,
  findInfoFromDeltas,
  currentNoteId,
  isEditorActive,
  delta,
  updateProfile,
}) => {
  const [showSaveWindow, setShowSaveWindow] = useState(false);
  const [noteValue, setNoteValue] = useState("");
  const [noteId, setNoteId] = useState(currentNoteId);
  const [isDocSaved, setIsDocSaved] = useState(isEditorActive);
  const [quill, setQuill] = useState(null);

  const handleClick = (stateChange) => {
    setShowSaveWindow(stateChange);
  };

  const handleChange = (e) => {
    setNoteValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let delta = {};
    if (quill !== null) delta = quill.getContents();
    let note_id = uuidv4();
    if (noteValue !== "") {
      fetch(process.env.REACT_APP_DOMAIN + "/note/post", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
        body: JSON.stringify({
          profile_id: localStorage.getItem("profileId"),
          note_id: note_id,
          note_name: noteValue,
          note_delta: delta,
        }),
      })
        .then(() => {
          setIsDocSaved(true);
          openInEditor(delta, noteValue, false, note_id);
          setNoteValue("");
          setShowSaveWindow(false);
          setNoteId(note_id);
        })
        .catch((e) => null);
    }
  };

  useEffect(() => {
    setIsDocSaved(isEditorActive);
  }, [isEditorActive]);

  useEffect(() => {
    let toolbarOptions = [
      [{ font: [] }],
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike"], // toggled buttons
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme

      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ header: "1" }, { header: "2" }],
      ["code-block"],

      ["link", "video", "formula"],
      [{ align: [] }],

      ["clean"], // remove formatting button
    ];

    setQuill(
      new Quill("#notes-editor", {
        theme: "snow",
        bounds: "#notes-editor",
        modules: {
          toolbar: toolbarOptions,
          imageResize: {
            modules: ["Resize", "DisplaySize"],
          },
        },
      })
    );
  }, []);

  useEffect(() => {
    if (quill !== null) {
      quill.setContents({});
      setIsDocSaved(false);
      setNoteId("");
    }
  }, [quill, updateProfile]);

  useEffect(() => {
    if (delta !== null && quill !== null) {
      quill.setContents(delta.ops);
    }
  }, [quill, delta]);

  useEffect(() => {
    document
      .querySelector(".notes-toolbar-wrapper")
      .appendChild(document.querySelector(".ql-snow"));
  }, [quill]);

  const [uploading, setUploading] = useState(false);

  if (quill !== null) {
    quill.on("text-change", function (badDelta, oldDelta, source) {
      if (source === "user") setUploading(true);
    });
  }

  useEffect(() => {
    if (uploading) {
      var timeout = setTimeout(() => {
        let delta = quill.getContents();
        findInfoFromDeltas(delta);

        if (isDocSaved && noteId !== null) {
          fetch(process.env.REACT_APP_DOMAIN + "/note/update", {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
            },
            credentials: "include",
            body: JSON.stringify({
              note_id: noteId,
              note_delta: delta,
            }),
          }).catch((err) => console.log(err));
        }

        setUploading(false);
      }, 3000);
    }

    return () => clearTimeout(timeout);
  }, [findInfoFromDeltas, quill, isDocSaved, uploading, noteId]);

  useEffect(() => {
    setNoteId(currentNoteId);
  }, [currentNoteId]);

  return (
    <div className="notes-editor-wrapper">
      {showSaveWindow ? (
        <div className="notes-editor-save-background">
          <div className="notes-editor-save-window">
            <img
              className="notes-editor-save-cancel"
              src={cancel}
              alt="Close"
              onClick={() => handleClick(false)}
            />
            <form onSubmit={handleSubmit}>
              <input
                className="notes-editor-save-input"
                type="text"
                placeholder="Name"
                onChange={handleChange}
                autoFocus
              />
              <button className="notes-editor-save-submit">Save</button>
            </form>
          </div>
        </div>
      ) : null}

      <div className="notes-toolbar-wrapper"></div>
      <div className="note-editor-external-wrapper">
        <div id="notes-editor"></div>
      </div>

      {!isDocSaved ? (
        <div
          onClick={() => handleClick(true)}
          className="notes-editor-save-btn"
        >
          <img className="notes-editor-save-icon" src={save} alt="save" />
        </div>
      ) : uploading ? (
        <div className="notes-editor-save-btn notes-editor-saving">
          <div className="progress container">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      ) : (
        <div className="notes-editor-save-btn notes-editor-saving">
          <img className="notes-editor-save-icon" src={saved} alt="save" />
        </div>
      )}
    </div>
  );
};

export default LargePanelEditor;
