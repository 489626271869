import React, { useState, useEffect } from "react";
import ExitIcon from "../../images/cancel.svg";

const Cookie = () => {
  const [display, setDisplay] = useState("none");

  useEffect(() => {
    if (localStorage.getItem("ac") !== "1") setDisplay("flex");
  }, []);

  const handleClick = () => {
    localStorage.setItem("ac", "1");
    setDisplay("none");
  };
  return (
    <div className="cookie-full-wrapper" style={{ display: display }}>
      <div className="cookie-wrapper">
        <div className="cookie-inner-wrapper">
          <div className="cookie-text-wrapper">
            <p className="cookie-large-text">
              We use cookies and other tracking technologies to improve your
              browsing experience on our website.
            </p>
            <p className="cookie-small-text">
              By browsing our website, you consent to our use of cookies and
              other tracking technologies.
            </p>
          </div>
          <div className="cookie-right-wrapper">
            <div className="cookie-button-wrapper">
              <button onClick={handleClick}>Agree</button>
            </div>
          </div>
        </div>
        <div className="cookie-exit" onClick={handleClick}>
          <img src={ExitIcon} alt="exit" />
        </div>
      </div>
    </div>
  );
};

export default Cookie;
