import React, { useState, useEffect } from "react";
import Flatpickr from "react-flatpickr";
import Quill from "quill";
import { v4 as uuidv4 } from "uuid";
import { Field, Form, Formik } from "formik";

function validate(values) {
  const errors = {};

  if (!values.title) {
    errors.title = "Required";
  } else if (values.title.length > 25) {
    errors.title = "Must be 15 characters or less";
  }

  if (values.desc.length > 45) {
    errors.desc = "Must be 45 characters or less";
  }

  return errors;
}

const MyQuill = ({ setFieldValue }) => {
  const [quill, setQuill] = useState(null);

  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (quill !== null) {
      quill.on("text-change", function (badDelta, oldDelta, source) {
        if (source === "user") setUploading(true);
      });
    }
  });

  useEffect(() => {
    if (uploading) {
      setTimeout(() => {
        let delta = quill.getContents();
        setFieldValue("text", delta);
        setUploading(false);
      }, 100);
    }

    //eslint-disable-next-line
  }, [uploading]);

  useEffect(() => {
    setQuill(
      new Quill("#add-new-goal-editor", {
        theme: "bubble",
        placeholder: "If the description wasn't enough...",
      })
    );
  }, []);

  return <div id="add-new-goal-editor"></div>;
};

const Default = ({ openAddWindowCallback, addNewGoalCallback }) => {
  const handleTagsData = (tags) => {
    let tagsArr = tags.length > 0 ? tags.split(" ") : [];
    tagsArr = tagsArr.filter((e) => e.length > 0);
    let tagsObj = tagsArr.map((e) => {
      if (e.length > 0) return { id: uuidv4(), title: e };
      else return null;
    });
    return tagsObj;
  };

  const uploadToDatabase = (id, values, tags) => {
    let profileId = localStorage.getItem("profileId");

    fetch(process.env.REACT_APP_DOMAIN + "/goal/post", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
      body: JSON.stringify({
        profile_id: profileId,
        goal_id: id,
        goal_title: values.title,
        goal_desc: values.desc,
        goal_tags: tags,
        goal_deadline: values.dateTime,
        goal_text: values.text,
        goal_created_at: Date.now(),
        goal_checked: false,
        goal_background: values.color,
        goal_auto_category: "none",
        goal_auto_value: 0,
        goal_auto_base_value: null,
      }),
    });
  };

  return (
    <Formik
      initialValues={{
        title: "",
        desc: "",
        dateTime: null,
        tags: "",
        color: "#118ab2",
        text: {},
      }}
      validate={validate}
      onSubmit={(values, { errors, setSubmitting }) => {
        let id = uuidv4();
        uploadToDatabase(id, values, handleTagsData(values.tags));

        let valuesWithTags = { ...values, tags: handleTagsData(values.tags) };
        addNewGoalCallback(id, valuesWithTags);
        openAddWindowCallback(false);
        setSubmitting(false);
      }}
    >
      {({ errors, setFieldValue, values }) => (
        <Form className="goals-add-default-wrapper">
          <Field name="title" placeholder="Title" type="text" />
          <div>{errors.title}</div>
          <Field name="desc" placeholder="Desc" type="text" />
          <div>{errors.desc}</div>
          <Flatpickr
            data-enable-time
            name="dateTime"
            placeholder="Select date..."
            onChange={(date) => setFieldValue("dateTime", date)}
          />
          <Field
            name="tags"
            placeholder="Tags (Separate by spaces)"
            type="text"
          />
          <div className="goals-add-default-color">
            <div>Choose a color:</div>
            <div className="goals-add-default-color-picker">
              <div style={{ background: values.color }}></div>
              <Field name="color" type="color" />
            </div>
          </div>
          <MyQuill setFieldValue={setFieldValue} />
          <button type="submit">Add</button>
        </Form>
      )}
    </Formik>
  );
};

export default Default;
