import React, { useState, useEffect } from "react";
import Basic from "./Basic.js";
import Completion from "./Completion.js";
import Content from "./Content/Content.js";

import CheckmarkIcon from "../../../../../images/checkmark.svg";
import LineIcon from "../../../../../images/line.svg";
import DeleteIcon from "../../../../../images/trash.svg";
import DeadlineIcon from "../../../../../images/deadline.svg";

const GoalsItem = ({
  id,
  title,
  desc,
  dateTime,
  tags,
  color,
  text,
  checked,
  updateGoalData,
  autoCategory,
  autoValue,
  autoBaseValue,
  deleteItem,
}) => {
  const [panelMode, setPanelMode] = useState({
    basic: true,
    completion: false,
    content: false,
  });

  const [isCompleted, setIsCompleted] = useState(checked);

  const [itemColor, setItemColor] = useState(
    color.length > 0 ? color : "#118ab2"
  );

  function colorCallback(color) {
    setItemColor(color);
  }

  const handleClick = (type, mode = null) => {
    if (type === "mode") {
      setPanelMode({
        basic: false,
        completion: false,
        content: false,
        [mode]: true,
      });
    } else if (type === "completed") {
      setIsCompleted((c) => !c);
      updateGoalData(id, "checked", !isCompleted);
    }
  };

  const calcStyle = (panel, type) => {
    if (panelMode[panel]) {
      if (type === "main") return { visibility: "visible" };
      else if (type === "select") return { background: "#eee", border: "none" };
    } else {
      if (type === "main") return { visibility: "hidden", height: "0" };
    }
    return null;
  };

  const [updatedDateTime, setUpdatedDateTime] = useState(dateTime);

  function updateDateTime(value) {
    setUpdatedDateTime(value);
  }

  const [isOverdue, setIsOverdue] = useState(false);

  useEffect(() => {
    if (updatedDateTime !== null) {
      if (Date.now() > Date.parse(updatedDateTime)) {
        setIsOverdue(true);
      } else {
        setIsOverdue(false);
      }
    } else {
      setIsOverdue(false);
    }
  }, [updatedDateTime]);

  return (
    <div
      style={panelMode.content ? { marginBlockEnd: "140px" } : null}
      className={isCompleted ? "goals-item goals-item-completed" : "goals-item"}
    >
      <div style={{ background: itemColor }} className="goals-item-color">
        {isOverdue && !isCompleted ? (
          <div>
            <img src={DeadlineIcon} alt="Deadline" />
          </div>
        ) : null}
      </div>
      <div className="goals-item-main">
        <div
          className="goals-item-mode-wrapper"
          style={calcStyle("basic", "main")}
        >
          {isCompleted ? (
            <div className="goals-item-completed-overlay"></div>
          ) : null}
          <Basic
            id={id}
            title={title}
            desc={desc}
            updateGoalData={updateGoalData}
            isCompleted={isCompleted}
          />
        </div>
        <div
          className="goals-item-mode-wrapper"
          style={calcStyle("completion", "main")}
        >
          {isCompleted ? (
            <div className="goals-item-completed-overlay"></div>
          ) : null}
          <Completion
            id={id}
            autoCategory={autoCategory}
            autoValue={autoValue}
            autoBaseValue={autoBaseValue}
            dateTime={dateTime}
            updateGoalData={updateGoalData}
            updateDateTime={updateDateTime}
          />
        </div>
        <div
          className="goals-item-mode-wrapper"
          style={calcStyle("content", "main")}
        >
          {isCompleted ? (
            <div className="goals-item-completed-overlay"></div>
          ) : null}
          <Content
            id={id}
            isShowing={panelMode.content}
            callback={colorCallback}
            updateGoalData={updateGoalData}
            tags={tags}
            color={color}
            text={text}
          />
        </div>

        <div className="goals-item-select-wrapper">
          <div
            onClick={() => handleClick("mode", "basic")}
            style={calcStyle("basic", "select")}
          ></div>
          <div
            onClick={() => handleClick("mode", "completion")}
            style={calcStyle("completion", "select")}
          ></div>
          <div
            onClick={() => handleClick("mode", "content")}
            style={calcStyle("content", "select")}
          ></div>
        </div>
      </div>
      <div className="goals-item-buttons-wrapper">
        <div onClick={() => handleClick("completed")}>
          {isCompleted ? (
            <img src={LineIcon} alt="Undo" />
          ) : (
            <img src={CheckmarkIcon} alt="Complete" />
          )}
        </div>
        <div onClick={() => deleteItem(id)}>
          <img src={DeleteIcon} alt="Finish" />
        </div>
      </div>
    </div>
  );
};

export default GoalsItem;
