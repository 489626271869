import React from "react";
import TrashIcon from "../../../images/trash.svg";
import ModifyIcon from "../../../images/edit.svg";

const DeleteOrModify = ({
  id,
  placement = NaN,
  type,
  chartType = "line",
  handleDelete,
  handleModify,
}) => {
  return (
    <>
      {handleModify === null ? (
        <div
          onClick={() => handleDelete(id, type)}
          className="stats-panel-settings-delete"
        >
          <img
            className="stats-panel-settings-delete-icon"
            src={TrashIcon}
            alt="Delete"
          />
        </div>
      ) : (
        <div
          onClick={() => handleModify(id, placement, type, chartType)}
          className="stats-panel-settings-modify"
        >
          <img
            className="stats-panel-settings-modify-icon"
            src={ModifyIcon}
            alt="Modify"
          />
        </div>
      )}
    </>
  );
};

export default DeleteOrModify;
