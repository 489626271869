import React, { useState, useEffect, useRef } from "react";
import Chart from "chart.js";
import handleData from "../../../../../customFunctions/handleData.js";
import {
  borderColors,
  backgroundColors,
  pieDoughnutOptions,
} from "../ChartPanelVariables.js";

// Color settings?

const ChartPanelPolar = ({ data, selected, placement }) => {
  const [myChart, setMyChart] = useState(null);
  const [datasets, setDatasets] = useState([]);
  const [handledData, setHandledData] = useState(
    handleData.charts(data.statistics)
  );
  const ctx = useRef(null);
  const [chartData, setChartData] = useState({
    labels: selected,
    datasets: datasets,
  });

  useEffect(() => {
    setHandledData(handleData.charts(data.statistics));
  }, [data]);

  useEffect(() => {
    if (placement || placement === 0) {
      function resizeHandler() {
        if (myChart.ctx !== null) {
          myChart.resize();

          myChart.destroy();
          setMyChart(
            new Chart(ctx.current.getContext("2d"), {
              type: "pie",
              data: chartData,
              options: pieDoughnutOptions,
            })
          );
        }
      }

      window.addEventListener("resize", resizeHandler, true);

      return () => {
        window.removeEventListener("resize", resizeHandler, true);
      };
    }
  }, [placement, chartData, myChart]);

  // myChart

  useEffect(() => {
    if (myChart !== null) myChart.destroy();
    setMyChart(
      new Chart(ctx.current.getContext("2d"), {
        type: "pie",
        data: chartData,
        options: pieDoughnutOptions,
      })
    );

    //eslint-disable-next-line
  }, [chartData]);

  useEffect(() => {
    let data = [];
    let i = 0;

    for (let e of selected) {
      if (i === 4) return;
      if (e.toLowerCase() !== "none") {
        data.push(handledData[handledData.length - 1][e]);
      }
      i += 1;
    }

    setDatasets([
      {
        borderColor: borderColors,
        backgroundColor: backgroundColors,
        data: [...data],
      },
    ]);
  }, [selected, handledData]);

  useEffect(() => {
    let filteredSelected = selected.filter((s) => s.toLowerCase() !== "none");
    setChartData((ld) => {
      return { labels: filteredSelected, datasets: datasets };
    });
  }, [datasets, selected]);

  return (
    <div className="stats-panel-chart-line canvas-container">
      <canvas ref={ctx} style={{ width: "100%" }}></canvas>
    </div>
  );
};

export default ChartPanelPolar;
