import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../../reusable/Loader.js";
import SmallLoader from "../../reusable/SmallLoader.js";

const queryParser = (queryString) => {
  let object = {};
  let queryArray = queryString.replace("?", "").split("&");
  queryArray = queryArray.map((query) => query.split("="));
  for (let i = 0; i < queryArray.length; i++) {
    object[queryArray[i][0]] = queryArray[i][1];
  }
  return object;
};

const NewPassword = () => {
  let location = useLocation();
  let history = useHistory();
  let queryString = location.search;

  const [isValidToken, setIsValidToken] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmation, setConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");

  useEffect(() => {
    fetch(
      process.env.REACT_APP_DOMAIN +
        `/resetPassword/getTokenStatus/${queryParser(queryString).token}`,
      {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((d) => {
        if (d.status === 200) {
          setIsValidToken(true);
        } else {
          setIsValidToken(false);
          setErrorMessage(d.message);
        }
      });
  }, [queryString]);

  const handleClick = () => {
    setConfirmation(true);
    if (password === confirmedPassword) {
      setIsLoading(true);
      fetch(process.env.REACT_APP_DOMAIN + "/resetPassword/changePassword", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
        body: JSON.stringify({
          token: queryParser(queryString).token,
          email: queryParser(queryString).email,
          password: password,
        }),
      }).then(() => history.replace("/auth/login"));
    }
  };

  return (
    <div className="auth-background">
      <div className="auth-wrapper auth-new-password-wrapper">
        {isValidToken === null ? (
          <Loader />
        ) : isValidToken ? (
          <>
            <div className="flex-row">
              <h1>Enter a new password</h1>
            </div>
            <div className="auth-input-wrapper">
              <input
                type="password"
                placeholder="Password..."
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                minLength="6"
                maxLength="500"
                required
              />
              <input
                type="password"
                placeholder="Confirm password..."
                value={confirmedPassword}
                onChange={(e) => setConfirmedPassword(e.target.value)}
                minLength="6"
                maxLength="500"
                required
              />

              {confirmation && confirmedPassword !== password ? (
                <div className="auth-error">Passwords must match</div>
              ) : null}
            </div>
            <button className="auth-submit-btn" onClick={handleClick}>
              {isLoading ? <SmallLoader reloadMessage={false} /> : "Confirm"}
            </button>
          </>
        ) : (
          <h3>{errorMessage}</h3>
        )}
      </div>
    </div>
  );
};

export default NewPassword;
