import React, { useContext } from "react";
import { TempAccountContext } from "../../context/temp-account-context.js";
import "../../css/desktop/Home.css";
import "../../css/mobile/MobileHome.css";
import HomeHeader from "./HomeHeader.js";
import HomeStart from "./HomeStart.js";
import HomeContent from "./HomeContent/HomeContent.js";
import Footer from "./../reusable/Footer/Footer";

const Home = ({ isLoggedIn, setIsLoggedIn }) => {
  const { isTempAccount } = useContext(TempAccountContext);

  return (
    <div className="home-body">
      <HomeHeader isLoggedIn={isLoggedIn} isTempAccount={isTempAccount} />
      <HomeStart
        isTempAccount={isTempAccount}
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
      />
      <div className="home-bottom-wrapper">
        <HomeContent isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
        <div className="home-bottom-padding"></div>
        <Footer />
      </div>
    </div>
  );
};

export default Home;
