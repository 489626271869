import React, { useState, useEffect, useContext } from "react";
import handleData from "../../../../customFunctions/handleData.js";
import DeleteOrModify from "../DeleteOrModify.js";
import { UpdateProfileContext } from "../../../../context/update-profile-context.js";
//
// Color settings?

const ChartPanelSettings = ({
  id,
  placement = NaN,
  handleDelete,
  handleModify,
  data,
  setValuesCallback,
  setTypeCallback,
}) => {
  const [handledData] = useState(handleData.charts(data.statistics));
  const [dataKeys] = useState(Object.keys(handledData[handledData.length - 1]));
  const [options, setOptions] = useState([]);
  const [values, setValues] = useState(["none", "none", "none", "none"]);
  const [change, setChange] = useState(false);
  const [chartType, setChartType] = useState("line");
  const { updateProfile } = useContext(UpdateProfileContext);

  const handleChange = (e, index) => {
    let selectedValue = e.currentTarget.value;
    values[index] = selectedValue;
    setValues((v) => [...v]);
    setChange(true);
  };

  useEffect(() => {
    let controller = new AbortController();
    let signal = controller.signal;
    const nullSelect = Object.keys(handledData[0]);

    fetch(process.env.REACT_APP_DOMAIN + `/panel/getChart/${id}`, {
      signal,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((d) => {
        if (d.status === 200) {
          if (d.message.graph_type) {
            setTypeCallback(d.message.graph_type);
          } else {
            setTypeCallback("");
          }

          setChartType(d.message.graph_type);
          setValues(
            d.message.graph_data.map((e, i) => (e === null ? nullSelect[i] : e))
          );
        } else {
          setTypeCallback(null);
        }
      })
      .then(() => setChange(true))
      .catch((e) => e);
    return () => controller.abort();

    //eslint-disable-next-line
  }, [handledData, id, updateProfile]);

  useEffect(() => {
    let controller = new AbortController();
    let signal = controller.signal;
    if (change) {
      fetch(process.env.REACT_APP_DOMAIN + `/panel/updateChart`, {
        signal,
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
        body: JSON.stringify({
          chart_id: id,
          chart_data: values,
        }),
      })
        .then((res) => res.json())
        .then((d) => (d.status === 200 ? setValuesCallback(values) : null))
        .then(() => setChange(false))
        .catch((e) => e);
    }

    return () => controller.abort();
  }, [change, id, values, setValuesCallback]);

  useEffect(() => {
    setOptions(
      dataKeys.map((e) => {
        if (e !== "date") {
          return (
            <option key={e} value={e}>
              {e}
            </option>
          );
        } else {
          return null;
        }
      })
    );
  }, [dataKeys]);

  return (
    <div className="stats-panel-settings">
      <select onChange={(e) => handleChange(e, 0)} value={values[0]}>
        <option value="none">None</option>
        {options}
      </select>

      <select onChange={(e) => handleChange(e, 1)} value={values[1]}>
        <option value="none">None</option>
        {options}
      </select>

      <select onChange={(e) => handleChange(e, 2)} value={values[2]}>
        <option value="none">None</option>
        {options}
      </select>

      <select onChange={(e) => handleChange(e, 3)} value={values[3]}>
        <option value="none">None</option>
        {options}
      </select>

      <DeleteOrModify
        id={id}
        type="chart"
        chartType={chartType}
        placement={placement}
        handleDelete={handleDelete}
        handleModify={handleModify}
      />
    </div>
  );
};

export default ChartPanelSettings;
