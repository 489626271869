import React, { useEffect, useState, useContext, useCallback } from "react";
import { UpdateProfileContext } from "../../../../../context/update-profile-context.js";
import Tabs from "./Tabs.js";
import LargePanelNotes from "./LargePanelNotes.js";
import LargePanelEditor from "./LargePanelEditor.js";
import LargePanelOverview from "./LargePanelOverview.js";

const LargePanel = ({ currentDelta, findInfoFromDeltas }) => {
  const { updateProfile } = useContext(UpdateProfileContext);
  const [currentTab, setCurrentTab] = useState("notes");
  const [noteId, setNoteId] = useState(null);
  const [delta, setDelta] = useState(null);
  const [tabNames, setTabNames] = useState(["Notes", "Editor"]);
  const [tabIds, setTabIds] = useState(["notes", "editor"]);
  const [isOverviewTabActive, setIsOverviewTabActive] = useState(false);
  const [isEditorActive, setIsEditorActive] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  const resetEditorTabName = useCallback(
    (id = null) => {
      if (noteId === id || id === null) {
        setTabNames((t) =>
          tabNames.length === 2 ? [t[0], "Editor"] : [t[0], "Editor", t[2]]
        );
      }
    },
    [noteId, setTabNames, tabNames.length]
  );

  async function openInEditor(itemDelta, itemName, open = true, id = null) {
    await setDelta(itemDelta);
    setNoteId(id);
    setActiveTab(1);

    if (open) setCurrentTab("editor");

    if (itemName === null) {
      setTabNames((t) => [...t]);
      setIsEditorActive(false);
    } else {
      setTabNames((t) =>
        window.innerWidth >= 1500
          ? [t[0], `Editor - ${itemName}`]
          : [t[0], `Editor - ${itemName}`, t[2]]
      );
      setIsEditorActive(true);
    }
  }

  function handleClick(tabName) {
    setCurrentTab(tabName);
  }

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 1500 && !isOverviewTabActive) {
        setIsOverviewTabActive(true);
      } else if (window.innerWidth >= 1500 && isOverviewTabActive) {
        setIsOverviewTabActive(false);
      }
    });

    if (window.innerWidth < 1500 && !isOverviewTabActive) {
      setIsOverviewTabActive(true);
    } else if (window.innerWidth >= 1500 && isOverviewTabActive) {
      setIsOverviewTabActive(false);
    }
  }, [isOverviewTabActive]);

  useEffect(() => {
    setNoteId(null);
    setDelta(null);
    resetEditorTabName();

    //eslint-disable-next-line
  }, [updateProfile]);

  useEffect(() => {
    if (isOverviewTabActive) {
      setTabNames((t) => [...t, "Overview"]);
      setTabIds((t) => [...t, "overview"]);
      setActiveTab((t) => (t !== 1 ? 0 : t));
    }

    if (!isOverviewTabActive) {
      setTabNames((t) => [t[0], t[1]]);
      setTabIds((t) => [t[0], t[1]]);
      setCurrentTab("notes");
      setActiveTab(0);
    }
  }, [isOverviewTabActive]);

  return (
    <div className="notes-large-panel-wrapper">
      <Tabs
        ids={tabIds}
        names={tabNames}
        handleClick={handleClick}
        activeTab={activeTab}
        setActiveTab={(e) => setActiveTab(e)}
      />

      {currentTab === "notes" ? (
        <div className="notes-panel-wrapper">
          <LargePanelNotes
            findInfoFromDeltas={findInfoFromDeltas}
            openInEditor={openInEditor}
            resetEditorTabName={resetEditorTabName}
            updateProfile={updateProfile}
          />
        </div>
      ) : null}
      <div
        className="notes-panel-wrapper"
        style={
          currentTab === "editor" ? { display: "flex" } : { display: "none" }
        }
      >
        <LargePanelEditor
          openInEditor={openInEditor}
          findInfoFromDeltas={findInfoFromDeltas}
          isEditorActive={isEditorActive}
          currentNoteId={noteId}
          updateProfile={updateProfile}
          delta={delta}
        />
      </div>
      <div
        className="notes-panel-wrapper"
        style={
          currentTab === "overview" ? { display: "flex" } : { display: "none" }
        }
      >
        <LargePanelOverview delta={currentDelta} />
      </div>
    </div>
  );
};

export default LargePanel;
