import React, { useState, useContext } from "react";
import { TempAccountContext } from "../../../../../context/temp-account-context.js";
import ShareIcon from "../../../../../images/share.svg";
import DeleteIcon from "../../../../../images/cancel.svg";
import CopyLinkIcon from "../../../../../images/copy-link.svg";
import LockIcon from "../../../../../images/lock.svg";
import ShareSettingsIcon from "../../../../../images/share-settings.svg";
import AnonymousIcon from "../../../../../images/anonymous.svg";
import DisableAnonymousIcon from "../../../../../images/disable-anonymous.svg";
import DoneIcon from "../../../../../images/checkmark.svg";

const ListItem = ({
  title,
  id,
  lastAccessed,
  handleClick,
  handleMouseEnter,
  handleMouseLeave,
  lastDelete,
  deleteItem,
  defaultIsPublic,
}) => {
  const { isTempAccount } = useContext(TempAccountContext);

  const [noteTitle, setNoteTitle] = useState(title);

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [titleValue, setTitleValue] = useState(title);

  const [linkBackground, setLinkBackground] = useState("none");

  const [isPublic, setIsPublic] = useState(defaultIsPublic);
  const [showShareMenu, setShowShareMenu] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(false);

  const handleInternalClick = (id, type) => {
    if (type === "link") {
      setLinkBackground("var(--main-color)");
      fetch(process.env.REACT_APP_DOMAIN + `/note/getPublicId/${id}`, {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
      })
        .then((res) => res.json())
        .then((d) => {
          if (d.status === 200) {
            if (!navigator.clipboard) {
              window.open(`https://bepity.com/shared/note?id=${d.message}`);
              window.focus();
            } else {
              navigator.clipboard.writeText(
                `https://bepity.com/shared/note?id=${d.message}`
              );
              setTimeout(() => {
                setLinkBackground("none");
              }, 160);
            }
          }
        })
        .catch((e) => e);
    }

    if (type === "anonymous") {
      fetch(process.env.REACT_APP_DOMAIN + "/note/toggleAnonymous", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
        body: JSON.stringify({
          private_id: id,
          new_state: !isAnonymous,
        }),
      })
        .then((res) => res.json())
        .then((d) => setIsAnonymous(d.message))
        .catch((e) => e);
    }

    if (type === "title") {
      if (isEditingTitle && titleValue.length > 0) {
        fetch(process.env.REACT_APP_DOMAIN + "/note/updateTitle", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
          },
          credentials: "include",
          body: JSON.stringify({
            private_id: id,
            title: titleValue,
          }),
        })
          .then((res) => res.json())
          .then((d) => {
            setIsEditingTitle(false);
            setNoteTitle(d.message);
          })
          .catch((e) => e);
      } else {
        setIsEditingTitle(true);
      }
    }
  };

  const handleChange = (e, type) => {
    setTitleValue(e.target.value);
  };

  const handleDelete = (id) => {
    fetch(process.env.REACT_APP_DOMAIN + `/note/delete/${id}`, {
      method: "DELETE",
      headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((d) => {
        if (d.status === 200) {
          deleteItem(id);
          handleMouseLeave();
          lastDelete();
        }
      })
      .catch((err) => err);
  };

  const handleShare = (id) => {
    if (isPublic) {
      fetch(process.env.REACT_APP_DOMAIN + "/note/makePrivate", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
        body: JSON.stringify({
          private_id: id,
        }),
      })
        .then((res) => res.json())
        .then((d) => {
          if (d.status === 200) {
            setIsPublic(false);
            setShowShareMenu(false);
          }
        });
    } else {
      fetch(process.env.REACT_APP_DOMAIN + "/note/makePublic", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
        body: JSON.stringify({
          private_id: id,
        }),
      })
        .then((res) => res.json())
        .then((d) => {
          if (d.status === 200) {
            window.open(`https://bepity.com/shared/note?id=${d.message}`);
            window.focus();
            setIsPublic(true);
          }
        });
    }
  };

  return (
    <div
      className="notes-list-item"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="notes-list-item-content">
        {isEditingTitle ? (
          <div className="notes-list-item-edit-title goals-item-basic-is-editing">
            <input
              onChange={(e) => handleChange(e)}
              value={titleValue}
              type="text"
              placeholder="Can't be empty"
            />
            <div
              className="goals-item-basic-is-editing-img"
              onClick={() => handleInternalClick(id, "title")}
            >
              <img src={DoneIcon} alt="Done" />
            </div>
          </div>
        ) : (
          <h3 onClick={() => handleInternalClick(id, "title")}>{noteTitle}</h3>
        )}
        <p>Last edit: {lastAccessed}</p>
        <div className="notes-list-item-hitbox" onClick={handleClick}></div>
      </div>

      <div className="notes-list-item-right-wrapper">
        {showShareMenu && isPublic ? (
          <div className="notes-list-item-share-menu">
            <div
              className="notes-list-item-share-menu-link"
              onClick={() => handleInternalClick(id, "link")}
              style={{ background: linkBackground }}
            >
              <img src={CopyLinkIcon} alt="Copy link" />
            </div>
            <div
              id="notes-list-item-anonymous-icon"
              onClick={() => handleInternalClick(id, "anonymous")}
              style={
                isTempAccount
                  ? {
                      background: "#444",
                      borderRadius: "5px",
                      padding: "5px",
                      height: "36px",
                      width: "36px",
                      cursor: "not-allowed",
                    }
                  : null
              }
            >
              {isAnonymous && !isTempAccount ? (
                <img src={DisableAnonymousIcon} alt="Disable anonymous mode " />
              ) : (
                <img src={AnonymousIcon} alt="Enable anonymous mode" />
              )}
            </div>
            <div onClick={() => handleShare(id)}>
              <img src={LockIcon} alt="Make private" />
            </div>
          </div>
        ) : null}
        <div
          className="notes-list-item-right-item"
          onClick={() =>
            !isPublic ? handleShare(id) : setShowShareMenu((m) => !m)
          }
        >
          {isPublic ? (
            <div className="notes-list-item-share-settings">
              <img src={ShareSettingsIcon} alt="share settings" />
            </div>
          ) : (
            <img src={ShareIcon} alt="Share" />
          )}
        </div>
        <div
          className="notes-list-item-right-item"
          onClick={() => handleDelete(id)}
        >
          <img
            className="notes-list-item-delete-icon"
            src={DeleteIcon}
            alt="Delete"
          />
        </div>
      </div>
    </div>
  );
};

export default ListItem;
