import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import SmallLoader from "../../reusable/SmallLoader.js";

const PasswordEmailInput = () => {
  const [emailValue, setEmailValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  let history = useHistory();

  const handleChange = (event) => {
    setEmailValue(event.target.value);
  };

  const handleSubmit = (event) => {
    if (!emailValue) {
      setErrorMessage("Required");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailValue)) {
      setErrorMessage("Invalid email adress");
    } else {
      setIsLoading(true);
      fetch(process.env.REACT_APP_DOMAIN + "/resetPassword/sendEmailToken", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
        body: JSON.stringify({
          email: emailValue,
        }),
      });
      setTimeout(() => {
        history.replace(`/auth/password/confirmation?email=${emailValue}`);
      }, 1500);
    }
    event.preventDefault();
  };

  return (
    <div className="auth-background">
      <div className="auth-wrapper">
        <h3 className="auth-title">Forgot your password?</h3>
        <p className="auth-text-box">
          Type your email in the input box below and we will send you an email
          with further instructions.
        </p>

        <form className="auth-form-wrapper" onSubmit={handleSubmit}>
          <input
            className="auth-input-text"
            type="text"
            name="email"
            placeholder="Email"
            onChange={handleChange}
            value={emailValue}
          />
          <p className="auth-error auth-relative">{errorMessage}</p>
          <button className="auth-submit-btn" name="submit" type="submit">
            {isLoading ? <SmallLoader /> : "Send"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default PasswordEmailInput;
