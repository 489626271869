import React, { useState } from "react";
import InfoBubbleTexts from "./InfoBubbleTexts.js";

const InfoBubbleSection = ({ title, type }) => {
  const [activeText, setActiveText] = useState(null);

  const handleClick = (text) => {
    if (text === null) {
      setActiveText(null);
    } else {
      setActiveText(InfoBubbleTexts[text]);
    }
  };

  return (
    <>
      {activeText === null ? (
        <div className="main-info-item" onClick={() => handleClick(type)}>
          <div>{title}</div>
        </div>
      ) : (
        <div
          className="main-info-text-wrapper"
          onClick={() => handleClick(null)}
        >
          <div className="main-info-text">{activeText}</div>
          <p className="main-info-bottom-close">Click to close</p>
        </div>
      )}
    </>
  );
};

export default InfoBubbleSection;
