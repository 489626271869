import React, { useState, useEffect } from "react";

const PanelCategoryItem = ({
  id,
  selected,
  setSelected,
  children,
  title,
  desc,
  image = null,
}) => {
  const [menuIsVisible, setMenuIsVisible] = useState(false);

  useEffect(() => {
    if (selected !== id) setMenuIsVisible(false);
  }, [id, selected]);

  function handleClick() {
    setSelected(id);
    setMenuIsVisible(true);
  }

  return (
    <div
      style={
        selected === -1 || selected === id
          ? { height: "200px" }
          : { display: "none", width: "100%" }
      }
    >
      {children.length > 1 ? (
        <>
          <div
            className="panel-item"
            style={menuIsVisible ? { display: "none" } : null}
            onClick={handleClick}
          >
            <div>
              {image !== null ? (
                <img className="panel-item-image" src={image} alt={title} />
              ) : null}
              <div className="panel-item-title">{title}</div>
            </div>

            <div className="panel-item-hover">
              <div className="panel-item-desc">{desc}</div>
            </div>
          </div>

          <div className="panel-popup-item-wrapper">
            {menuIsVisible ? children : null}
          </div>
        </>
      ) : (
        children
      )}
    </div>
  );
};

export default PanelCategoryItem;
