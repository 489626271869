import React, { useState } from "react";
import { FooterLink } from "./FooterLink";
import "../../../css/global/Footer.css";

const Footer = () => {
  const [value, setValue] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.trim())) {
      fetch(process.env.REACT_APP_DOMAIN + "/newsletter/subscribe", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
        body: JSON.stringify({
          email: value.trim(),
        }),
      }).then(() => setValue(""));
    }
  };
  return (
    <div className="footer-wrapper">
      <div className="footer-top">
        <div className="footer-contact">
          <h3>Contact</h3>
          <a
            href="https://www.discord.gg/UrTBPvu"
            target="_blank"
            rel="noopener noreferrer"
          >
            Discord
          </a>
          <p>contact@bepity.com</p>
        </div>
        <div className="footer-about">
          <h3>About</h3>
          <FooterLink href="/faq" text="FAQ" />
        </div>
        <div className="footer-subscribe">
          <h3>Stay Connected</h3>
          <p>
            Get exclusive news before all your friends. It's free bragging
            rights!
          </p>
          <form className="footer-subscribe-form" onSubmit={handleSubmit}>
            <input
              type="email"
              onChange={(e) => setValue(e.target.value)}
              value={value}
              placeholder="Email..."
            />
            <button type="submit">Get news</button>
          </form>
          <div className="footer-subscribe-under">
            <p>By signing up you agree to our </p>
            <FooterLink href="/privacy-policy" text="Privacy Policy" />.
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <FooterLink href="/tos" text="Terms of Service" />
        <p>•</p>
        <FooterLink href="/cookie-policy" text="Cookie Policy" />
        <p>•</p>
        <FooterLink href="/privacy-policy" text="Privacy Policy" />
        <p>•</p>
        <a className="footer-link" href="/sitemap.xml">
          Sitemap
        </a>
        <p>•</p>
        <p>© 2020 Bepity</p>
      </div>
    </div>
  );
};

export default Footer;
