import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { TempAccountContext } from "../../context/temp-account-context.js";

import InfoBubble from "./InfoBubble/InfoBubble.js";
import Sidebar from "./Sidebar/Sidebar.js";
import Navbar from "./Sidebar/Navbar.js";
import Header from "./Header/Header.js";
import Content from "./content/Content.js";

import CloseIcon from "../../images/cancel.svg";

import "../../css/desktop/Main.css";
import "../../css/mobile/MobileMain.css";
import "../../css/global/Panels.css";

const Main = ({ isLoggedIn, setIsLoggedIn }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [headerMargin, setHeaderMargin] = useState(0);
  let history = useHistory();

  window.addEventListener("resize", () => {
    if (window.innerWidth > 850 && showSidebar) {
      setShowSidebar(false);
    }
  });

  function showSidebarCallback(e) {
    setShowSidebar(e);
  }

  const [showAlert, setShowAlert] = useState(false);
  const { isTempAccount } = useContext(TempAccountContext);

  useEffect(() => {
    if (isTempAccount) {
      var timeout = setTimeout(() => {
        setShowAlert(true);
      }, 3000);
    }

    if (!isTempAccount) clearTimeout(timeout);
    return () => clearTimeout(timeout);
  }, [isTempAccount]);

  return (
    <div className="main-body-wrapper">
      <div className="main-alert" style={!showAlert ? { height: 0 } : null}>
        <div className="main-alert-text">
          <p className="main-alert-text-desktop">
            Looks like you're using a limited version. For a complete feature
            set please
          </p>
          <p className="main-alert-text-mobile">
            Looks like you're using a limited version. For a complete feature
            set please Sign Up!
          </p>
          <button
            className="main-alert-sign"
            onClick={() => history.push("/auth/signup")}
          >
            Sign Up
          </button>
          <p className="main-alert-text-end">!</p>
        </div>
        <img onClick={() => setShowAlert(false)} src={CloseIcon} alt="Close" />
      </div>

      <div
        className="main-body-inner-wrapper"
        style={{ gridTemplateRows: `${headerMargin}px 1fr` }}
      >
        <div className="sidebar-desktop">
          <Sidebar
            setIsLoggedIn={setIsLoggedIn}
            mobile={false}
            showSidebarCallback={showSidebarCallback}
          />
        </div>

        <div
          style={showSidebar ? { display: "flex" } : { display: "none" }}
          className="sidebar-mobile"
        >
          <Sidebar
            setIsLoggedIn={setIsLoggedIn}
            mobile={true}
            showSidebarCallback={showSidebarCallback}
          />
        </div>

        <Header
          showSidebarCallback={showSidebarCallback}
          setHeaderMargin={setHeaderMargin}
        />
        <Content />
        <Navbar />
      </div>

      {isLoggedIn && isTempAccount ? (
        <InfoBubble isLoggedIn={isLoggedIn} isTempAccount={isTempAccount} />
      ) : null}
    </div>
  );
};

export default Main;
