import React from "react";
import { Route } from "react-router-dom";
import ConfirmationPassword from "./ConfirmationPassword.js";
import PasswordEmailInput from "./PasswordEmailInput.js";
import NewPassword from "./NewPassword.js";
import "../../../css/desktop/Auth.css";

const ForgotPassword = () => {
  return (
    <>
      <Route exact path="/auth/password/forgot">
        <PasswordEmailInput />
      </Route>
      <Route exact path="/auth/password/confirmation">
        <ConfirmationPassword />
      </Route>
      <Route exact path="/auth/password/new">
        <NewPassword />
      </Route>
    </>
  );
};

export default ForgotPassword;
