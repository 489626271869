import React, { useState } from "react";
import PanelListItem from "../../Panels/PanelListItem.js";
import PanelCategoryItem from "../../Panels/PanelCategoryItem.js";
import GoBackIcon from "../../../../images/arrow.svg";
import Cancel from "../../../../images/cancel.svg";

const AddNew = ({ closePopupCallback, addNewPanelCallback }) => {
  const [selected, setSelected] = useState(-1);
  return (
    <div className="panel-popup">
      <div className="panel-popup-inner">
        <div className="panel-popup-header">
          <div
            className="panel-popup-go-back"
            style={selected === -1 ? { display: "none" } : null}
            onClick={() => setSelected(-1)}
          >
            <img src={GoBackIcon} alt="Choose another one" />
          </div>

          <div
            className="panel-popup-cancel"
            onClick={() => closePopupCallback(false)}
          >
            <img src={Cancel} alt="Cancel" />
          </div>
        </div>

        <div
          className={
            selected === -1
              ? "panel-popup-item-wrapper"
              : "panel-popup-overflow"
          }
        >
          <PanelCategoryItem
            id={0}
            selected={selected}
            setSelected={setSelected}
            title="Profile"
            desc="desc"
          >
            <PanelListItem
              title="Profile"
              desc="Profile with basic information of specified game"
              type="profile"
              category="stats"
              closePopupCallback={closePopupCallback}
              addNewPanelCallback={addNewPanelCallback}
            />
          </PanelCategoryItem>

          <PanelCategoryItem
            id={1}
            selected={selected}
            setSelected={setSelected}
            title="Charts"
            desc="desc"
          >
            <PanelListItem
              title="Line Chart"
              desc="Game statistics shown in chart format"
              type="chart"
              chartType="line"
              category="stats"
              closePopupCallback={closePopupCallback}
              addNewPanelCallback={addNewPanelCallback}
            />
            <PanelListItem
              title="Bar Chart"
              desc="Game statistics shown in chart format"
              type="chart"
              chartType="bar"
              category="stats"
              closePopupCallback={closePopupCallback}
              addNewPanelCallback={addNewPanelCallback}
            />
            <PanelListItem
              title="Radar Chart"
              desc="Game statistics shown in chart format"
              type="chart"
              chartType="radar"
              category="stats"
              closePopupCallback={closePopupCallback}
              addNewPanelCallback={addNewPanelCallback}
            />
            <PanelListItem
              title="Polar Chart"
              desc="Game statistics shown in chart format"
              type="chart"
              chartType="polar"
              category="stats"
              closePopupCallback={closePopupCallback}
              addNewPanelCallback={addNewPanelCallback}
            />
            <PanelListItem
              title="Pie Chart"
              desc="Game statistics shown in chart format"
              type="chart"
              chartType="pie"
              category="stats"
              closePopupCallback={closePopupCallback}
              addNewPanelCallback={addNewPanelCallback}
            />
            <PanelListItem
              title="Doughnut Chart"
              desc="Game statistics shown in chart format"
              type="chart"
              chartType="doughnut"
              category="stats"
              closePopupCallback={closePopupCallback}
              addNewPanelCallback={addNewPanelCallback}
            />
          </PanelCategoryItem>
        </div>
      </div>
    </div>
  );
};

export default AddNew;
