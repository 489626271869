export async function getData(name, date, profileGame) {
  let returnData = {};

  if (profileGame !== null && name !== undefined) {
    if (profileGame.length > 0) {
      let game = profileGame.toLowerCase();

      await fetch(
        process.env.REACT_APP_DOMAIN + `/${game}/get/${date}/${name}`,
        {
          method: "GET",
          headers: {
            "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
          },
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((d) => {
          if (d.status === 404) {
            returnData = null;
          } else {
            returnData = d.message;
          }
        })
        .catch((err) => null);
    }

    if (
      profileGame.toLowerCase() === "tf2" ||
      profileGame.toLowerCase() === "csgo"
    ) {
      await fetch(
        process.env.REACT_APP_DOMAIN + `/steam/getSteamProfile/${name}`,
        {
          method: "GET",
          headers: {
            "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
          },
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((d) => {
          returnData = [returnData, d.message];
        })
        .catch((err) => null);
    }
  }
  return [returnData, profileGame];
}

export async function getUsernamesAndGame(profileId, outsiderSignal = null) {
  let game = null;
  let usernames = null;
  let controller = new AbortController();
  let signal = outsiderSignal === null ? controller.signal : outsiderSignal;

  await fetch(process.env.REACT_APP_DOMAIN + `/gameNames/get`, {
    signal,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((d) =>
      d.status === 200
        ? (usernames = {
            apex: d.message.apex,
            league: d.message.league,
            CSGO: d.message.steam,
            TF2: d.message.steam,
          })
        : null
    )
    .catch((err) => console.log(err.message));

  await fetch(process.env.REACT_APP_DOMAIN + `/profile/get/${profileId}`, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((d) => (d.status === 200 ? (game = d.message[0].profile_game) : null))
    .catch((e) => e);

  return [usernames, game];
}
