import React, { useState, useEffect } from "react";

const SmallLoader = ({
  reloadMessage = true,
  isLoading = true,
  color = "var(--main-color)",
}) => {
  const [isTimedOut, setIsTimedOut] = useState(false);

  useEffect(() => {
    if (reloadMessage) {
      var timeout = setTimeout(() => {
        setIsTimedOut(true);
      }, 6000);
    }

    return () => clearTimeout(timeout);
  }, [reloadMessage]);

  useEffect(() => {
    if (!isLoading) {
      setIsTimedOut(false);
    }
  }, [isLoading]);

  return (
    <div style={isLoading ? { display: "block" } : { display: "none" }}>
      {isTimedOut ? (
        <div className="loader-wrapper">
          <p className="loader-text">Oh no, looks like something went wrong!</p>
          <button
            className="loader-button"
            onClick={() => window.location.reload()}
          >
            Reload Page
          </button>
        </div>
      ) : (
        <div
          className="loader small-loader"
          style={{ borderTopColor: color, borderLeftColor: color }}
        ></div>
      )}
    </div>
  );
};

export default SmallLoader;
