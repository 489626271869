import React from "react";
import DeleteIcon from "../../../../../../images/trash.svg";

const Tag = ({ id, title, handleDelete }) => {
  return (
    <div className="goals-item-content-tag">
      <div>{title}</div>
      <div
        className="goals-item-content-tag-delete"
        onClick={() => handleDelete(id)}
      >
        <img src={DeleteIcon} alt="Delete" />
      </div>
    </div>
  );
};

export default Tag;
