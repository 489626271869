import React from "react";
import DeleteOrModify from "../DeleteOrModify.js";

const ErrorPanelSettings = ({
  id,
  type,
  placement,
  handleDelete,
  handleModify,
}) => {
  return (
    <div className="stats-panel-settings">
      <DeleteOrModify
        id={id}
        type={type}
        placement={placement}
        handleDelete={handleDelete}
        handleModify={handleModify}
      />
    </div>
  );
};

export default ErrorPanelSettings;
