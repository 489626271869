import React, { useState, useEffect, useContext } from "react";
import { UpdateProfileContext } from "../../../context/update-profile-context.js";
import { v4 as uuidv4 } from "uuid";
import { useHistory, useLocation } from "react-router-dom";
import { TempAccountContext } from "../../../context/temp-account-context.js";
import { queryParser } from "../../../customFunctions/url.js";
import { deleteProfile, postProfile } from "./sidebarModules.js";

import AddIcon from "../../../images/add.svg";
import VerifyIcon from "../../../images/checkmark.svg";
import ExitIcon from "../../../images/cancel.svg";

const SecondarySectionItems = () => {
  const [items, setItems] = useState([]);
  const [data, setData] = useState(null);
  const [getData, setGetData] = useState(0);
  const [newProfileValue, setNewProfileValue] = useState("");
  const [activeItem, setActiveItem] = useState(null);

  const [showAddInput, setShowAddInput] = useState(false);

  const { isTempAccount } = useContext(TempAccountContext);
  const { setUpdateProfile } = useContext(UpdateProfileContext);

  let history = useHistory();
  let location = useLocation();

  const handleClick = (title, item, profileId) => {
    if (activeItem !== item && activeItem !== null) {
      setActiveItem(item);
    }

    localStorage.setItem("profileId", profileId);
    setUpdateProfile((e) => !e);
  };

  const handleDelete = async (profileId) => {
    await deleteProfile(profileId)
      .then(() => setData(() => data.filter((d) => d.profile_id !== profileId)))
      .then(() => setGetData((g) => g + 1));

    setTimeout(async () => {
      if (data.length <= 1) {
        await setActiveItem(null);
        history.replace("/app/no_profile");
      } else {
        if (activeItem === 0) {
          localStorage.setItem("profileId", data[1].profile_id);
        } else {
          setActiveItem(0);
          localStorage.setItem("profileId", data[0].profile_id);
        }
      }
    }, 500);

    setUpdateProfile((e) => !e);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTimeout(() => {
      setGetData((g) => g + 1);
    }, 300);

    if (newProfileValue) {
      let newProfileId = await uuidv4();
      await postProfile(newProfileId, newProfileValue);
      setNewProfileValue("");

      if (activeItem === null) await setActiveItem(0);

      setShowAddInput(false);
    } else {
      setShowAddInput(false);
    }
  };

  const handleChange = (e) => {
    setNewProfileValue(e.target.value);
  };

  const handleProfileData = (arr) => {
    try {
      setData(arr.map((d) => d));

      if (arr.length === 0) {
        setActiveItem(null);
      } else if (arr.length >= 1) {
        setActiveItem(0);
      }

      setNewProfileValue("");
      if (arr.length > 0) {
        localStorage.setItem("profileId", arr[0].profile_id);
      }
    } catch (err) {
      console.log(err);
      setData([]);
    }
  };

  useEffect(() => {
    fetch(process.env.REACT_APP_DOMAIN + `/profile/getAll`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => handleProfileData(data.message))
      .catch((err) => console.log(err));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData]);

  useEffect(() => {
    if (data !== null) {
      setItems(
        data.map((d, i) => {
          return (
            <li
              key={Math.random() * 1000}
              className={
                activeItem === i
                  ? "sidebar-active sidebar-section-secondary "
                  : "sidebar-section-secondary"
              }
            >
              <div
                className="sidebar-section-secondary-content"
                onClick={() => handleClick(d.profile_title, i, d.profile_id)}
              >
                <p>{d.profile_title}</p>
              </div>
              <img
                onClick={() => handleDelete(d.profile_id)}
                className="sidebar-section-delete-icon"
                src={ExitIcon}
                alt="Delete"
              />
            </li>
          );
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, activeItem, data]);

  useEffect(() => {
    let pathname = location.pathname.split("/");
    let pathnameSection =
      pathname.length >= 3 ? pathname[2].toUpperCase().trim() : "";

    if (pathnameSection === "NO_PROFILE" && activeItem !== null) {
      history.replace("/app/overview");
    }

    //eslint-disable-next-line
  }, [activeItem]);

  useEffect(() => {
    if (queryParser(location.search).addNewProfile) {
      setGetData((g) => g + 1);
    }
  }, [location]);

  return (
    <div>
      <div className="sidebar-section-secondary-wrapper">{items}</div>
      {isTempAccount && items.length >= 2 ? (
        <div className="misc-max-amount-wrapper">
          <div className="misc-max-amount-txt">
            <p>Maximum amount of profiles reached.</p>
            <p>Please sign up for more.</p>
          </div>
          <button
            className="misc-max-amount-sign-up"
            onClick={() => history.push("/auth/signup")}
          >
            Sign Up
          </button>
        </div>
      ) : !showAddInput ? (
        <div className="sidebar-add-wrapper">
          <div
            className="sidebar-add-button"
            onClick={() => setShowAddInput(true)}
          >
            <img src={AddIcon} alt="verify" />
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="sidebar-verify-wrapper">
          <input
            className="sidebar-verify-input"
            type="text"
            onChange={handleChange}
            value={newProfileValue}
            placeholder="Profile..."
            minLength="2"
          />
          <button type="submit" className="sidebar-verify-button">
            {newProfileValue.length > 0 ? (
              <img src={VerifyIcon} alt="verify" />
            ) : (
              <img src={ExitIcon} alt="cancel" />
            )}
          </button>
        </form>
      )}
    </div>
  );
};

export default SecondarySectionItems;
