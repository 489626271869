import React, { useEffect, useState, useContext, useCallback } from "react";
import { UpdateProfileContext } from "../../../../context/update-profile-context.js";
import { TempAccountContext } from "../../../../context/temp-account-context.js";
import {
  getData,
  getUsernamesAndGame,
} from "../../../../customFunctions/getData.js";
import Loader from "../../../reusable/Loader.js";
import "../../../../css/desktop/Statistics.css";

import PanelHub from "../../Panels/PanelHub.js";
import AddNew from "./AddNew.js";
import NewPanel from "./NewPanel.js";
import MaximumAmountPanel from "./MaximumAmountPanel.js";
import NoGamePanel from "../../Panels/NoGamePanel.js";

const Statistics = () => {
  const { updateProfile } = useContext(UpdateProfileContext);
  const [showAddNew, setShowAddNew] = useState(false);
  const [panels, setPanels] = useState([]);
  const [panelsData, setPanelsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { isTempAccount } = useContext(TempAccountContext);

  let profileId = localStorage.getItem("profileId");

  const handleDelete = useCallback(
    (id, type) => {
      fetch(process.env.REACT_APP_DOMAIN + `/stat/delete/${id}/${type}`, {
        method: "DELETE",
        headers: {
          "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
        },
        credentials: "include",
      })
        .then((res) => res.json())
        .then((d) =>
          d.status === 200
            ? setPanelsData(panelsData.filter((e) => e.id !== id))
            : null
        )
        .catch((e) => e);
    },
    [panelsData, setPanelsData]
  );

  function closePopupCallback(state) {
    setShowAddNew(state);
  }

  function addNewPanel(id, type) {
    setPanelsData((p) => [...p, { id: id, type: type }]);
  }
  useEffect(() => {
    setIsLoading(true);
  }, [updateProfile]);

  useEffect(() => {
    fetch(process.env.REACT_APP_DOMAIN + `/stat/getAll/${profileId}`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((d) => {
        if (d.status === 200) {
          setPanelsData(
            d.message.map((e) => {
              return {
                id: e.stat_id,
                type: e.stat_type,
              };
            })
          );
        }
      })
      .then(setIsLoading(false))
      .catch((e) => e);
  }, [updateProfile, profileId]);

  useEffect(() => {
    getUsernamesAndGame(profileId).then((res) => {
      getData(res[0] !== null ? res[0][res[1]] : null, "all", res[1]).then(
        (d) =>
          setPanels(
            panelsData.map((e) => {
              let existingGameName =
                res[0] === null
                  ? null
                  : Object.values(res[0]).filter(
                      (e) => e === null || e === "none" || e === ""
                    ).length !== Object.values(res[0]).length
                  ? true
                  : false;

              if (res[1] === "none" || res[1] === null) {
                return (
                  <NoGamePanel
                    key={e.id}
                    id={e.id}
                    type={e.type}
                    existingGameName={existingGameName}
                    handleDelete={handleDelete}
                    handleModify={null}
                  />
                );
              } else {
                return (
                  <PanelHub
                    key={e.id}
                    id={e.id}
                    type={e.type}
                    handleDelete={handleDelete}
                    handleModify={null}
                    game={d[1]}
                    data={d[0]}
                  />
                );
              }
            })
          )
      );
    });
  }, [handleDelete, panelsData, profileId, updateProfile]);

  return (
    <div
      className="stats-wrapper"
      style={isLoading ? { alignItems: "center" } : null}
    >
      {isLoading ? (
        <Loader isLoading={true} />
      ) : showAddNew ? (
        <AddNew
          addNewPanelCallback={addNewPanel}
          closePopupCallback={closePopupCallback}
        />
      ) : null}

      <div className="stats-inner-wrapper">
        {isLoading ? null : panels}
        {isLoading ? null : isTempAccount && panels.length >= 3 ? (
          <MaximumAmountPanel />
        ) : (
          <NewPanel closePopupCallback={closePopupCallback} />
        )}
      </div>
    </div>
  );
};
export default Statistics;
