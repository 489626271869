import React, { createContext, useState, useEffect } from "react";

export const TempAccountContext = createContext();

const TempAccountContextProvider = (props) => {
  const [isTempAccount, setIsTempAccount] = useState(false);

  useEffect(() => {
    fetch(process.env.REACT_APP_DOMAIN + `/auth/isTempAccount`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then(async (d) => {
        await setIsTempAccount(d.message);
      })
      .catch((e) => e);
  }, []);

  return (
    <TempAccountContext.Provider
      value={{
        isTempAccount,
        setIsTempAccount,
      }}
    >
      {props.children}
    </TempAccountContext.Provider>
  );
};

export default TempAccountContextProvider;
