import React, { useState, useEffect, useRef } from "react";
import Chart from "chart.js";
import handleData from "../../../../../customFunctions/handleData.js";
import {
  borderColors,
  backgroundColors,
  getValues,
  options,
} from "../ChartPanelVariables.js";

// Color settings?

const handleLateValues = (values, maxLength) => {
  if (values.length === maxLength) return values;
  let array = [];
  for (let i = 0; i < maxLength - values.length; i++) {
    array.push(0);
  }
  array = [...array, ...values];
  return array;
};

const ChartPanelLine = ({ data, selected, placement }) => {
  const [myChart, setMyChart] = useState(null);
  const [datasets, setDatasets] = useState([]);
  const [handledData, setHandledData] = useState(
    handleData.charts(data.statistics)
  );
  const ctx = useRef(null);
  const [chartData, setChartData] = useState({
    labels: getValues("date", handledData),
    datasets: datasets,
  });

  useEffect(() => {
    setHandledData(handleData.charts(data.statistics));
  }, [data]);

  useEffect(() => {
    if (placement || placement === 0) {
      function resizeHandler() {
        if (myChart.ctx !== null) {
          myChart.resize();

          myChart.destroy();
          setMyChart(
            new Chart(ctx.current.getContext("2d"), {
              type: "line",
              data: chartData,
              options,
            })
          );
        }
      }

      window.addEventListener("resize", resizeHandler, true);

      return () => {
        window.removeEventListener("resize", resizeHandler, true);
      };
    }
  }, [placement, chartData, myChart]);

  // myChart

  useEffect(() => {
    if (myChart !== null) myChart.destroy();
    setMyChart(
      new Chart(ctx.current.getContext("2d"), {
        type: "line",
        data: chartData,
        options,
      })
    );

    //eslint-disable-next-line
  }, [chartData]);

  useEffect(() => {
    let set = [];
    let i = 0;
    const nullSelect = Object.keys(handledData[0])[0];

    setChartData((ld) => {
      return { ...ld, labels: getValues("date", handledData) };
    });

    for (let e of selected) {
      if (i === 4) return;
      if (e.toLowerCase() !== "none") {
        set.push({
          label: e,
          borderColor: borderColors[i],
          backgroundColor: backgroundColors[i],
          data:
            e === "None"
              ? getValues(e, handledData)
              : e === null && nullSelect !== "date"
              ? getValues(nullSelect, handledData)
              : handleLateValues(getValues(e, handledData), handledData.length),
          fill: true,
          pointRadius: 1,
          pointHoverRadius: 5,
          lineTension: 0.05,
          cubicInterpolationMode: "monotone",
          showLine: e === "None" ? false : true,
        });
      }
      i += 1;
    }

    setDatasets(set);
  }, [selected, handledData]);

  useEffect(() => {
    setChartData((ld) => {
      return {
        labels: getValues("date", handledData),
        datasets: datasets,
      };
    });
  }, [handledData, datasets]);

  return (
    <div className="stats-panel-chart-line canvas-container">
      <canvas ref={ctx} style={{ width: "100%" }}></canvas>
    </div>
  );
};

export default ChartPanelLine;
