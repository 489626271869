import React, { useState } from "react";
import ProfilePanelSettings from "./ProfilePanelSettings.js";
import SettingsIcon from "../../../../images/setting-icon.svg";
import Cancel from "../../../../images/cancel.svg";

const ProfilePanel = ({
  id,
  handleDelete,
  handleModify,
  game,
  data,
  placement = NaN,
}) => {
  const [showSettings, setShowSettings] = useState(false);
  const [width] = useState(`${data.levelProgression}%`);

  return (
    <div className="stats-panel">
      <div
        className="stats-panel-settings-icon-wrapper"
        onClick={() => setShowSettings((s) => !s)}
      >
        <img
          className="stats-panel-settings-icon"
          src={showSettings ? Cancel : SettingsIcon}
          alt="Delete"
        />
      </div>

      <div style={{ overflowY: "auto" }}>
        <div className="stats-profile-panel-upper">
          <div className="stats-profile-panel-upper-background">
            <img src={data.banner} alt="Background" />
          </div>

          <div className="stats-profile-panel-profile-picture">
            <img src={data.icon} alt="Background" />
          </div>
        </div>

        <div className="stats-profile-panel-lower">
          <div className="stats-profile-panel-lower-name">{data.username}</div>
          {data.levelProgression !== null ? (
            <div className="stats-profile-panel-lower-progress-wrapper">
              <div>{data.level}</div>
              <div className="stats-profile-panel-lower-bar">
                <div
                  style={{ width: width }}
                  className="stats-profile-panel-lower-bar-inner"
                ></div>
              </div>
              <div>{data.level + 1}</div>
            </div>
          ) : data.level !== null ? (
            <div className="stats-profile-panel-lower-level-wrapper">
              <div>Level:</div>
              <div>{data.level}</div>
            </div>
          ) : null}
        </div>
      </div>

      <div
        className="stats-panel-settings-wrapper"
        style={showSettings ? { display: "flex" } : { display: "none" }}
      >
        <ProfilePanelSettings
          id={id}
          placement={placement}
          handleDelete={handleDelete}
          handleModify={handleModify}
        />
      </div>
    </div>
  );
};

export default ProfilePanel;
