import React from "react";
import { useHistory } from "react-router-dom";

const MaximumAmountPanel = () => {
  const history = useHistory();
  return (
    <div className="stats-panel stats-maximum-amount-panel">
      <div className="misc-max-amount-wrapper">
        <div className="misc-max-amount-txt">
          <p>Maximum amount of panels reached.</p>
          <p>Please sign up for more.</p>
        </div>
        <button
          className="misc-max-amount-sign-up"
          onClick={() => history.push("/auth/signup")}
        >
          Sign Up
        </button>
      </div>
    </div>
  );
};

export default MaximumAmountPanel;
