import React, { useState, useEffect } from "react";
import SearchIcon from "../../../../images/search_glass.svg";

const GoalsSearch = ({ update, data, updateDataCallback }) => {
  const filterData = (data, searchQuery) => {
    if (searchQuery[0] === "!") {
      return data.filter((d) =>
        d.title.toLowerCase().startsWith(searchQuery.substr(1).toLowerCase())
      );
    } else if (searchQuery[0] === "%") {
      return data.filter((d) =>
        d.desc.toLowerCase().startsWith(searchQuery.substr(1).toLowerCase())
      );
    } else if (searchQuery[0] === ".") {
      return data.filter((d) => {
        let tagArr = d.tags.map((t) => t.title);
        return (
          tagArr.filter((t) =>
            t.toLowerCase().includes(searchQuery.substr(1).toLowerCase())
          ).length > 0
        );
      });
    } else if (searchQuery[0] === "#") {
      return data.filter((d) =>
        d.color.toLowerCase().startsWith(searchQuery.substr(1).toLowerCase())
      );
    } else {
      return data.filter((d) => {
        let tagArr = d.tags.map((t) => t.title);

        return (
          d.title.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
          d.desc.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
          tagArr.filter((t) =>
            t.toLowerCase().includes(searchQuery.toLowerCase())
          ).length > 0 ||
          d.color.toLowerCase().startsWith(searchQuery.toLowerCase())
        );
      });
    }
  };

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (searchQuery.length > 0) {
      updateDataCallback(filterData(data, searchQuery));
    } else {
      updateDataCallback(data);
    }

    //eslint-disable-next-line
  }, [data, searchQuery, update]);

  return (
    <div className="goals-search">
      <input
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search..."
        type="text"
      />
      <div className="goals-search-icon">
        <img
          style={
            searchQuery.length > 0 ? { filter: "none", loss: "none" } : null
          }
          src={SearchIcon}
          alt="Search"
        />

        <div className="goals-search-tooltip">
          <p>all - Search by all types</p>
          <p>!title - Search only by title</p>
          <p>%desc - Search only by description</p>
          <p>.tag - Search only by tags</p>
          <p>#color - Search only by hex</p>
        </div>
      </div>
    </div>
  );
};

export default GoalsSearch;
