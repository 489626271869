import React from "react";

import AccountIcon from "../../images/account.svg";
import GameIcon from "../../images/controller.svg";
import ProfileIcon from "../../images/list.svg";

const SettingsSidebar = ({ isTempAccount, setCategoryCallback }) => {
  return (
    <div className="settings-inner-left">
      {isTempAccount ? null : (
        <div
          className="settings-inner-left-item"
          onClick={() => setCategoryCallback("account")}
        >
          <img src={AccountIcon} alt="Account settings" />
        </div>
      )}
      <div
        className="settings-inner-left-item"
        onClick={() => setCategoryCallback("game")}
      >
        <img src={GameIcon} alt="Game settings" />
      </div>
      <div
        className="settings-inner-left-item"
        onClick={() => setCategoryCallback("profile")}
      >
        <img src={ProfileIcon} alt="Profile settings" />
      </div>
    </div>
  );
};

export default SettingsSidebar;
