import React, { useEffect, useState, useContext, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { queryParser } from "../../../../../customFunctions/url.js";
import { toDateTimeString } from "../../../../../customFunctions/handleData.js";
import { TempAccountContext } from "../../../../../context/temp-account-context.js";
import ListItem from "./ListItem.js";
import Quill from "quill";
import AddIcon from "../../../../../images/add.svg";

const LargePanelNotes = ({
  findInfoFromDeltas,
  openInEditor,
  resetEditorTabName,
  updateProfile,
}) => {
  let maxAmountOfNotes = 3;
  const location = useLocation();
  const history = useHistory();

  const { isTempAccount } = useContext(TempAccountContext);

  const [deletedItems, setDeletedItems] = useState([]);

  const deleteItem = useCallback(
    (id) => {
      setDeletedItems((d) => [...d, id]);
      resetEditorTabName(id);
    },
    [setDeletedItems, resetEditorTabName]
  );

  const [data, setData] = useState(null);
  const [quill, setQuill] = useState(null);

  useEffect(() => {
    setQuill(
      new Quill("#preview-editor", {
        theme: "bubble",
        modules: {
          toolbar: [],
        },
      })
    );
  }, []);

  useEffect(() => {
    let profileId = localStorage.getItem("profileId");
    fetch(process.env.REACT_APP_DOMAIN + `/note/getAll/${profileId}`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((d) => setData(d.message))
      .catch((err) => console.log(err));
  }, [updateProfile]);

  useEffect(() => {
    if (quill !== null) {
      quill.enable(false);
    }
  }, [quill]);

  const [showPreview, setShowPreview] = useState(false);
  const [previewDelta, setPreviewDelta] = useState({});
  useEffect(() => {
    if (quill !== null) {
      quill.setContents(previewDelta, "api");
    }
  }, [quill, previewDelta]);

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (data !== null) {
      setItems(
        data.map((e) => {
          let date = new Date(parseInt(e.last_updated));

          if (deletedItems.indexOf(e.note_id) === -1) {
            return (
              <div key={e.note_id}>
                <ListItem
                  title={e.note_name}
                  id={e.note_id}
                  lastAccessed={
                    Date.now() - e.last_updated > 86400000
                      ? toDateTimeString(date, "date")
                      : date.toLocaleTimeString()
                  }
                  handleClick={() => {
                    openInEditor(e.note_delta, e.note_name, true, e.note_id);
                    findInfoFromDeltas(e.note_delta);
                  }}
                  handleMouseEnter={() => {
                    setPreviewDelta(e.note_delta);
                    setShowPreview(true);
                  }}
                  handleMouseLeave={() => {
                    setPreviewDelta({});
                    setShowPreview(false);
                  }}
                  lastDelete={() => {
                    openInEditor({}, null, false);
                    findInfoFromDeltas(null);
                  }}
                  deleteItem={deleteItem}
                  defaultIsPublic={e.note_is_public}
                />
                <div className="notes-list-item-divider"></div>
              </div>
            );
          } else {
            return null;
          }
        })
      );
    }
  }, [data, deletedItems, deleteItem, openInEditor, findInfoFromDeltas]);

  useEffect(() => {
    if (queryParser(location.search).openInEditor) {
      history.push({ search: "" });

      fetch(
        process.env.REACT_APP_DOMAIN +
          `/note/get/${queryParser(location.search).openInEditor}`,
        {
          method: "GET",
          headers: {
            "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
          },
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((d) => {
          if (d.status === 200) {
            openInEditor(
              d.message.note_delta,
              d.message.note_name,
              true,
              d.message.note_id
            );
            findInfoFromDeltas(d.message.note_delta);
          }
        });
    }
  }, [findInfoFromDeltas, location, history, openInEditor]);

  return (
    <div className="notes-list-background">
      {isTempAccount && items.length >= maxAmountOfNotes ? (
        <div className="notes-max-amount-items-wrapper">
          <div className="notes-list-left">
            {items.length === 0 ? <p>No notes found</p> : items}
          </div>
          <div className="misc-max-amount-wrapper">
            <div className="misc-max-amount-txt">
              <p>Maximum amount of notes reached. </p>
              <p>Please sign up for more. </p>
            </div>
            <button
              className="misc-max-amount-sign-up"
              onClick={() => history.push("/auth/signup")}
            >
              Sign Up
            </button>
          </div>
        </div>
      ) : (
        <div className="notes-max-amount-items-wrapper">
          <div className="notes-list-left">
            {items.length === 0 ? (
              <div className="notes-no-notes-wrapper">
                <h3>No notes found</h3>
              </div>
            ) : (
              items
            )}
          </div>
        </div>
      )}

      <div className="notes-large-panel-divider"></div>
      <div className="notes-list-right">
        <div
          style={showPreview ? { display: "flex" } : { display: "none" }}
          id="preview-editor"
          className="notes-list-right-txt-background"
        ></div>
        <h1
          className="notes-list-right-preview-txt"
          style={showPreview ? { display: "none" } : { display: "flex" }}
        >
          Preview
        </h1>
      </div>

      {isTempAccount && items.length >= maxAmountOfNotes ? null : (
        <div
          onClick={() => {
            findInfoFromDeltas(null);
            openInEditor({}, null);
          }}
          className="notes-editor-save-btn"
        >
          <img className="notes-editor-add-icon" src={AddIcon} alt="save" />
        </div>
      )}
    </div>
  );
};

export default LargePanelNotes;
