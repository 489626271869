import React, { useEffect, useState } from "react";
import { getGameNames } from "./settingsModules";
import GameField from "./GameField/GameField.js";
import Steam from "./Steam.js";
import Loader from "../reusable/Loader.js";

const changeProfileGame = (gameId) => {
  const profileId = localStorage.getItem("profileId");
  fetch(process.env.REACT_APP_DOMAIN + `/profile/get/${profileId}`, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((d) => {
      if (d.message[0].profile_game === "none") {
        fetch(process.env.REACT_APP_DOMAIN + "/profile/updateGame", {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
          },
          credentials: "include",
          body: JSON.stringify({
            profile_id: profileId,
            profile_game: gameId,
          }),
        });
      } else {
        return;
      }
    })
    .catch((d) => d);
};

const GameSettings = () => {
  const [gameNames, setGameNames] = useState("");
  const [steamAuthStatus, setSteamAuthStatus] = useState(null);
  const [steamValue, setSteamValue] = useState(null);

  useEffect(() => {
    getGameNames().then((res) => setGameNames(res));

    fetch(process.env.REACT_APP_DOMAIN + `/steam/status`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_DOMAIN,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((d) => {
        setSteamAuthStatus(d.message.auth);
        setSteamValue(d.message.id);
      })
      .catch((e) => null);
  }, []);
  return (
    <div className="settings-all-games-inner-wrapper">
      {gameNames !== "" ? (
        <GameField
          initUser={gameNames.apex}
          options={["PC", "PS4", "X1"]}
          label="Apex Legends"
          game="apexLegends"
          handleNameChange={() => changeProfileGame("apex")}
          placeholder="Username"
        />
      ) : (
        <Loader />
      )}
      {gameNames !== "" ? (
        <>
          <GameField
            initUser={gameNames.league}
            options={[]}
            label="League Of Legends - COMING SOON"
            game="leagueOfLegends"
            handleNameChange={() => changeProfileGame("league")}
            placeholder="Not available"
          />
        </>
      ) : (
        <Loader />
      )}

      {steamAuthStatus !== null && steamValue !== null ? (
        <div className="settings-steam-wrapper">
          <GameField
            initUser={""}
            options={[]}
            label="CSGO and Team Fortress 2"
            game="steam"
            handleNameChange={() => changeProfileGame("CSGO")}
            initValue={steamValue}
            placeholder="SteamID"
          />
          <Steam handleNameChange={() => changeProfileGame("CSGO")} />
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default GameSettings;
